import React from 'react';
import { MDBIcon } from 'mdbreact';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './shared.scss';
import './SearchFieldWithAddValue.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const filter = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

const SearchFieldWithAddValue = (props) => {
    return (
        (!props?.searchSelect ? 
            <ClickAwayListener onClickAway={props?.onDropdownClose ? props?.onDropdownClose : ()=>{}}>
            <div className={`SearchFieldWithAddValueContainer ${props?.disabled ? 'disabled' : ''}`}>
                <Autocomplete
                    id={props?.id}
                    disabled={props?.disabled ? props?.disabled : false}
                    options={props?.options ? props?.options : []}
                    clearOnBlur = {true}
                    clearOnEscape = {true}
                    value={props?.value ? props?.options.find(item => item.value === props?.value) !== undefined ? props?.options.find(item => item.value === props?.value).text: null : null}
                    //value={props?.value !== null && props?.value.length > 0 ? props?.options.find(item => item.value === props?.value).text : null}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            return option ? option : "";
                        } else
                            if (option?.inputValue) {
                            return option.inputValue;
                            } else {
                                return option.text ? option.text : "";
                            }
                    }}
                    getOptionSelected={(option, value) => option.value === value.value}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        // if(props?.validateMethod && props?.validateMethod?.(params?.inputValue)){
                            let checkOption = options.find(item => item[props?.type] === params?.inputValue)
                            if (params?.inputValue !== '' && checkOption === undefined) {
                                params.inputValue = props.formatPhoneNumbers ? props.formatPhoneNumbers(params?.inputValue) : params?.inputValue
                                console.log("props?.displayAddNewSongOption 1s",props?.displayAddNewSongOption);
                                if((props?.displayAddNewSongOption && props?.displayAddNewSongOption===true) || props?.displayAddNewSongOption === undefined){
                                    filtered.push({
                                        value: params.inputValue,
                                        optionType:'new',
                                        text: `Add '${params.inputValue}' as New ${props?.newLabel}`,
                                    });
                                }
                                
                            }
                        // }
                        return filtered;
                        }
                    }
                    style={{ width: props?.width, marginTop: '-1rem'}}
                    popupIcon={<MDBIcon icon="chevron-right" size='sm'/>}
                    onChange={props?.onChange}
                    renderInput={(params) => <TextField 
                        //className={"textFieldContainer"}
                        placeholder={props?.placeholder || "Add Or Select"}
                        id={props?.id ? `${props?.id}-input` : null}
                        {...params} variant="outlined" 
                        // style={{border: '1px solid',}}
                        />}
                />
            </div>
        </ClickAwayListener> :
        <ClickAwayListener onClickAway={props?.onDropdownClose ? props?.onDropdownClose : ()=>{}}>
            <div className={`SearchFieldWithAddValueContainer searchSelect ${props?.disabled ? 'disabled' : ''}`}> 
                <Autocomplete
                    className='Remove-default-MuiAutocomplete-option'
                    id={props?.id}
                    disabled={props?.disabled ? props?.disabled : false}
                    options={props?.options ? props?.options : []}
                    clearOnBlur = {true}
                    clearOnEscape = {true}
                    value={(props?.detail_selected ? props?.detail_selected : null)}
                    // value={props?.value ? props?.options.find(item => item.value === props?.value) !== undefined ? props?.options.find(item => item.value === props?.value).text: null : null}
                    //value={props?.value !== null && props?.value.length > 0 ? props?.options.find(item => item.value === props?.value).text : null}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            return option ? option : "";
                        } else
                            if (option?.inputValue) {
                            return option.inputValue;
                            } else {
                                return option.text ? option.text : "";
                            }
                    }}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderOption={(optionProps, option) => (
                        <li
                        {...optionProps}
                        optiontype={optionProps.text?.split(" ")[0]=== "Add" ? 'new' : undefined}
                        className="MuiAutocomplete-option-add"
                        >
                        {optionProps.text}
                        </li>
                    )}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        // if(props?.validateMethod && props?.validateMethod?.(params?.inputValue)){
                            let checkOption = options.find(item => item[props?.type] === params?.inputValue)
                            if (params?.inputValue !== '' && checkOption === undefined) {
                                params.inputValue = props.formatPhoneNumbers ? props.formatPhoneNumbers(params?.inputValue) : params?.inputValue
                                console.log("props?.displayAddNewSongOption 2",props?.displayAddNewSongOption);
                                if((props?.displayAddNewSongOption && props?.displayAddNewSongOption===true) || props?.displayAddNewSongOption === undefined){
                                    filtered.push({
                                        value: params.inputValue,
                                        optionType:'new',
                                        text: `Add '${params.inputValue}' as New ${props?.newLabel}`,
                                    });
                                }
                            }
                        // }
                        return filtered;
                        }
                    }
                    style={{ width: props?.width, marginTop: '-1rem'}}
                    popupIcon={<MDBIcon icon="chevron-right" size='sm'/>}
                    onChange={props?.onChange}
                    // onChange={props?.valueSelected}
                    renderInput={(params) => <TextField 
                        //className={"textFieldContainer"}
                        placeholder={props?.placeholder || "Add Or Select"}
                        id={props?.id ? `${props?.id}-input` : null}
                        {...params} variant="outlined" 
                        // style={{border: '1px solid',}}
                        onChange={props?.searchText}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            endAdornment: (
                                <React.Fragment>
                                    {(props?.loading) ? <CircularProgress color="inherit"  /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                        />}
                />
            </div>
        </ClickAwayListener>
     ) );
}

export default SearchFieldWithAddValue;