import { Collapse } from "@material-ui/core";

export const initialCueSheetConfig = {
    "cue_sheet_record_id": null,
    "show_season_episode_id": null,
    "cue_no": null,
    "show_season_id": null,
    "show_id": null,
    "season_id": null,
    "composer": null,
    "publisher": null,
    "how_used": null,
    "duration": null,
    "is_active": 1,
    "is_delete": 0
}

export const initialCueSheetHeaderConfig = {
    "show_season_episode_id": null,
    "airing": null,
    "cue_sheet_time": null,
    "cue_sheet_notes": null,
    "cue_sheet_episodes": null,
    "cue_sheet_heading": null,
    "is_show_episode_date": 0,
    "is_send_through_rapid_cue": 0,
    "is_override_cue_sheet_episode": 0
}

export const airingOptions = [
    { label: "Initial", value: null },
]


export const initialStatusConfig = {
    "cue_sheet_status_id": null,
    "show_season_episode_id": null,
    "status": null,
    "is_approve": null,
    "is_reject": null,
    "is_active": 1
}

export const initialRecurringCueConfig = {
    "is_delete": 0,
    "show_season_recurring_cue_id": null,
    "show_season_id": null,
    "show_id": null,
    "season_id": null,
    "song_id": null,
    "composer": null,
    "publisher": null,
    "how_used": null,
    "duration": null,
    "is_active": 1
  }

export const clearenceRecordConfig = {
    headings: [
        {
            headingLabel: "Composition",
            inputType: "text",
            icon: "sort-alpha-down",
        },
        {
            headingLabel: "Scene",
            inputType: "text",
            icon: ""
        },
        {
            headingLabel: "Type",
            inputType: "text",
            icon: ""
        },
        {
            headingLabel: "Licensors",
            inputType: "text",
            icon: ""
        },
        {
            headingLabel: "TotalFee",
            inputType: "text",
            icon: ""
        },
        {
            headingLabel: "Licensee Rep",
            inputType: "text",
            icon: ""
        },
        {
            headingLabel: "Status",
            inputType: "text",
            icon: ""
        },

    ],
    dataNodes: ["composition", "scene", "licensor_type", "licensor_name", "total_fee", "licensee_rep", "status"],
    primaryKey: "clearance_id",
    hyperlinks: ["composition"]
};
export const allowRestrictConfig ={
    "Restrict_All_Promo_Use": null,
    "All_Promo_In_Episode_Only": null,
    "Allow_all_Promo_Use" : null
}

const allUsersList = [
    {
        id: 1,
        composition: "Mess by Dwayne carter",
        scence: "[Scence]",
        type: "S/s/m",
        licensors: "S/s/m",
        totalfee: "$15000",
        licenseerep: "[licenseerep]",
        status: "Confirmation sent"
    },
    {
        id: 2,
        composition: "Mess by Dwayne carter",
        scence: "[Scence]",
        type: "S/s/m",
        licensors: "S/s/m",
        totalfee: "$15000",
        licenseerep: "[licenseerep]",
        status: "Confirmation sent"
    },
    {
        id: 3,
        composition: "Mess by Dwayne carter",
        scence: "[Scence]",
        type: "S/s/m",
        licensors: "S/s/m",
        totalfee: "$15000",
        licenseerep: "[licenseerep]",
        status: "Confirmation sent"
    },
    {
        id: 4,
        composition: "Mess by Dwayne carter",
        scence: "[Scence]",
        type: "S/s/m",
        licensors: "S/s/m",
        totalfee: "$15000",
        licenseerep: "[licenseerep]",
        status: "Confirmation sent"
    },
    {
        id: 5,
        composition: "Mess by Dwayne carter",
        scence: "[Scence]",
        type: "S/s/m",
        licensors: "S/s/m",
        totalfee: "$15000",
        licenseerep: "[licenseerep]",
        status: "Confirmation sent"
    },
    {
        id: 6,
        composition: "Mess by Dwayne carter",
        scence: "[Scence]",
        type: "S/s/m",
        licensors: "S/s/m",
        totalfee: "$15000",
        licenseerep: "[licenseerep]",
        status: "Confirmation sent"
    },
]
const valueList = [
    {
        value: 1,
        label: "Override Cost Center",
        // icon: "check-circle"
        dataNode: "is_override_cost_center"
    },
    {
        value: 2,
        label: "No Lic Music",
        // icon: "check-circle"
        dataNode: "is_no_lic_music"
    },
    {
        value: 3,
        label: "MS Episode Complete",
        // icon: "times-circle"
        dataNode: "is_ms_complete"
    }
    
]
const detailsIconList = [
    {
        value: 1,
        label: "Subtitle Allowed",
        // icon: "times-circle"
        dataNode: "is_subtitle_allowed"
    },
    {
        value: 2,
        label: "Translation Allowed",
        // icon: "times-circle"
        dataNode: "is_translation_allowed"
    },
    {
        value: 3,
        label: "Film Tv Clip",
        // icon: "times-circle"
        dataNode: "is_film_tv_clip"
    }
]
const selectOptionList = [
    {
        label: "Edit Licensor",
        value: 1
    },
    {
        label: "Add New Licensor to this CL Record",
        value: 2
    },
    {
        label: "Copy Licensor on this Cl Record",
        value: 3
    },
    {
        label: "Change/View Default Contact",
        value: 4
    },
    {
        label: "Remove Licensor from this Cl R",
        value: 5
    },
    // {
    //     label: "horizantal line",
    //     value: 6
    // },
    {
        label: "Request W9-W8-VEN",
        value: 6
    }
    // {
    //     label: "Calc.Sync fees based on Master",
    //     value: 7
    // },
    // {
    //     label: "Clac.Master fee based on sync ",
    //     value: 8
    // },
    // {
    //     label: "Calc.Sync & co-pub fees based on Master",
    //     value: 9
    // },
    // {
    //     label: "Clac.Co-Master fees based on Current Master ",
    //     value: 10
    // },
    // {
    //     label: "Calc.Co-Pub fees based on Current Sync Licensor",
    //     value: 11
    // },
    // {
    //     label: "Clac.Master && Co-Master fees based on Sync Licensor ",
    //     value: 12
    // }
]
const iconList = [
    {
        value: 1,
        label: "COA",
        // icon: "times-circle",
        dataNode: "is_coa"
    },
    {
        value: 2,
        label: "All Promo Use Restricted",
        // icon: "times-circle"
        dataNode: "is_all_promo_use_restricted"
    },
    // {
    //     value: 3,
    //     label: "Limited Promo Use",
    //     // icon: "check-circle"
    //     dataNode: "is_limited_promo_use"
    // },
    {
        value: 4,
        label: "Contains Sample(s)",
        // icon: "check-circle"
        dataNode: "is_contains_simple"
    },
    {
        value: 5,
        label: "Library",
        // icon: "check-circle"
        dataNode: "is_library"
    },
    {
        value: 6,
        label: "PD",
        // icon: "times-circle"
        dataNode: "is_PD"
    },
    {
        value: 5,
        label: "Is Arrangement",
        // icon: "check-circle"
        dataNode: "is_arrangement"
    },


]
const cueSheetConfig = {
    headings: [
        {
            headingLabel: "Sel.",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "3%"
        },
        {
            headingLabel: "No",
            inputType: "text",
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Composition",
            inputType: "textarea",
            icon: "",
            border: true,
            width: "13%"
        },
        {
            headingLabel: "Arr",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "3%"
        },
        {
            headingLabel: "Composer",
            inputType: "select",
            inputType: "textarea",
            icon: "",
            border: true,
            width: "24%"
        },
        {
            headingLabel: "Publisher",
            inputType: "textarea",
            icon: "",
            border: true,
            width: "24%"
        },
        {
            headingLabel: "How Used",
            inputType: "select",
            selectOptions: [],
            border: true,
            icon: "",
            width: "7%"
        },
        {
            headingLabel: "Min.",
            inputType: "text",
            border: true,
            icon: "",
            width: "2.5%"
        },
        {
            headingLabel: "",
            inputType: "label",
            border: true,
            icon: "",
            width: "1%"
        },
        {
            headingLabel: "Sec",
            inputType: "text",
            border: true,
            icon: "",
            width: "2.5%"
        },

    ],
    dataNodes: ["check","cue_no", "composition",'is_arr', "composer", "publisher", "how_used", "min","colon","sec"],
    nodeFields: [
        {
            column: "is_arr",
            icon: "check"
        }
    ],
    primaryKey: "cue_sheet_record_id",
    actions: ["pen", "copy", "times"],
    disableActions: ["pen", "copy", "times"],
    isClearFields: true,
    inlineEdits: [
        {
            dataNode: "cue_no",
            width: "7%"
        },
        {
            dataNode: "composition",
            width: "16%"
        },
        {
            dataNode: "is_arr",
            width: "3%"
        },
        {
            dataNode: "composer",
            width: "24%", 
            icon: "arrows-alt-v",
            iconTooltip: "Add Composer",
        },
        {
            dataNode: "publisher",
            width: "22%",
            icon: "arrows-alt-v",
            iconTooltip: "Add Publisher"
        },
        {
            dataNode: "how_used",
            width: "7%"
        },
        {
            dataNode: "min",
            width: "3.5%",
            validateMethod : 'validateNumbersOnly',
            validateMessage : 'Invalid Min'
        },
        {
            dataNode: "colon",
            width: "1%",
            className:'cueSheetColon',
        },
        {
            dataNode: "sec",
            width: "3%",
            validateMethod : 'validateDurationSec',
            validateMessage : 'Invalid Sec'
        }
    ],
    isClearFields:true,
    newRowSelectOptions: [
        {
            dataNode: "check",
            width: "2%",
            inputType : 'none',
        },
        {
            dataNode: "cue_no",
            width: "2%",
            inputType : 'none',
        },
        {
            dataNode: "composition",
            width: "20%",
            inputType : 'select',
            addRowOptions:[],
            placeHolderText : ' -Select Composition- ',
            onchangeField : 'composition',
        },
        {
            dataNode: "is_arr",
            width: "2%",
            inputType : 'none',
        },
        {
            dataNode: "composer",
            width: "32%",
            inputType : 'select',
            addRowOptions:[],
            placeHolderText : '-Select Composer-',
            onchangeField : 'composer',
            relatedField : ['publisher']
        },
        {
            dataNode: "publisher",
            width: "20%",
            inputType : 'none',
        },
        {
            dataNode: "how_used",
            width: "10%",
            inputType : 'none'
        },
        // {
        //     dataNode: "duration",
        //     width: "4%",
        //     inputType : 'none'
        // }
    ],
};

const cueSheetRecurringCueConfig = {
    headings: [
        {
            headingLabel: "Composition",
            inputType: "textarea",
            // selectOptions: [],
            icon: "",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "Composer",
            inputType: "textarea",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Publisher",
            inputType: "textarea",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "How Used",
            inputType: "select",
            selectOptions: [],
            border: true,
            icon: "",
            width: "18%"
        },
        {
            headingLabel: "Min",
            inputType: "text",
            border: true,
            icon: "",
            width: "3.5%"
        },
        {
            headingLabel: "",
            inputType: "label",
            border: true,
            icon: "",
            width: "1%"
        },
        {
            headingLabel: "Sec",
            inputType: "text",
            border: true,
            icon: "",
            width: "3.5%"
        },

    ],
    dataNodes: ["composition", "composer", "publisher", "how_used", "min","colon","sec"],
    primaryKey: "show_season_recurring_cue_id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "composition",
            width: "24.5%"
        },
        {
            dataNode: "composer",
            width: "19%",
            icon: "arrows-alt-v",
            iconTooltip: "Add Composer"
        },
        {
            dataNode: "publisher",
            width: "19.5%",
            icon: "arrows-alt-v",
            iconTooltip: "Add Publisher"
        },
        {
            dataNode: "how_used",
            width: "18.5%"
        },
        {
            dataNode: "min",
            width: "3.5%",
            validateMethod : 'validateNumbersOnly',
            validateMessage : 'Invalid Min'
        },
        {
            dataNode: "colon",
            width: "1%",
            className:'cueSheetColon',
        },
        {
            dataNode: "sec",
            width: "3%",
            validateMethod : 'validateDurationSec',
            validateMessage : 'Invalid Sec'
        }
    ],
    isClearFields:true,
    newRowSelectOptions: [
        {
            dataNode: "composition",
            width: "18%",
            inputType : 'none',
        },
        {
            dataNode: "composer",
            width: "25%",
            inputType : 'select',
            addRowOptions:[],
            placeHolderText : '-Select Composer-',
            onchangeField : 'composer',
            relatedField : ['publisher']
        },
        {
            dataNode: "publisher",
            width: "20%",
            inputType : 'none',
        },
        {
            dataNode: "how_used",
            width: "20%",
            inputType : 'none'
        },
        // {
        //     dataNode: "duration",
        //     width: "8%",
        //     inputType : 'none'
        // }
    ],

};

const cueSheetUsersList = [
    {
        id: 1,
        no: "1",
        composition: "Freedom",
        composer: "Hans Zimmerman (ASCAP)",
        publisher: "Showtime Songs (ASCAP) 10% Bosshouse Music LLC(BMI)",
        howused: "Not Confirmed.",
        duration: "1:48"
    },
    {
        id: 2,
        no: "2",
        composition: "[First Name] [Last Name]",
        composer: "Composer Name (Affiiation)",
        publisher: "[Publisher Name] ([Affiliation]) [Percentage]",
        howused: "Bkg. Inst.",
        duration: "2:12"
    },
    {
        id: 3,
        no: "3",
        composition: "[First Name] [Last Name]",
        composer: "Composer Name (Affiiation)",
        publisher: "[Publisher Name] ([Affiliation]) [Percentage]",
        howused: "Bkg. Inst.",
        duration: "1:00"
    },
    {
        id: 3,
        no: "3",
        composition: "[First Name] [Last Name]",
        composer: "Composer Name (Affiiation)",
        publisher: "[Publisher Name] ([Affiliation]) [Percentage]",
        howused: "Bkg. Inst.",
        duration: "3:48"
    },
    {
        id: 4,
        no: "4",
        composition: "[First Name] [Last Name]",
        composer: "Composer Name (Affiiation)",
        publisher: "[Publisher Name] ([Affiliation]) [Percentage]",
        howused: "Bkg. Inst.",
        duration: "1:44"
    },
    {
        id: 5,
        no: "5",
        composition: "[First Name] [Last Name]",
        composer: "Composer Name (Affiiation)",
        publisher: "[Publisher Name] ([Affiliation]) [Percentage]",
        howused: "Bkg. Inst.",
        duration: "5:44"
    },
]
const filesList = [
    {
        value: 1,
        label: "File_Name.pdf",
        icon: "times"
    },
    {
        value: 2,
        label: "File_Name.pdf",
        icon: "times"
    },
    {
        value: 3,
        label: "File_Name.pdf",
        icon: "times"
    },
    {
        value: 4,
        label: "File_Name.pdf",
        icon: "times"
    },
    {
        value: 5,
        label: "File_Name.pdf",
        icon: "times"
    }
]
const syncLicensorConfig = {
    headings: [
        {
            headingLabel: " ",
            inputType: "icon",
            icon: "",
            width: "2%"
        },
        {
            headingLabel: "Payee",
            inputType: "searchselect",
            icon: "",
            border: true,
            width: "25%",
            selectOptions: []
        },
        {
            headingLabel: "Fee %",
            inputType: "text",
            icon: "",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Share",
            inputType: "text",
            icon: "",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "Init. Fee",
            inputType: "text",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Req. Date",
            inputType: "datefield",
            border: true,
            icon: "",
            width: "14%"
        },
        {
            headingLabel: "Inv.",
            inputType: "",
            icon: "",
            width: "5%"
        },
        {
            headingLabel: "Notes",
            inputType: "",
            icon: "",
            width: "5%"
        },
        {
            headingLabel: "In Batch",
            inputType: "label",
            border: true,
            icon: "",
            width: "10%"
        },
    ],
    dataNodes: ["clearance_check_request_id", "payee_remit_to", "lic_per", "share", "init_fee", "requested_date", "inv", "notes", "in_batch"],
    searchFields: [{
        label: "payee_remit_to",
        hyperlink: false
    }],
    primaryKey: "clearance_check_request_id",
    buttonFields: [
        {
            column: "inv",
            icon: "file",
            hideBtnText: true
        },
        {
            column: "notes",
            icon: "file",
            hideBtnText: true
        }
    ],
    nodeFields: [
        {
            column: "clearance_check_request_id",
            icon: "pen-square"
        },
    ],
    actions: ["arrows-alt-v"],
    inlineEdits: [
        {
            dataNode: "clearance_check_request_id",
            width: "2%",
            icon:"pen-square",
            resetField: 'payee_remit_to',
            fieldDisabled: true
        },
        {
            dataNode: "payee_remit_to",
            width: "24.3%",
            fieldDisabled: true
        },
        {
            dataNode: "lic_per",
            width: "6.3%",
            validateMethod:"validateNumDotWithNegative",
            validateMessage : 'Please enter numbers',
            fieldDisabled: true
        },
        {
            dataNode: "share",
            width: "6.3%",
            validateMethod:"validateNumDotWithNegative",
            validateMessage : 'Please enter numbers',
            fieldDisabled:true
        },
        {
            dataNode: "init_fee",
            width: "9.3%",
            inlineIcon: "$",
            validateMethod:"validateNumDotWithNegative",
            validateMessage : 'Please enter numbers',
            fieldDisabled:true
        },
        {
            dataNode: "requested_date",
            width: "12.8%",
            fieldDisabled: true
        },
        {
            dataNode: "",
            width: "4%",
            fieldDisabled: true
        },
        {
            dataNode: "",
            width: "5%",
            fieldDisabled: true
        },
        {
            dataNode: "in_batch",
            width: "9%",
            fieldDisabled: true
        },
    ],
    isClearFields: true,
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
};


const  mainTitlesyncLicensorConfig = {
    headings: [
        {
            headingLabel: " ",
            inputType: "icon",
            icon: "",
            width: "2%"
        },
        {
            headingLabel: "Payee",
            inputType: "searchselect",
            icon: "",
            border: true,
            width: "20%",
            selectOptions: []
        },
        {
            headingLabel: "",
            inputType: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Fee %",
            inputType: "text",
            icon: "",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "Share",
            inputType: "text",
            icon: "",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "Init. Fee",
            inputType: "text",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Req. Date",
            inputType: "datefield",
            border: true,
            icon: "",
            width: "14%"
        },
        {
            headingLabel: "Inv.",
            inputType: "",
            icon: "",
            width: "5%"
        },
        {
            headingLabel: "Notes",
            inputType: "",
            icon: "",
            width: "5%"
        },
        {
            headingLabel: "In Batch",
            inputType: "label",
            border: true,
            icon: "",
            width: "10%"
        },
    ],
    dataNodes: ["main_title_check_request_id", "payee_remit_to","eps_cnt", "lic_per", "share", "init_fee", "requested_date", "inv", "notes", "in_batch"],
    searchFields: [{
        label: "payee_remit_to",
        hyperlink: false
    }],
    primaryKey: "main_title_check_request_id",
    buttonFields: [
        {
            column: "inv",
            icon: "file",
            hideBtnText: true
        },
        {
            column: "notes",
            icon: "file",
            hideBtnText: true
        },
        {
            column: "eps_cnt",
            icon: "",
            showDataNodeValue: true
        }
    ],
    nodeFields: [
        {
            column: "main_title_check_request_id",
            icon: "pen-square"
        },
    ],
    actions: ["arrows-alt-v"],
    inlineEdits: [
        {
            dataNode: "main_title_check_request_id",
            width: "2%",
            icon:"pen-square",
            resetField: 'payee_remit_to',
            fieldDisabled: true

        },
        {
            dataNode: "payee_remit_to",
            width: "19.8%",
            fieldDisabled: true
        },
        {
            dataNode: "",
            width: "4.2%",
            fieldDisabled: true
        },
        {
            dataNode: "lic_per",
            width: "4.2%",
            validateMethod:"validateNumDotWithNegative",
            validateMessage : 'Please enter numbers',
            fieldDisabled: true
        },
        {
            dataNode: "share",
            width: "6.3%",
            validateMethod:"validateNumDotWithNegative",
            validateMessage : 'Please enter numbers',
            fieldDisabled:true
        },
        {
            dataNode: "init_fee",
            width: "9.3%",
            inlineIcon: "$",            
            validateMethod:"validateNumDotWithNegative",
            validateMessage : 'Please enter numbers',
            fieldDisabled:true
        },
        {
            dataNode: "requested_date",
            width: "13.5%",
            fieldDisabled: true
        },
        {
            dataNode: "",
            width: "4%",
            fieldDisabled: true
        },
        {
            dataNode: "",
            width: "5%",
            fieldDisabled: true
        },
        {
            dataNode: "in_batch",
            width: "9%",
            fieldDisabled: true
        },
    ],
    isClearFields: true,
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
};

const syncLicensorList = [
    {
        id: 1,
        Payee: "Nylon REcords",
        Lic: "50%",
        Share: "25%",
        InitFee: "$45,000.00",
        YRS_HOME: "$60000.00",
        ReqDate: "05/21/2021",
        InBatch: 9999,
    },
    {
        id: 2,
        Payee: "Nylon Records LLC",
        Lic: "50%",
        Share: "25%",
        InitFee: "$45,000.00",
        YRS_HOME: "$60000.00",
        ReqDate: "09/22/2012",
        InBatch: 9999,
    },

]
const syncIconList = [
    {
        value: 1,
        label: "Quote Request",
        date: "3/20/2022",
        link: "link",
        icon: "check-circle"
    },
    {
        value: 2,
        label: "Producer’s Memo",
        date: "3/20/2022",
        link: "link",
        icon: "check-circle"
    },
    {
        value: 3,
        label: "Confirmation",
        date: "3/20/2022",
        link: "link",
        icon: "check-circle"
    },
    {
        value: 4,
        label: "Lic. Requested",
        date: "",
        icon: "check-circle"
    },
    {
        value: 5,
        label: "Signed Lic. Rcvd.",

        icon: "check-circle"
    },
    {
        value: 6,
        label: "Check Request",
        icon: "times-circle"
    },
    {
        value: 5,
        label: "Fully Executed",
        icon: "check-circle"
    },


]

const CueSheetPublisherConfig = {
    headings: [
        {
            headingLabel: " ",
            inputType: "icon",
            icon: "",
            width: "2%"
        },
        {
            headingLabel: "Payee",
            inputType: "searchselect",
            selectOptions: [],
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "",
            inputType: "",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Fee %",
            inputType: "text",
            icon: "",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Share",
            inputType: "text",
            icon: "",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "Init. Fee",
            inputType: "text",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Req. Date",
            inputType: "datefield",
            border: true,
            icon: "",
            width: "14%"
        },
        {
            // headingLabel: "In Batch",
            // inputType: "label",
            // border: true,
            // icon: "",
            // width: "20%"
            headingLabel: "BCH",
            icon: "payment",
            width: "5%"
        },
        {
            headingLabel: "Inv. Notes",
            inputType: "",
            icon: "",
            width: "6%"
        },
        {
            headingLabel: "Notes",
            inputType: "",
            icon: "",
            width: "6%"
        },
    ],
    buttonFields: [
        {
            column: "inv",
            icon: "file",
            hideBtnText: true
        },
        {
            column: "notes",
            icon: "file",
            hideBtnText: true
        },
        {
            column: "eps_op_cnt",
            icon: "",
            showDataNodeValue: true
        }
    ],
    dataNodes: ["clearance_licensor_optional_check_requests_id", "payee_remit_to","eps_op_cnt","lic_per", "share", "fee", "requested_date", "bch", "inv", "notes"],
    searchFields: [{
        label: "payee_remit_to",
        hyperlink: false
    }],
    primaryKey: "clearance_licensor_optional_check_requests_id",
    nodeFields: [ 
        {
            column: "clearance_licensor_optional_check_requests_id",
            icon: "pen-square"
        },
        {
            column: "bch",
            icon: "money-check"
        },
    ],
    actions: ["arrows-alt-v"],
    isClearFields: true,
    inlineEdits: [
        {
            dataNode: "clearance_licensor_optional_check_requests_id",
            width: "2%",
            icon:"pen-square",
            resetField: 'payee_remit_to',
            fieldDisabled: true

        },
        {
            dataNode: "payee_remit_to",
            width: "19.2%",
            fieldDisabled: true
        },
        {
            dataNode: "",
            width: "4.2%",
            fieldDisabled: true
        },
        {
            dataNode: "lic_per",
            width: "4.4%",
            validateMethod:"validateNumDotWithNegative",
            validateMessage : 'Please enter numbers',
            fieldDisabled: true
        },
        {
            dataNode: "share",
            width: "6.2%",
            validateMethod:"validateNumDotWithNegative",
            validateMessage : 'Please enter numbers',
            fieldDisabled:true
        },
        {
            dataNode: "fee",
            width: "9.3%",
            inlineIcon: "$",
            validateMethod:"validateNumDotWithNegative",
            validateMessage : 'Please enter numbers',
            fieldDisabled:true
        },
        {
            dataNode: "requested_date",
            width: "13.4%",
            fieldDisabled: true
        },
        // {
        //     dataNode: "in_batch",
        //     width: "18.9%"
        // },
        {
            dataNode: "",
            width: "4%",
            fieldDisabled: true
        },
        {
            dataNode: "",
            width: "5.5%",
            fieldDisabled: true
        },
    ],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
};

const CueSheetPublisherList = [
    {
        id: 1,
        Payee: "Nylon Records LLC",
        Lic: "50%",
        Share: "25%",
        InitFee: "$45,000.00",
        YRS_HOME: "$60000.00",
        ReqDate: "05/21/2021",
        InBatch: 9999,

    },
    {
        id: 2,
        Payee: "Nylon Records LLC",
        Lic: "50%",
        Share: "25%",
        InitFee: "$45,000.00",
        YRS_HOME: "$60000.00",
        ReqDate: "09/22/2012",
        InBatch: 9999,
    },

]
const selectSongList = [
    {
        label: "Add Song to this record",
        value: 1
    },
    {
        label: "Remove this song from record",
        value: 2
    },
    {
        label: "Edit this Song",
        value: 3
    },
]
export const syncLicensorFlagConfig = [
    {
        label: "Edit Payee",
        value: 1
    },
    {
        label: "Create New Payee",
        value: 2
    }
]


const Licensor = {
    "clearance_licensor_id": null,
    "clearance_id": null,
    "show_season_id": null,
    "show_id": null,
    "season_id": null,
    "show_season_episode_id": null,
    "licensor_id": null,
    "licensor_type": null,
    "share": null,
    "status_id": null,
    "is_they_provide_license": 0,
    "selected_licensee_rep":null,
    "licensee_rep_id": null,
    "notes": null,
    "is_mfn_with_S_M": 0,
    "is_mfn_with_M":0,
    "is_mfn_with_Co_M":0,
    "is_mfn_with_Pub":0,
    "is_mfn_with_Co_Pub":0,
    "is_apply_dollar_to_HV": 0,
    "is_license_for_sample": 0,
    "recording_share": null,
    "quote_request_date": null,
    "confirmation_date": null,
    "license_request_date": null,
    "is_active": 1,
    "is_check_request_updated": 0,
    "is_licensor_optional_rights_updated": 0,
    "is_rights_updated":0,
    "rights": [],
    "cue_sheet_publishers": [],
    "clearance_check_request": [],
    "clearance_licensor_optional_rights": []
}

export const licensorRightsConfig = [
    {
        "clearance_licensor_rights_id": null,
        "right_id": null,
        "right_abbr": null,
        "is_initial": 1,
        "is_alt_initial": 0,
        "territory_id": null,
        "term_id": null,
        "fee": null
    },
    {
        "clearance_licensor_rights_id": null,
        "right_id": null,
        "right_abbr": null,
        "is_initial": 0,
        "is_alt_initial": 1,
        "territory_id": null,
        "term_id": null,
        "fee": null
    }
]

const selectLicensorType = [
    {
        label: "S",
        value: 1
    },
    {
        label: "M",
        value: 2
    },
    {
        label: "SM",
        value: 3
    },
]

const cueSheetMusicEditorConfig = {
    headings: [
        {
            headingLabel: "Sel.",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "3%"
        },
        {
            headingLabel: "No",
            inputType: "text",
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Composition",
            inputType: "textarea",
            icon: "",
            border: true,
            width: "13%"
        },
        {
            headingLabel: "Arr",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "3%"
        },
        {
            headingLabel: "Composer",
            inputType: "textarea",
            icon: "",
            border: true,
            width: "24%"
        },
        {
            headingLabel: "Publisher",
            inputType: "textarea",
            icon: "",
            border: true,
            width: "24%"
        },
        {
            headingLabel: "How Used",
            inputType: "select",
            selectOptions: [],
            border: true,
            icon: "",
            width: "8%"
        },
        {
            headingLabel: "Min.",
            inputType: "text",
            border: true,
            icon: "",
            width: "3.5%"
        },
        {
            headingLabel: "",
            inputType: "label",
            border: true,
            icon: "",
            width: "1%"
        },
        {
            headingLabel: "Sec",
            inputType: "text",
            border: true,
            icon: "",
            width: "3.5%"
        },

    ],
    dataNodes: ["check","cue_no", "composition", "is_arr","composer", "publisher", "how_used", "min","colon","sec"],
    nodeFields: [
        {
            column: "is_arr",
            icon: "check"
        }
    ],
    primaryKey: "cue_sheet_record_id",
    actions: ["pen"],
    disableActions: ["pen"],
    isClearFields: true,
    inlineEdits: [
        {
            dataNode: "cue_no",
            width: "7%"
        },
        {
            dataNode: "composition",
            width: "16%"
        },
        {
            dataNode: "composer",
            width: "25%", 
            icon: "arrows-alt-v",
            iconTooltip: "Add Composer",
        },
        {
            dataNode: "publisher",
            width: "25.5%",
            icon: "arrows-alt-v",
            iconTooltip: "Add Publisher"
        },
        {
            dataNode: "how_used",
            width: "8%"
        },
        {
            dataNode: "min",
            width: "3.5%",
            validateMethod : 'validateNumbersOnly',
            validateMessage : 'Invalid Min'
        },
        {
            dataNode: "colon",
            width: "1%",
            className:'cueSheetColon',
        },
        {
            dataNode: "sec",
            width: "3%",
            validateMethod : 'validateDurationSec',
            validateMessage : 'Invalid Sec'
        }
    ],
    disableSelectCheckbox :false,
    newRowSelectOptions: [
        {
            dataNode: "check",
            width: "2%",
            inputType : 'none',
        },
        {
            dataNode: "cue_no",
            width: "3%",
            inputType : 'none',
        },
        {
            dataNode: "composition",
            width: "20%",
            inputType : 'select',
            addRowOptions:[],
            placeHolderText : ' -Select Composition- ',
            onchangeField : 'composition',
        },
        {
            dataNode: "is_arr",
            width: "3%",
            inputType : 'none',
        },
        {
            dataNode: "composer",
            width: "30%",
            inputType : 'select',
            addRowOptions:[],
            placeHolderText : '-Select Composer-',
            onchangeField : 'composer',
            relatedField : ['publisher']
        },
        {
            dataNode: "publisher",
            width: "20%",
            inputType : 'none',
        },
        {
            dataNode: "how_used",
            width: "20%",
            inputType : 'none'
        },
        // {
        //     dataNode: "duration",
        //     width: "4%",
        //     inputType : 'none'
        // }
    ],
};

const selectMaintitleOptionList = [
    {
        label: "Edit Licensor",
        value: 1
    },
    {
        label: "Add New Licensor to this CL Record",
        value: 2
    },
    {
        label: "Copy Licensor on this Cl Record",
        value: 3
    },
    {
        label: "Change/View Default Contact",
        value: 4
    },
    {
        label: "Remove Licensor from this Cl R",
        value: 5
    }
]
    
export const clearanceRecordPermissionsConfig = [
    {
        buttonName: "New CR",
        permissions: [ "Create a Clearance Record", "Create a Main Title Record" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "Licensor Notes Field",
        permissions: [ "Licensor Notes field" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "Licensor",
        permissions: [ "Licensor"],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "Cue Sheet Publisher Info",
        permissions: [ "Cue Sheet Info field", "Cue Sheet Info Field" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "Initial",
        permissions: [ "Initial Rights" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "Licensor Tracking",
        permissions: [ "License Tracking modal" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "Letters",
        permissions: [ "Letters: License Request, License, License Cover letter, Check Request, Check Request (AP), Producer Memo", "Letters Modal (+ generating any letter)" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "Letters: Quote Request, Confirmation",
        permissions: ["Letters: Quote Request, Confirmation", "Letters Modal (+ generating any letter)"],
        permissionGroupName: ["Clearance Record"]
    },
    {
        buttonName: "Air Date",
        permissions: [ "Header: Air Date, Clearance Rep fields" ],
        permissionGroupName: ["Clearance Record"]
    },
    {
        buttonName: "Main Title",
        permissions: ["Header: Main Title, Total Fees fields"],
        permissionGroupName: ["Clearance Record"]
    },
    {
        buttonName: "statusCROptions",
        permissions: ["Status, CR Options (MFN, Apply $ to HV, Recording Share)"],
        permissionGroupName: ["Clearance Record"]
    },
    {
        buttonName: "initialRightsCheckRequests",
        permissions: [ "Initial Rights Check Requests" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "optionRights",
        permissions: [ "Option Rights" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "optionRightsCheckRequests",
        permissions: [ "Option Rights Check Requests" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "optionAcquisitionModal",
        permissions: [ "Option Acquisition Modal" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "licenseStatuses",
        permissions: ["License statuses", "View License Statuses" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "EFCButton",
        permissions: [ "EFC Button" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "ClearanceBatches",
        permissions: [ "Clearance Batches", "MT CR: Can VIEW/USE/EXPORT Batches" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    },
    {
        buttonName: "song/performerInfo",
        permissions: [ "Song/Performer Information" ],
        permissionGroupName: ["Clearance Record", "SS - Main Title"]
    }
]



export {
    allUsersList, iconList, detailsIconList, valueList, selectOptionList, cueSheetConfig, cueSheetUsersList,
    filesList, syncLicensorConfig, syncLicensorList, cueSheetRecurringCueConfig,
    syncIconList, CueSheetPublisherConfig, CueSheetPublisherList,
    selectSongList, Licensor, selectLicensorType, mainTitlesyncLicensorConfig, cueSheetMusicEditorConfig,selectMaintitleOptionList
}