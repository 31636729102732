import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import MessageModal from "../../SharedComponents/MessageModal";
import { newShowSeasonPostJson } from './config';
import CircularProgress from "@material-ui/core/CircularProgress";
import "./NewSeason.scss";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { withUserContext } from '../../../contexts/UserContext';
import messages from '../../../Common/Messages.json';
import { validateNumbersOnly } from '../../../Common/Helper';
class NewSeason extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCopySeason: false,
      isOpen: false,
      newShowSeasonDetails: { ...newShowSeasonPostJson },
      seasonOptions: [],
      newSeasonOptions: [],
      currentSeasonOptions: [],
      isLoading: false,
      isPosting: false,
      postInitiated: false
    };
  }

  validateErrorFlags = () => {
    this.setState({ postInitiated: true })
    if(this.state.newShowSeasonDetails?.copy_season_flag === 1 && this.state.newShowSeasonDetails?.old_season_id != null) {
      this.handleSubmit()
    }
    else if (this.state.newShowSeasonDetails?.copy_season_flag === 0) {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    let postObj = this.state.newShowSeasonDetails;
    if ((postObj.mpm === null || validateNumbersOnly(postObj?.mpm))) {
      this.setState({ postInitiated: true, isPosting: true });
      postObj.show_id = this.props.show_id;
      postObj.pay_increase = parseFloat(postObj.pay_increase);
      console.log("postObj", postObj);
      let extensionUrl = '/copySeason';
      clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + extensionUrl, postObj, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
        .then((response) => {
          if (response.data.error) {
            this.setState({ isPosting: false });
            this.props?.notificationComponent(true, "fail");
          } else {
            this.setState({ isPosting: false });
            window.open(`/showseason/${response.data[0].var_new_show_season_id}`, '_blank')
            this.props.fetchData("getInitialValues", `/showSeason?showSeasonId=${this.props.show_season_id}`);
            this.props?.notificationComponent(true, "success");
            console.log("post response", response);
          }
        },
          (err) => {
            this.setState({ postInitiated: false, isPosting: false });
            this.props?.notificationComponent(true, "fail");
            console.log("Post agency details error: " + err);
          });
    } else {
      this.setState({ postInitiated: false, isPosting: false });
    }
  };

  handleChange = (field, value) => {
    switch (field) {
      case 'copy_season_flag':
        if (value === false) {
          this.setState((prevState) => ({
            newShowSeasonDetails: {
              ...prevState.newShowSeasonDetails,
              ["copy_season_flag"]: 0,
              ["old_season_id"]: null,
              ["copy_main_title_flag"]: 0,
              ["pay_increase"]: null,
              ["copy_business_affairs_flag"]: 0
            },
            postInitiated: false
          }));
        }
        else {
          this.setState((prevState) => ({
            newShowSeasonDetails: {
              ...prevState.newShowSeasonDetails,
              ["copy_season_flag"]: 1,
              ["old_season_id"]: this.state.currentSeasonOptions[0].value
            },
            postInitiated: false
          }));
        }
        break;
      case 'copy_main_title_flag':
      case 'copy_business_affairs_flag':
      case 'is_pilot':
        this.setState((prevState) => ({
          newShowSeasonDetails: {
            ...prevState.newShowSeasonDetails,
            [field]: value ? 1 : 0,
          },
          postInitiated: false
        }));
        break;

      default:
        if (value !== "") {
          this.setState((prevState) => ({
            newShowSeasonDetails: {
              ...prevState.newShowSeasonDetails,
              [field]: value,
            },
            postInitiated: false
          }));
        } else {
          this.setState((prevState) => ({
            newShowSeasonDetails: {
              ...prevState.newShowSeasonDetails,
              [field]: null,
            },
            postInitiated: false
          }));
        }

        break;
    }
  };


  fetchData = (field, url) => {
    this.setState({ isLoading: true });
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
      .then(response => {
        this.setState({ isLoading: false });
        let formattedList = [];
        switch (field) {
          case 'seasonOptions':
            let currentSeasonOptions = this.props.seasonOptions;
            let allSeasonOptions = response.data?.map(item => ({ value: item.season_id, label: item.season_name, is_active: item.is_active }));
            let deltaOptions = allSeasonOptions.filter(object1 => {
              return !currentSeasonOptions.some(object2 => {
                return object1.value === object2.value;
              });
            });

            this.setState({
              newSeasonOptions: deltaOptions,
              currentSeasonOptions: currentSeasonOptions
            })
            break;

        }
      },
        (err) => {
          this.setState({ isSecondaryLoading: false, isLoading: false });
          console.log("Error in fetching Address Types:", err)
        })
  }

  componentDidMount() {
    if (this.props?.show_season_id) {
      this.setState((prevState) => ({
        newShowSeasonDetails: {
          ...prevState.newShowSeasonDetails,
          ["type"] : this.props?.initialRecordData?.show_type
        }
      }))
      this.fetchData("seasonOptions", `/entitySearch?entity=SEASON&searchString=null`);
    }
  }

  render() {
    return (
      <>
        <div className='copyShowSeasonContainer'>
          <MDBContainer className="copyShowSeason">
            {this.state.isLoading ? (
              <div className="ContentLoader">
                <CircularProgress />
              </div>
            ) : (<>
              <MDBRow>
                <MDBCol md={12} className='mt-2'>
                  <BasicCheckbox
                    id={"is_pilot"}
                    label={"This Season contains a Pilot (this cannot be edited)"}
                    checked={this.state.newShowSeasonDetails?.is_pilot || false}
                    onChange={(e) => this.handleChange("is_pilot", e.target.checked)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className='mt-2'>
                <MDBCol md={4}>
                  <BasicLabel text={"Season"} isMandatory={true} />
                  <SelectField
                    showMandatory={this.state.postInitiated || false}
                    mandatoryWarning={messages.mandatoryWarning}
                    value={this.state.newShowSeasonDetails?.new_season_id}
                    options={this.state.newSeasonOptions}
                    onChange={(e) => this.handleChange("new_season_id", e.target.value)}
                  />
                </MDBCol>
                <MDBCol md={4}>
                  <BasicLabel text={"Type"} />
                  <p>{this.state.newShowSeasonDetails?.type}</p>
                </MDBCol>
                <MDBCol md={4}>
                  <BasicLabel text={"MPM #"} />
                  <BasicTextField
                    value={this.state.newShowSeasonDetails?.mpm || ""}
                    fieldValid={this.state?.newShowSeasonDetails?.mpm ? (!validateNumbersOnly(this.state?.newShowSeasonDetails?.mpm) ? true : false) : false}
                    inValidInput={messages.invalidInput}
                    onChange={(e) => this.handleChange('mpm', e.target.value)} />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <BasicLabel text={"Copy Information From Other Season"} />
                <BasicCheckbox
                  id={"copy_season_flag"}
                  label={"I would like to copy information form previous season"}
                  checked={this.state.newShowSeasonDetails?.copy_season_flag || false}
                  onChange={(e) => this.handleChange("copy_season_flag", e.target.checked)}
                />
              </MDBRow>
              {this.state.newShowSeasonDetails?.copy_season_flag ?
                <div>
                  <MDBRow>
                    <MDBCol md={10}>
                      <p>This action will copy Show Details, Cost Splits settings, and People tab information from the selected season, as well as the optionally below.</p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md={3}>
                      <BasicLabel text={"Copy From"} isMandatory={this.state.newShowSeasonDetails?.copy_season_flag === 1 ? true : false}/>
                      <SelectField
                        value={this.state.newShowSeasonDetails?.old_season_id}
                        options={this.state.currentSeasonOptions}
                        isMandatory={true}
                        showMandatory={this.state.newShowSeasonDetails?.copy_season_flag === 1 ? this.state.postInitiated : false}
                        mandatoryWarning={messages.mandatoryWarning}
                        onChange={(e) => this.handleChange("old_season_id", e.target.value)}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className={this.state.newShowSeasonDetails?.copy_season_flag === 1 && this.state.newShowSeasonDetails?.old_season_id === null && this.state.postInitiated ? "copy_main_title_flag" : null}>
                    <BasicCheckbox
                      id={"copy_main_title_flag"}
                      label={"Copy MainTitle Reacord(s)"}
                      checked={this.state.newShowSeasonDetails?.copy_main_title_flag || false}
                      onChange={(e) => this.handleChange("copy_main_title_flag", e.target.checked)}
                    />
                  </MDBRow>
                  <MDBRow>
                    <MDBRow>
                      <MDBCol md={1}></MDBCol>
                      <MDBCol md={3}>
                        <BasicLabel text={"Pay Increase"} />
                        <BasicTextField
                          value={this.state.newShowSeasonDetails?.pay_increase || ""}
                          onChange={(e) => this.handleChange('pay_increase', e.target.value)} placeholder="__.__ %" />
                      </MDBCol>
                      <MDBCol md={3}>
                        <p style={{ marginTop: "20px" }}>for Main Title song(s)</p>
                      </MDBCol>
                    </MDBRow>
                  </MDBRow>
                  <MDBRow>
                    <BasicCheckbox
                      id={"copy_business_affairs_flag"}
                      label={"Copy Show Composers/Music Supervisors (Business Affairs Tab)"}
                      checked={this.state.newShowSeasonDetails?.copy_business_affairs_flag || false}
                      onChange={(e) => this.handleChange("copy_business_affairs_flag", e.target.checked)}
                    />
                  </MDBRow>
                  <MDBRow>
                    <MDBCol className="ShowComposersAndMusicSupervisorsDealsWrapper">
                      <p>Show Composers and Music Supervisors and their deals, but not Check Requests</p>
                    </MDBCol>
                  </MDBRow>
                </div>
                : null
              }
              <MDBRow>
                <MDBCol md={8}></MDBCol>
                <MDBCol md={4}>
                  <MDBRow>
                    <MDBCol>
                      <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Create"} onClick={this.validateErrorFlags} />
                    </MDBCol>
                    <MDBCol>
                      <BasicButton
                        variant="outlined" text={"Cancel"}
                        onClick={() => this.props.handleClose()}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </>)}
          </MDBContainer>
        </div>

      </>
    );
  }
}
export default withUserContext(NewSeason);
