import { MDBCol, MDBIcon, MDBRow, MDBContainer, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { mainTitleDetails, Licensor, licensorRightsConfig } from './MainTitleConfig';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import "../ShowSeasonDetails/ShowSeasonDetails.scss";
import "../../EpisodeAndClearence/EpisodeAndClearenceDetails/EpisodeAndClearence.scss"
import Details from "./Details";
import Popover from '@material-ui/core/Popover';
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';
import CompositionFiles from '../../EpisodeAndClearence/EpisodeAndClearenceDetails/CompositionFiles';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import MessageModal from '../../SharedComponents/MessageModal';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { withUserContext } from '../../../contexts/UserContext';
// import '../../EpisodeAndClearence/EpisodeAndClearenceDetails/EpisodeAndClearence.scss';
import { getFormattedDate } from "../../../Common/Helper"
import { clearanceRecordPermissionsConfig } from '../../EpisodeAndClearence/EpisodeAndClearenceDetails/Config';
let defaultRights = {};
class MainTitleTabDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainTitleDetails: mainTitleDetails,
            mainTitleId: this.props?.main_title_id,
            showNewMainTitleCrearanceRecord: false,
            setPopover: null,
            showSuccess: false,
            isLoading: false,
            isMainTitleLicensor: false,
            showFail: false,
            mode: "view",
            fieldChanged: false,
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            backUp: [],
            licensorsInfo: [],
            isFetchingMainTitleDetails: false,
            isCopyMT: false,
            copySuccess: false,
            noMTForPrevSeason: false,
            EmailLetterPermission: null,
            notificationMessage:null
        }
    }

    componentDidMount() {
        if (this.props?.main_title_id) {
            this.setState({ mainTitleId: this.props?.main_title_id }, () => {
                this.getMainTitleDetails();

            })
        }




        defaultRights = {
            initial_term: this.props?.showSeasonDetails?.initial_term,
            initial_term_id: this.props?.showSeasonDetails?.initial_term_id,
            initial_territory: this.props?.showSeasonDetails?.initial_territory,
            initial_territory_id: this.props?.showSeasonDetails?.initial_territory_id,
            initial_right_id: this.props?.showSeasonDetails?.initial_right_id,
            initial_right_abbr: this.props?.showSeasonDetails?.initial_right_abbr,
            alt_initial_term: this.props?.showSeasonDetails?.alt_initial_term,
            alt_initial_term_id: this.props?.showSeasonDetails?.alt_initial_term_id,
            alt_initial_territory: this.props?.showSeasonDetails?.alt_initial_territory,
            alt_initial_territory_id: this.props?.showSeasonDetails?.alt_initial_territory_id,
            alt_initial_right_id: this.props?.showSeasonDetails?.alt_initial_right_id,
            alt_initial_right_abbr: this.props?.showSeasonDetails?.alt_initial_right_abbr
        }

    }
    componentDidUpdate(prevProps, prevState) {


        if (prevProps?.refreshPage?.value !== this.props?.refreshPage?.value && this.props?.refreshPage?.modal === 'EpisodeClearance') {

            this.getMainTitleDetails();
            this.props?.toggleRefreshPage('');
        }
    }

    handleChangePage = () => {       
        this.getMainTitleDetails();      
    }

    getMainTitleDetails = () => {
        this.setState({ isFetchingMainTitleDetails: true });
        let mainTitleId = this.state.mainTitleId || this.state.licensorsInfo?.[0]?.main_title_id;
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/mainTitle?main_title_id=${mainTitleId}`,
            this.props?.userContext?.active_tenant?.tenant_id,
            this.props?.showSeasonDetails?.division_id)
            .then(response => {
                console.log('re',response);
                let data = response?.data;
                if(response?.data?.length > 0){
                let EmailLetterPermission = this.props?.mainTitlePermissions?.find((per) => per.permission_name === "Main Title Letters: Email") ? this.props?.mainTitlePermissions?.find((per) => per.permission_name === "Main Title Letters: Email") : false;
                this.setState({ licensorsInfo: data, isFetchingMainTitleDetails: false, EmailLetterPermission }, () => {
                    this.getMainTitleLicensors();
                });
            }else{
                this.setState({ licensorsInfo: null, isFetchingMainTitleDetails: false });
            }
            },
                (err) => {
                    console.log("Error in fetching Main Title Details", err);
                    this.setState({ isFetchingMainTitleDetails: false });
                })
    }

    getMainTitleLicensors = () => {
        this.setState({ isLoading: true, isFetchingMainTitleDetails: true })
        let mainTitleId = this.state.mainTitleId || this.state.licensorsInfo?.[0]?.main_title_id;
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/mainTitleLicensors?show_season_id=${this.props?.show_season_id}&main_title_id=${mainTitleId}&main_title_song_id=${this.state.licensorsInfo?.[0]?.songs_list?.[0]?.main_title_song_id}`,
            this.props?.userContext?.active_tenant?.tenant_id, this.props?.showSeasonDetails?.division_id)
            .then(response => {

                let data = response?.data
                data[0]?.licensors_list?.map((item) => {
                    item.editing = false
                    item.quote_request_date = item.quote_request_date ? item?.quote_request_date?.split('T')[0] : null
                    item.confirmation_date = item.confirmation_date ? item?.quote_request_date?.split('T')[0] : null
                    item.license_request_date = item.license_request_date ? item?.license_request_date?.split('T')[0] : null
                    item.clearance_licensor_name = { value: item?.licensor_id || null, text: item?.licensor_name || null }
                    if (item?.rights?.length === 0) {
                        item.rights = [...licensorRightsConfig]
                    }
                    if (item?.rights?.length === 1) {
                        if (item.rights?.[0]?.is_initial === 1) {
                            item.rights.push(...licensorRightsConfig.filter(i => i.is_initial === 0));
                        }
                        else if (item.rights?.[0]?.is_alt_initial === 1) {
                            item.rights.push(...licensorRightsConfig.filter(i => i.is_alt_initial === 0));
                        }
                    }
                    item.main_title_check_request?.map(option => {
                        option['editing'] = false
                        option['payee_remit_to'] = option['payee_remit_to_id'];
                        option.requested_date = option.requested_date ? getFormattedDate(option.requested_date, true) : null;
                        option.episodes_list = option.episodes_list?.map(o => ({
                            ...o, ...{ value: o.show_season_episode_id, text: o.episode_title }
                        }))
                    })
                    item.main_title_licensor_optional_rights?.map(option => {
                        option['bch'] = 1;
                        option.option_acquired_date = option?.option_acquired_date ? getFormattedDate(option?.option_acquired_date?.split('T')[0]) : null
                        option?.main_title_licensor_optional_check_requests?.map(optionPayee => {
                            optionPayee['payee_remit_to'] = optionPayee['payee_remit_to_id'];
                            optionPayee.requested_date = optionPayee?.requested_date ? optionPayee?.requested_date?.split('T')[0] : optionPayee?.requested_date
                            optionPayee.episodes_list_opt = optionPayee?.episodes_list_opt?.map(o => ({
                                ...o, ...{ value: o.show_season_episode_id, text: o.episode_title }
                            }))
                        })

                    })
                    return item;
                })
                this.setState({
                    mainTitleLicenserInfo: data,
                    backUp: data,
                    isFetchingMainTitleDetails: false,
                    mainTitleId: data?.[0]?.main_title_id,
                    showSeasonId: data?.[0]?.show_season_id,
                    showId: data?.[0]?.show_id,
                    seasonId: data?.[0]?.season_id,
                    isLoading: false,
                },
                    // () => { this.checkLicensors() }
                );
            },
                (err) => {
                    console.log("Error in fetching Main Title Details:", err)
                })
    }

    checkLicensors = () => {
        let checkLicensors = this.state.mainTitleLicenserInfo;
        if (checkLicensors && checkLicensors.length == 0) {
            // this.setState({ isFetchingMainTitleDetails: true });
            clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/songDetails?song_id=${this.state.licensorsInfo?.[0]?.songs_list?.[0]?.song_id}`,
                this.props?.userContext?.active_tenant?.tenant_id, this.props?.showSeasonDetails?.division_id)
                .then(response => {
                    let data = response?.data
                    this.setState({
                        mainTitleSongInfo: data,
                        isFetchingMainTitleDetails: false
                    }, () => {
                        // this.fetchLicensorsList() 
                    });
                },
                    (err) => {
                        console.log("Error in fetching Song details:", err);
                        this.setState({ isFetchingMainTitleDetails: false });
                    })
        }
    }

    fetchLicensorsList = () => {
        let mainTitleSongInfo = this.state.mainTitleSongInfo;
        let syncMasterLicensors = mainTitleSongInfo[0]?.licensors_list?.filter(item => item.is_SM === 1).length > 0 ?
            this.createLicensorInstances(mainTitleSongInfo[0]?.licensors_list?.filter(item => item.is_SM === 1), "SM") : [];
        let syncLicensors = mainTitleSongInfo[0]?.licensors_list?.filter(item => item.is_SM === 0).length > 0 ?
            this.createLicensorInstances(mainTitleSongInfo[0]?.licensors_list?.filter(item => item.is_SM === 0), "S") : []
        let masterLicensors = [];
        if (mainTitleSongInfo[0]?.performers_list && mainTitleSongInfo[0]?.performers_list.length > 0) {
            mainTitleSongInfo[0]?.performers_list.map(function (item) {
                item?.performer_licensor_list?.forEach(element => {
                    masterLicensors.push(element);
                });
            })
        }
        masterLicensors = this.createMasterLicensorInstances(masterLicensors, "M");
        let licensorsArray = syncMasterLicensors.concat(syncLicensors, masterLicensors)
        licensorsArray.map(item => {
            item['editing'] = false;
            item?.main_title_licensor_optional_rights?.map((option) => (option['editing'] = false))
            item?.main_title_check_request?.map((option) => option['editing'] = false)
        })
        //Adding Parent Data From Array
        var obj = [{
            "main_title_id": this.state.mainTitleId,
            "show_season_id": this.state.showSeasonId,
            "show_id": this.state.showId,
            "season_id": this.state.seasonId,
            "duration": "",
            "is_active": 1,
            "licensors_list": licensorsArray
        }]
        obj[0]?.licensors_list?.map(licensor => {
            if (licensor?.rights?.length === 0) {
                licensor.rights = [...licensorRightsConfig]
                return licensor;
            }
        })
        this.setState({ mainTitleLicenserInfo: obj }, () => { });
    }

    createLicensorInstances = (list, type) => {
        let obj = [];
        list.map(function (item) {
            var licensor = JSON.parse(JSON.stringify({ ...Licensor }));
            licensor.licensor_id = item.s_licensor_id;
            licensor.licensor_name = item.licensor_name;
            licensor.licensor_type = type;
            licensor.share = item.licensor_share;
            licensor.is_active = item.licensor_is_active; //--Clarification to use this flag or other
            obj.push(licensor);
        })
        return obj;
    }

    createMasterLicensorInstances = (list, type) => {
        let obj = [];
        list.map(function (item) {
            var licensor = Licensor;
            licensor.licensor_id = item.p_licensor_id;
            licensor.licensor_name = item.performer_licensor_name;
            licensor.licensor_type = type;
            licensor.share = item.performer_licensor_share;
            // licensor.is_active = item.licensor_is_active; //--Clarification to use this flag or other
            obj.push(licensor);
        })
        return obj;
    }

    handleListEdit = (field, value, obj, listName) => {
        let licInfo = { ...this.state.mainTitleLicenserInfo };
        let id = (listName === "licensors_list") ? "main_title_licensor_id" : "";
        let list = licInfo[0][listName]?.map(item => {
            let currentVal = item;
            // //Need to have checks at this place
            if ((item[id] && obj[id] && (item[id] === obj[id]))) {

                item[field] = value;

            }
        })
        this.handleGeneralEdit(listName, list);
    }

    handleGeneralEdit = (field, value) => {
        this.setState(prevState => ({
            mainTitleLicenserInfo: {
                ...prevState.mainTitleLicenserInfo,
                [field]: value
            },
        }), () => {
            this.fieldChanged(true, "change");
        })
    }

    fieldChanged = (value, mode = "error") => {
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value });
            case "error": return this.setState({ showError: value });
        }
    }

    setMode = (confirm, index) => {
        let mainTitleLicenserInfo = this.state.mainTitleLicenserInfo
        let r = mainTitleLicenserInfo?.[0]?.licensors_list
        let editCheck = r?.findIndex((item, i) => item.editing === true)
        if (editCheck !== -1) {
            if (this.state.fieldChanged === true) {
                this.fieldChanged(true, "error");
            } else {
                if (editCheck === index) {
                    this.updateEdits(editCheck, false)
                }
                else {
                    this.updateEdits(editCheck, false, undefined, () => this.updateEdits(index, true))
                }
            }
        } else {
            this.updateEdits(index, true)
        }
        this.setState({
            index,
        })
    }

    updateEdits = (variable, variableValue, confirm, callback) => {
        let mainTitleLicenserInfo = JSON.parse(JSON.stringify(this.state.mainTitleLicenserInfo));
        let r = mainTitleLicenserInfo?.[0]?.licensors_list
        r[variable]['editing'] = variableValue
        r[variable]?.main_title_check_request?.map((option) => {
            option['editing'] = variableValue;
            let obj = {
                text: option?.payee_remit_to_name,
                value: typeof option?.payee_remit_to === 'object' && option?.payee_remit_to !== null ? option?.payee_remit_to?.value : option?.payee_remit_to
            }
            option.payee_remit_to = { ...obj }
        })
        r[variable]?.main_title_licensor_optional_rights?.map((option) => {
            option['editing'] = variableValue
            option?.main_title_licensor_optional_check_requests?.map((item) => {
                item['editing'] = variableValue;
                let obj = {
                    text: item.payee_remit_to_name,
                    value: typeof item?.payee_remit_to === "object" && item?.payee_remit_to !== null ? item?.payee_remit_to?.value : item?.payee_remit_to
                }
                item.payee_remit_to = { ...obj }
            })
        })
        this.setState({
            mainTitleLicenserInfo
        }, () => {
            if (callback) callback()
        })
    }

    onConfirm = () => {
        let mainTitleLicenserInfo = this.state.mainTitleLicenserInfo
        let r = mainTitleLicenserInfo?.[0]?.licensors_list
        let editCheck = r?.findIndex((item) => item.editing === true)
        let index = this.state.index
        this.updateEdits(editCheck, false, true, editCheck !== index ? () => this.updateEdits(index, true, true) : undefined)
        this.setState({
            fieldChanged: false,
            showError: false
        })
    }

    onCancel = () => {
        this.setState({ showError: false })
    }

    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.closPopup();
        }
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.props?.handleClose();
        }
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }


    handleShowNewMainTitleCrearanceRecord = () => {
        this.setState({ showNewMainTitleCrearanceRecord: true })
    }
    handleHideNewMainTitleCrearanceRecord = () => {
        this.setState({ showNewMainTitleCrearanceRecord: false })
    }
    handleClick = (event) => {
        this.setState({
            setPopover: event.currentTarget
        })
    };
    handleClose = () => {
        this.setState({
            setPopover: null
        })
    };
    handleshowEditSongModal = () => {
        this.setState({ showEditSongModal: true })
    }
    handleHideShowEditSongModal = () => {
        this.setState({ showEditSongModal: false })
    }

    handleRefreshMainTitle = (mainTitleId) => {
        this.setState({ mainTitleId: mainTitleId }, () => {
            this.getMainTitleDetails();
        })
    }

   
    
    findPreviousSeason(currentSeasonId) {
        // Sort seasons by season_id in ascending order
        const sortedSeasons = this.props?.showSeasonDetails?.seasons?.sort((a, b) => a.season_id - b.season_id);
        
        // Find the index of the current season
        const currentIndex = sortedSeasons.findIndex(season => season.season_id === currentSeasonId);
        
        // If current season is not found or it's the first in the list, return null
        if (currentIndex === -1 || currentIndex === 0) {
            return null;
        }
        
        // Return the previous season
        return sortedSeasons[currentIndex - 1];
    }
    
      

    copyMainTitleFromPrevSeason = () => {
        this.setState({ isCopyMT: true })
        let postJson = {
            "copy_main_title_flag": 1,
            "previous_show_season_id": this.findPreviousSeason(this.props?.showSeasonDetails?.season_id)?.show_season_id,
            "previous_season_id": this.findPreviousSeason(this.props?.showSeasonDetails?.season_id)?.season_id,
            "show_season_id": this.props?.show_season_id,
            "season_id": this.props?.showSeasonDetails?.season_id,
            "show_id": this.props?.showSeasonDetails?.show_id
        }

        clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + '/mainTitle', postJson, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then((response) => {
                if (!response.data.error) {
                    if (response.data[0].main_title_id != 0) {
                        this.setState({ isCopyMT: false, copySuccess: true, showSuccess: true,notificationMessage:null }, () => {
                            this.notificationComponent(true, "success")
                            this.handleRefreshMainTitle(response.data[0].main_title_id)
                        })
                    }
                    else {
                        this.setState({ isCopyMT: false, noMTForPrevSeason: true,notificationMessage:null })
                        this.notificationComponent(true, "fail")
                    }
                }
                else {
                    this.setState({ isCopyMT: false })
                    this.notificationComponent(true, "fail")
                }
            },
                (err) => {
                    this.setState({ isCopyMT: false })
                    this.notificationComponent(true, "fail")
                    console.log("Post Copy Main title error: " + err);
                });
    }

    notificationMessage(message){
        this.setState({notificationMessage: message || null})
    }

    render() {
        let createMainTitlePermission = this.props.mainTitlePermissions?.find((per) => clearanceRecordPermissionsConfig?.find((item) => item.buttonName == "New CR" && item.permissions.includes(per.permission_name)))

        let open = Boolean(this.state.setPopover);
        let id = open ? 'simple-popover' : undefined;
        let message = this.state.showError ? this.state.unsavedMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : "";
        let severity = this.state.showFail || this.state.noMTForPrevSeason ? "error" : this.state.showSuccess ? "success" : "";
         let messageNotification = null;
         if (this.state.copySuccess) {
             messageNotification = "Copied Successfully";
             setTimeout(() => {
                this.setState({copySuccess:false})
             }, 3000);
         } else if (this.state.notificationMessage) {
             messageNotification = this.state.notificationMessage;
         } else if (this.state.showSuccess && !this.state.notificationMessage) {            
                messageNotification = "The changes are saved successfully!";
         } else if (this.state.noMTForPrevSeason) {
             messageNotification = "Previous season doesn't have Main Title";
         } else if (this.state.showFail && !this.state.noMTForPrevSeason) {
             messageNotification =  "Saving changes failed!"; 
         }
        return (
            <MDBContainer fluid className="Main-Title-Container episode-clearence-container p-0">
                <>
                    <NotificationComponent
                        open={this.state.showSuccess || this.state.showFail}
                        message={messageNotification}
                        severity={severity}
                        handleResetNotify={this.handleResetNotify.bind(this)}
                    />
                    <MessageModal
                        open={this.state.showError || false}
                        title={comfirmModalTitle}
                        message={message}
                        hideCancel={false}
                        primaryButtonText={"OK"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={() => this.onConfirm()}
                        handleClose={() => this.onCancel()} />
                </>
                {this.state.isFetchingMainTitleDetails ?
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div> :
                    <>
                        {this.props.showSeasonDetails?.no_mt === 1 ? <p>No main title is present for this show season.</p> :
                            <>
                                {this.state.licensorsInfo?.[0]?.main_title_id ?
                                    <CompositionFiles
                                        className={"CompositionFiles-Container"}
                                        isMainTitle={true}
                                        handleChangePage={this.handleChangePage}
                                        licensorsInfo={this.state.licensorsInfo}
                                        clearneceLicenserInfo={this.state.mainTitleLicenserInfo}
                                        handleGeneralEdit={this.handleGeneralEdit}
                                        handleListEdit={this.handleListEdit}
                                        mode={this.state.mode}
                                        setMode={this.setMode}
                                        defaultRights={defaultRights}
                                        notificationComponent={this.notificationComponent}
                                        notificationMessage={this.notificationMessage.bind(this)}
                                        fieldChanged={this.fieldChanged}
                                        showSeasonId={this.props?.showSeasonDetails?.show_season_id}
                                        showId={this.props?.showSeasonDetails?.show_id}
                                        seasonId={this.props?.showSeasonDetails?.season_id}
                                        mainTitleId={this.props?.main_title_id || this.state.licensorsInfo?.[0]?.main_title_id}
                                        songId={this.state.licensorsInfo?.[0]?.songs_list?.[0]?.clearance_song_id}
                                        clearenceId={this.state.licensorsInfo[0]?.clearance_id}
                                        division_id={this.props.division_id}
                                        show_name={this.props?.showSeasonDetails?.show_name}
                                        season_name={this.props?.showSeasonDetails?.season_name ? this.props?.showSeasonDetails?.season_name : this.state.licensorsInfo?.[0]?.season_name}
                                        clearance_rep_id={this.props.showSeasonDetails?.clearance_rep_id}
                                        clearance_rep={this.props.showSeasonDetails?.clearance_rep}
                                        episodeLength={this.props.episodeLength}
                                        toggleRefreshPage={this.props?.toggleRefreshPage}
                                        toggleRefreshMainTitleLicensor={this.getMainTitleDetails}
                                        permissionArrMainTitile={this.props?.permissionArrMainTitile}
                                        show_season_episode_id={this.props?.show_season_episode_id}
                                        songAndPerformerPermissions={this.props?.songAndPerformerPermissions}
                                        mainTitlePermissions={this.props?.mainTitlePermissions}
                                        batchesPermissions={this.props?.batchesPermissions}
                                        EmailLetterPermission={this.state?.EmailLetterPermission}
                                        episodeSongPermissions={this.props?.episodeSongPermissions}
                                    /> :
                                    <>
                                        <MDBCard>
                                            <MDBCardBody>
                                                <MDBCardText>
                                                    <span className="headingContainer">
                                                        No Main Title Record has been added
                                    <span>
                                                            <MDBIcon className="no-main-title-icon" icon={"frown"} />
                                                        </span>
                                                    </span>
                                                    {createMainTitlePermission?.edit == 1 &&
                                                        <>
                                                            <BasicButton
                                                                text="Create new MT record"
                                                                variant="contained"
                                                                className="main-title-btn"
                                                                onClick={() => this.setState({ showNewMainTitleCrearanceRecord: true })}
                                                            />
                                                            {this.props?.showSeasonDetails?.seasons?.length > 1 &&
                                                                <div className="mt-1">
                                                                    <BasicButton
                                                                        text={this.state.isCopyMT === true ? <CircularProgress color="inherit" size={20} /> : "Copy from Prev. Season"}
                                                                        variant="contained"
                                                                        className="copy-mt-btn"
                                                                        onClick={() => this.copyMainTitleFromPrevSeason()}
                                                                    />
                                                                </div>}
                                                        </>}
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </>
                                }
                            </>
                        }
                    </>}
                {this.state.showNewMainTitleCrearanceRecord && (
                    <MainViewModalComponent
                        open={this.state.showNewMainTitleCrearanceRecord}
                        handleClose={() => this.setState({ showNewMainTitleCrearanceRecord: false })}
                        headerText={'Main Title Clearance Record'}
                        mode={"New"}
                        modalName={"Main Title Clearance Record"}
                        isMainTitle={true}
                        mainTitleId={this.props?.main_title_id || this.state.licensorsInfo?.[0]?.main_title_id}
                        show_season_id={this.props?.showSeasonDetails?.show_season_id}
                        showId={this.props?.showSeasonDetails?.show_id}
                        seasonId={this.props?.showSeasonDetails?.season_id}
                        division_id={this.props?.showSeasonDetails?.division_id}
                        show_name={this.props?.showSeasonDetails?.show_name}
                        season_name={this.props?.showSeasonDetails?.seasons?.find(item => item?.show_season_id === this.props?.showSeasonDetails?.show_season_id)?.season_name}
                        clearance_rep_id={this.props.showSeasonDetails?.clearance_rep_id}
                        clearance_rep={this.props.showSeasonDetails?.clearance_rep}
                        episodeLength={this.props.episodeLength}
                        toggleRefreshMainTitle={(mainTitleId) => this.handleRefreshMainTitle(mainTitleId)}
                        toggleRefreshPage={this.props?.toggleRefreshPage}
                        toggleRefreshMainTitleLicensor={this.getMainTitleDetails}
                        {...this.props}
                    />
                )}
                {
                    this.state.showEditSongModal &&
                    <MainViewModalComponent
                        open={this.state.showEditSongModal}
                        handleClose={this.handleHideShowEditSongModal}
                        headerText={"Edit Song"}
                        mode={""}
                        modalName={"Edit Song"}
                    />
                }
            </MDBContainer>
        )
    }
}

export default withUserContext(MainTitleTabDetails)