import React from 'react';
import TableComponent from '../../components/SharedComponents/Table';
import { configObjectAllUsers, allUsersList, selectOptions, userFilters, filterUsersConfig } from '../UserManagement/config';
import "../UserManagement/usermanagement.scss";
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import { MDBRow, MDBCol } from "mdbreact";
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../../src/Common/TableHelper';
import CreateOrEditUserModal from '../../../src/pages/NewUserPopUp/CreateOrEditUserModal';
import TablePagination from '@material-ui/core/TablePagination';
import * as Constants from '../../constants/constants';
import ClearTrackService from '../../services/service';
import { withUserContext } from '../../contexts/UserContext';
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import Chip from '@material-ui/core/Chip';
import UsersReport from './UsersReport';
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';
import axios from 'axios';

export default withUserContext(class UserManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            tabListData: [],
            searchTerm: null,
            listBackUp: [],
            list: allUsersList,
            page: 0,
            rowsPerPage: 10,
            ismodalOpen: false,
            isModal: false,
            titleGroup: "",
            list1: [],
            list2: [],
            newUserList: {},
            titleUser: "",
            userTitle: "",
            id: "",
            isFetchingGroupDetails: false,
            isShowLoading: false,
            currentList: [],
            usersReport: false,
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            config: JSON.parse(JSON.stringify({ ...configObjectAllUsers })),
            isUserLoading: false,
            userOptions: [],
            divisionOptions: [],
            showOptions: [{value: "All Shows", text: "All Shows"}],
            roleOptions: [],
            levelOptions: [],
            usersAllData: [],
            isFetchingUserDetails: false,
            emailList: [],
            filterInputs: [],
            filterList: JSON.parse(JSON.stringify({ ...userFilters })),
            searchCancelToken: null
        }
    }

    componentDidMount() {
        document.title = 'User Management'
        this.getUserDetails();
        this.getStaticData("division", "divisionOptions");
        this.getFilterData("role", "roleOptions");
        this.getFilterData("security_level", "levelOptions");
        this.getEntity("userOptions", "USER", "");
        // this.getEntity("showOptions", "SHOW", "");
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
            this.getUserDetails();
        }
    }

    getFilterData = (tableName, fieldName) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${tableName}`, null)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ [fieldName]: formattedList, });
            },
                (err) => {
                    console.log("Error in fetching Tenant Details:", err)
                })
    }

    getStaticData = (tableName, fieldName) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${tableName}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ [fieldName]: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Tenant Details:", err)
                })
    }

    getEntity = (entity, entitytype, searchString) => {
        let option = {
            "searchString": searchString,
        }
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        let loading = (entitytype === 'SHOW_DIVISION') ? 'isShowLoading' : (entitytype === 'USER') ? 'isUserLoading' : 'loading';
        this.setState({ [loading]: true });
        ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`, cancelToken, this.props.userContext.active_tenant.tenant_id, null)
            .then(response => {
                let formattedList = [];
                if (entitytype === 'SHOW_DIVISION') {
                    formattedList = response?.data?.map(item => ({ value: item.show_id, text: item.show_name }));
                    formattedList.unshift({text: "All Shows", value: "All Shows"});
                } else if (entitytype === 'USER') {
                    formattedList = response?.data?.map(item => ({ value: item.user_id, text: item.full_name }));
                }
                this.setState({ [entity]: formattedList, [loading]: false });
            },
                (err) => {
                    this.setState({ [loading]: false });
                    console.log("Error in fetching entities:", err)
                })
    }

    getUserDetails = (refresh = null) => {
        // if (this.props?.userContext?.active_tenant?.tenant_id) {
        this.setState({ isFetchingUserDetails: true });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + '/userAll', this.props.userContext?.active_tenant?.tenant_id, null)
            .then(response => {
                let formattedList = response.data.map(list => {
                    return {
                        "name": list?.full_name,
                        "displayName": list?.display_name,
                        "title": list?.title,
                        "company": list?.company,
                        "supervisor": list?.supervisor_full_name,
                        "assistant": list?.assistant_full_name,
                        "tenant": (list?.tenants.map((item, index) => (index ? ', ' : '') + item?.tenant_name)[0]) === 'null' ? ["--"] : (list?.tenants?.map((item, index) => (index ? ', ' : '') + item?.tenant_name)),
                        "division": (list?.tenants.map(item => item?.division_role.map((division, index) => (index ? ', ' : '') + division?.division_name))[0][0]) === 'null' ? ["--"] : list?.tenants?.map(item => item?.division_role?.map((division, index) => (index ? ', ' : '') + division?.division_name)),
                        "role": list.is_user_provisioner === 1 ? ["User Provisioner"] : (list?.tenants.map(item => item?.division_role?.map((role, index) => (index ? ', ' : '') + role?.role_name))[0][0]) === 'null' ? ["--"] : list?.tenants?.map(item => item?.division_role?.map((role, index) => (index ? ', ' : '') + role?.role_name)),
                        // "level": (list.tenants.map(item => item.division_role.map((level, index) => (index ? ', ' : '') + level.security_level_name))[0][0]) === 'null' ? "--" : list.tenants.map(item => item.division_role.map((level, index) => (index ? ', ' : '') + level.security_level_name)),
                        "show": list?.show_season?.map((show, index) => (index ? ', ' : '') + show?.show_name),
                        "email": list?.login_email,
                        "phone": list?.phone,
                        "status": list?.is_active ? "Active" : "Inactive",
                        "all_shows": list?.tenants?.map(item => item?.division_role?.map(div => div?.is_all_shows)),
                        "id": list?.user_id
                    }
                })
                console.log("formattedList", formattedList)
                let emailList = response.data.map(item => { return { "email": item.login_email?.toLowerCase() } })
                console.log("Email List", emailList);
                if (refresh) {
                    this.setState({ filterInputs: [], filterList: JSON.parse(JSON.stringify({ ...userFilters })) })
                }
                this.setState({ list: formattedList, emailList: emailList, list1: handlePaginationList(this.state.page, this.state.rowsPerPage, formattedList), currentList: formattedList, listBackUp: formattedList, isFetchingUserDetails: false, sortCount: 0, isSortByAsc: true }, () => this.handleTableSort('Name'));
            },
                (err) => {
                    this.setState({ isFetchingUserDetails: false });
                    console.log("Error in fetching Agency Details:", err)
                })
        // }
    }

    handleTabChange = (event, value) => {
        let tabList = value === 0 ? this.state.list1 : this.state.list2;
        this.setState({
            page: 0,
            rowsPerPage: 10,
            tabValue: value,
            list: handlePaginationList(this.state.page, this.state.rowsPerPage, tabList),
            listBackUp: tabList,
            currentList: tabList,
        });
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            list1: handlePaginationList(newPage, this.state.rowsPerPage, this.state.currentList)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10), page: 0,
            list1: handlePaginationList(0, parseInt(event.target.value, 10), this.state.currentList)
        });
    }


    handleOpenGroup = (id) => {
        this.setState({
            isModal: true,
            id: id === undefined ? "" : id,
            titleGroup: id === undefined ? "New Group" : "Edit Group"
        });
    }
    handleUserSelection = (item, node) => {
        if (node == 'name') {
            this.setState({ titleUser: "Edit User", id: item?.id, ismodalOpen: true })
        }
    }

    handleOpenUser = (id) => {
        this.setState({
            ismodalOpen: true,
            id: id === undefined ? "" : id,
            titleUser: "New User"
        });
    }

    hanldeUsersReport = () => {
        this.setState({
            usersReport: true
        })
    }

    handleTableColumnClick = (column) => {
        console.log("this.state.sortBy", this.state.sortBy);
        console.log("column", column);
        console.log("sortCount", this.state.sortCount);
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleFilterDelete = (data, fieldValue, index) => {
        alert("Delete the item:");
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            // config: updateTableSortConfigObject(configObjectAllUsers, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                config: updateTableSortConfigObject(configObjectAllUsers, this.state.isSortByAsc, this.state.sortBy, colName),
                sortBy: colName,
                list1: handlePaginationList(this.state.page, this.state.rowsPerPage,
                    tableSortList(colName === "Phone" ? "Number" : "Name", this.getSortNode(colName), this.state.list1, this.state.isSortByAsc))
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Name": return "name";
            case "Display Name": return "displayName";
            case "Tenant": return "tenant";
            case "Division": return "division";
            case "Role": return "role";
            // case "Level": return "level";
            case "Email": return "email";
            case "Phone": return "phone";
            case "Status": return "status";
        }
    }

    clearFilter = () => {
        this.getUserDetails();
        // let tableData = handlePaginationList(this.state.page, this.state.rowsPerPage, this.state.listBackUp )
        this.setState({ filterInputs: [], filterList: JSON.parse(JSON.stringify({ ...userFilters })) })
    }

    onChangeHandler = (type, value) => {
        let filteredUser = this.state.filterList
        filteredUser[type] = value
        this.setState({ filterList: filteredUser })
    }

    addFilterChangeHandler = (field, value) => {
        let newEntry = [`${field} :${value}`]
        let filterInputs = this.state.filterInputs
        let check = filterInputs?.filter((item, index) => {
            var keyValue = item.toString().split(":");
            if (keyValue[0].trim() === field) {
                filterInputs[index] = newEntry
                return item = newEntry
            }
            // var name = keyValue[0].trim();
            // var value = keyValue[1].trim().toLowerCase();
        })
        if (check?.length === 0) {
            filterInputs.push(newEntry)
        }
        var keyValuePair;
        filterInputs = filterInputs?.filter((item, index) => {
            keyValuePair = item.toString().split(":");
            if (keyValuePair[1] !== "") {
                return item
            }
        })
        this.setState({ filterInputs: filterInputs })
        let filteredTableData = this.applyFilterTable(filterUsersConfig, this.state.listBackUp, filterInputs, false)
        let data = handlePaginationList(this.state.page, this.state.rowsPerPage, filteredTableData)
        this.setState({ list1: data, currentList: filteredTableData })
    }

    applyFilterTable = (config, data, filterValues, filtersNone) => {
        var finalList = [];
        var finalResult = data.slice();
        var primaryKey = config.primaryKey;
        if (!filtersNone) {

            filterValues.map((filter) => {
                var keyValue = filter.toString().split(":");
                var name = keyValue[0].trim();
                var value = keyValue[1].trim().toLowerCase();

                var index = -1; // Object.keys(config?.headings).indexOf((item) => config?.headings[item].headingLabel == name);

                Object.keys(config?.headings).map((item) => {
                    console.log(config?.headings[item])
                    if (config?.headings[item].headingLabel == name) {
                        index = item;
                        return;
                    }
                });

                var nodeName = config?.dataNodes[index];
                var filteredList = data?.filter((item) => {
                    if (nodeName === 'division' || nodeName === 'role' || nodeName === 'level') {
                        return item[nodeName]?.length > 0 ? item[nodeName]?.some(ele => ele?.toString().toLowerCase().indexOf(value) > -1) : false;
                    } 
                    else if (nodeName === 'show' && value === 'all shows') {
                        return item.all_shows[0]?.includes(1) ? true : false;
                    }
                    else if (nodeName === 'show') {
                        return item[nodeName]?.length > 0 ? item[nodeName]?.some(ele => ele?.toString().toLowerCase().indexOf(value) > -1) : false;
                    }
                    else if (nodeName === 'status') {
                        return item[nodeName] != null ? item[nodeName].toString().toLowerCase() == value : false;
                    }
                    else {
                        return item[nodeName] != null ? item[nodeName].toString().toLowerCase().indexOf(value) > -1 : false;
                    }
                })
                finalResult = filteredList.slice()
            });
        }
        else {
            finalResult = data;
        }
        return finalResult;
    }

    render() {
        return (
            <div>
                {this.props?.userContext?.user_profile?.is_user_provisioner === 1 ?
                    <div className="User-Management">
                        <div className="headerDiv">
                            <div className="header">
                                <div><h5><b>User Management</b></h5></div>
                                <div className="ml2">
                                    <BasicButton
                                        className="button-bg"
                                        type="inline"
                                        variant="outlined"
                                        text={"New User"}
                                        onClick={this.handleOpenUser}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                            <div>
                                <BasicButton
                                    className="userReport"
                                    text={"Users Report"}
                                    type="inline"
                                    variant="contained"
                                    onClick={this.hanldeUsersReport} />
                            </div>
                        </div>
                        <br />
                        <div className="filtersContainer">
                            <MDBRow>
                                <MDBCol md={11} className={"tags"}>
                                    {this.state.filterInputs.length > 0 && this.state.filterInputs?.map((item, index) => {
                                        <Chip
                                            label={item}
                                            onDelete={this.handleFilterDelete.bind(
                                                this,
                                                item,
                                                index
                                            )}
                                        />
                                    })}
                                </MDBCol>
                                <MDBCol md={1} className="clearFilters">
                                    {this.state.filterInputs.length > 0 ?
                                        <BasicButton
                                            variant="outlined"
                                            text="Clear Filters"
                                            onClick={this.clearFilter}
                                        /> : null}
                                </MDBCol>
                            </MDBRow>
                        </div>
                        <div className="filterFieldsContainer">
                            {/* {this.state.filterList.map(item => )} */}
                            <MDBRow>
                                <MDBCol md={3}>
                                    <BasicTextField
                                        id="Name"
                                        placeholder="Name"
                                        // onKeyUp={(event) => {
                                        //     if (event.keyCode === 13) {
                                        //         this.addFilterChangeHandler("Name", event.target.value);
                                        //     }
                                        // }}
                                        value={this.state.filterList?.Name}
                                        onChange={(e) => {
                                            this.onChangeHandler("Name", e.target.value)
                                            this.addFilterChangeHandler("Name", event.target.value);
                                        }}
                                    />
                                </MDBCol>
                                <MDBCol md={3}>
                                    <BasicTextField
                                        id="displayName"
                                        placeholder="Display Name"
                                        // onKeyUp={(event) => {
                                        //     if (event.keyCode === 13) {
                                        //         this.addFilterChangeHandler("Display Name", event.target.value);
                                        //     }
                                        // }}
                                        value={this.state.filterList?.displayName}
                                        onChange={(e) => {
                                            this.onChangeHandler("displayName", e.target.value)
                                            this.addFilterChangeHandler("Display Name", event.target.value);
                                        }}
                                    />
                                </MDBCol>
                                <MDBCol md={3}>
                                    <BasicTextField
                                        id="title"
                                        placeholder="Title"
                                        // onKeyUp={(event) => {
                                        //     if (event.keyCode === 13) {
                                        //         this.addFilterChangeHandler("Title", event.target.value);
                                        //     }
                                        // }}
                                        value={this.state.filterList?.title}
                                        onChange={(e) => {
                                            this.onChangeHandler("title", e.target.value)
                                            this.addFilterChangeHandler("Title", event.target.value);
                                        }}
                                    />
                                </MDBCol>
                                <MDBCol md={3}>
                                    <BasicTextField
                                        id="company"
                                        placeholder="Company"
                                        // onKeyUp={(event) => {
                                        //     if (event.keyCode === 13) {
                                        //         this.addFilterChangeHandler("Company", event.target.value);
                                        //     }
                                        // }}
                                        value={this.state.filterList?.company}
                                        onChange={(e) => {
                                            this.onChangeHandler("company", e.target.value)
                                            this.addFilterChangeHandler("Company", event.target.value);
                                        }}
                                    />
                                </MDBCol>

                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={3}>
                                    <SearchSelectField
                                        id="supervisor"
                                        detail_selected={this.state.filterList?.supervisor_id ? { value: this.state.filterList?.supervisor_id, text: this.state.userOptions.find(item => item.value === this.state.filterList?.supervisor_id)?.text } : null}
                                        placeholder="Supervisor"
                                        searchSelect={true}
                                        multiple={false}
                                        options={this.state.userOptions}
                                        valueSelected={(e, newValue) => {
                                            let selectedUser = this.state.userOptions?.find(item => item.value === (newValue != null ? newValue.value : null));
                                            this.onChangeHandler('supervisor_id', selectedUser.value)
                                            this.addFilterChangeHandler("Supervisor", newValue != null ? selectedUser?.text : null)
                                        }}
                                        searchText={ev => {
                                            // dont fire API if the user delete or not entered anything
                                            if (ev.target.value !== "" || ev.target.value !== null) {
                                                this.getEntity("userOptions", "USER", ev.target.value);
                                            }
                                        }}
                                    />
                                </MDBCol>
                                <MDBCol md={3}>
                                    <SearchSelectField
                                        id="assistant"
                                        detail_selected={this.state.filterList?.assistant ? { value: this.state.filterList?.assistant, text: this.state.userOptions.find(item => item.value === this.state.filterList?.assistant)?.text } : null}
                                        placeholder="Assistant"
                                        searchSelect={true}
                                        multiple={false}
                                        options={this.state.userOptions}
                                        valueSelected={(e, newValue) => {
                                            let selectedUser = this.state.userOptions?.find(item => item.value === (newValue != null ? newValue.value : null));
                                            this.onChangeHandler('assistant', selectedUser.value)
                                            this.addFilterChangeHandler("Assistant", newValue != null ? selectedUser?.text : null)
                                        }}
                                        searchText={ev => {
                                            // dont fire API if the user delete or not entered anything
                                            if (ev.target.value !== "" || ev.target.value !== null) {
                                                this.getEntity("userOptions", "USER", ev.target.value);
                                            }
                                        }}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <SelectField
                                        id="status"
                                        value={this.state.filterList.status}
                                        placeHolderText="Status"
                                        options={selectOptions}
                                        onChange={(e) => {
                                            let selectedStatus = selectOptions?.find(item => item.value === e.target.value);
                                            this.onChangeHandler("status", e.target.value)
                                            this.addFilterChangeHandler("Status", selectedStatus.label);
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={3}>
                                    <SelectField
                                        id="division"
                                        value={this.state.filterList?.division}
                                        placeHolderText="Division"
                                        options={this.state.divisionOptions}
                                        onChange={(e) => {
                                            let selectedDiv = this.state.divisionOptions?.find(item => item.value === e.target.value);
                                            this.onChangeHandler("division", e.target.value)
                                            this.addFilterChangeHandler("Division", selectedDiv.label);
                                        }}
                                    />
                                </MDBCol>
                                <MDBCol md={3}>
                                    <SelectField
                                        id="role"
                                        value={this.state.filterList?.role}
                                        placeHolderText="Role"
                                        options={this.state.roleOptions}
                                        onChange={(e) => {
                                            let selectedRole = this.state.roleOptions?.find(item => item.value === e.target.value);
                                            this.onChangeHandler("role", e.target.value)
                                            this.addFilterChangeHandler("Role", selectedRole.label);
                                        }}
                                    />
                                </MDBCol>
                                {/* <MDBCol md={3}>
                                    <SelectField
                                        id="userlevel"
                                        value={this.state.filterList.user_level}
                                        placeHolderText="User Level"
                                        options={this.state.levelOptions}
                                        onChange={(e) => {
                                            let selectedLevel = this.state.levelOptions?.find(item => item.value === e.target.value);
                                            this.onChangeHandler("user_level", e.target.value)
                                            this.addFilterChangeHandler("Level", selectedLevel.label);
                                        }}
                                    />
                                </MDBCol> */}
                                <MDBCol md={3} className="margin-top">
                                    <SearchSelectField
                                        id="showName"
                                        placeholder="(Has Access to) Show Name"
                                        detail_selected={this.state.filterList?.shows ? { value: this.state.showOptions.find(item => item.value === this.state.filterList?.shows)?.value, text: this.state.filterList?.shows } : null}
                                        searchSelect={true}
                                        multiple={false}
                                        options={this.state.showOptions}
                                        valueSelected={(e, newValue) => {
                                            let selectShow = this.state.showOptions?.find(item => item.value === (newValue != null ? newValue.value : null));
                                            this.onChangeHandler('shows', selectShow?.text)
                                            this.addFilterChangeHandler("Show", newValue != null ? selectShow?.text : null)
                                        }}
                                        searchText={ev => {
                                            // dont fire API if the user delete or not entered anything
                                            if (ev.target.value !== "" || ev.target.value !== null) {
                                                this.getEntity("showOptions", "SHOW_DIVISION", ev.target.value);
                                            }
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </div>
                        <br />
                        <div>
                            {this.state.usersReport ?
                                <UsersReport
                                    open={this.state.usersReport}
                                    usersData={this.state.currentList}
                                    handleClose={(e) => this.setState({ usersReport: false })}
                                    filterInputs={this.state.filterInputs || []}
                                />
                                : null}
                        </div>
                        <div>
                            {this.state.ismodalOpen ?
                                <div className="userModal">
                                    <CreateOrEditUserModal
                                        open={this.state.ismodalOpen}
                                        title={this.state.titleUser}
                                        primaryButtonText={this.state.id > 0 ? "Save" : "Create"}
                                        secondaryButtonText={"Close"}
                                        AddButtonText={"Add"}
                                        onConfirm={() => alert("Button Clicked")}
                                        handleClose={(e) => this.setState({ ismodalOpen: false })}
                                        id={this.state.id ? this.state.id : ""}
                                        handleRefresh={this.getUserDetails}
                                        viewFunction={this.handleOpenUser}
                                        emailList={this.state.emailList}
                                    />
                                </div>
                                : null}
                        </div>
                        <div>
                            <div className="usersTable">
                                <TableComponent
                                    list={this.state.list1 ? this.state.list1 : ""}
                                    config={configObjectAllUsers}
                                    sortCallback={this.handleTableColumnClick}
                                    editCallback={this.handleOpenUser}
                                    tabValue={this.state.tabValue}
                                    hyperLinkNavigationCallback={(item, node) => this.handleUserSelection(item, node)}
                                    isLoading={this.state.isFetchingUserDetails || false}
                                />
                                <TablePagination
                                    component="div"
                                    count={this.state.currentList ? this.state.currentList?.length : 0}
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    page={this.state.page}
                                    onChangePage={this.handleChangePage}
                                    rowsPerPage={this.state.rowsPerPage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </div>
                            <br />
                        </div >
                    </div >
                     :
                    <MDBRow>You do not have permissions to manage users</MDBRow>
                } 
            </div>
        )
    }
}
)