import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import DateField from "../../../components/SharedComponents/DateField/DateField";
import TableComponent from "../../SharedComponents/Table";
import { withUserContext } from "../../../contexts/UserContext";
import {LicenseTrackingHeading} from "./config";
import "./LicenseTracking.scss";
import moment from "moment";
import * as Constants from "../../../constants/constants";
import ClearTrackService from "../../../services/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getFormattedDate } from "../../../Common/Helper";
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import { updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';
// import ExcelAndDocModal from "../../EpisodeViewModals/ExcelAndDocModal/ExcelAndDocModal";
import {EpisodeAndClearance} from "../../EpisodeAndClearence/EpisodeAndClearenceDetails/EpisodeAndClearence";

export default withUserContext(
  class LicenseTracking extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        sortBy: null,
        isSortByAsc: true,
        sortCount: 0,
        licenseTrackingDetails: {},
        config: null,
        isFetchingLicenseTrakingDetails: false,
        isPosting: false,
        licenseTrackingRecords: {},
        currentList: [],
        actionsList: [],
        usersList: [],
        currentUser:null,
        page: 0,
        rowsPerPage: 10,
        iserror: false,
        showFileSizeError : false,
        fromCurrentDate: null,
        isFileOperation:null,
        showExcelAndDocModal: false,
        isOpenExisting: false,
        licensorsInfo: [],
        send_email : true,
        is_draft : true,
        has_attachment : true,
        showSeasonId: this.props?.showSeasonId,
        showSeasonEpisodeId: this.props?.show_season_episode_id,
        showId: this.props?.showId,
        licensorsInfo: this.props?.licensorsInfo,
        songId : this.props.song_id,
        licensor_id: this.props.licensor_id
        // licensorid :this.prop?.licensorsInfo[0],licensor_id,
        // songid : this.props?.licensorsInfo[0]?.song_id
      };
    }
    componentDidMount = () => {
      let updateConfig = JSON.parse(JSON.stringify({ ...LicenseTrackingHeading }))
      if(this.props.isMainTitle) {
        updateConfig.primaryKey = 'main_title_license_tracking_id'
      }
      this.setState({config : updateConfig})
      this.getLicensorActinsList();
      this.getLicensorTrakingRecord(null,true,true)
      };
    

    isOpenExisting=(data)=>{
      this.setState({isOpenExisting: data})
    }
    // handleShowExcelAndDocModal = () => {
    //   this.setState({ showExcelAndDocModal: true ,isOpenExisting: false ,send_email:true,is_draft:true , has_attachment:false });
    // };
    handleHideExcelAndDocModal = () => {
      this.setState({ showExcelAndDocModal: false });
    };

    getLicensorTrakingRecord = (rowId = null , loader = true, callUserApi=false) => {
      this.setState({
        isFetchingLicenseTrakingDetails: loader,
        showFileSizeError : false,
      });
      let url = !this.props.isMainTitle ? `/licenseTracking?clearance_licensor_id=${this.props.clearanceLicensorId}`:
       `/licenseTracking?main_title_licensor_id=${this.props.clearanceLicensorId}`
      ClearTrackService.getData(
        Constants.ClearTrackServiceBaseUrl +
         url,
        this.props.userContext?.active_tenant?.tenant_id,
        this.props?.division_id
      ).then(
        (response) => {
          let licenseTrackingRecords = response.data[0];
          let primaryKey = this.props.isMainTitle ? 'main_title_license_tracking_id' : 'clearance_license_tracking_id';
          let trackingRenderList =
            licenseTrackingRecords?.license_tracking?.map((item, index) => {
              item.date = item?.date ? item?.date?.split('T')[0] : item?.date;
              let fileRecord = item.files;
              if (Object.keys(item.files).length > 0) {
                item.files = (
                  <div>
                    <div md={12} key={"fileRow"}>
                      <div className="files-display">
                        <div style={{ margin: "0" }} className="display-file">
                          {item.files?.Key ? (
                            <p
                              className="file-text"
                              onClick={() => this.downloadFile(fileRecord.url, item[primaryKey])}
                            >
                              {item.files.Key}{" "}
                            </p>
                          ) : (
                            <p className="file-text">{item} </p>
                          )}
                          <div className="fileIconContainer">
                            {item?.files.Key ? (
                              <BasicButton
                                icon="exchange-alt"
                                text=""
                                type="inline"
                                inputType="file"
                                multiple={true}
                                id={"uploadseaonfilereplace" + item[primaryKey]}
                                uploadFile={(file) => {
                                  if(file[0].size > 4194304) {
                                    this.setState({showFileSizeError : true})
                                  } else {
                                    this.replaceFile(fileRecord,file,item[primaryKey])
                                  }
                                }}
                              />
                            ) : null}
                            <span style={{ cursor: "pointer" }}>
                              <MDBIcon
                                icon={"trash"}
                                id={item[primaryKey] + 'delete'}
                                onClick={() =>
                                  this.deleteAPICall(
                                    fileRecord,
                                    item[primaryKey]
                                  )
                                }
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                // delete item.files
                item.files = (
                  <div>
                    <BasicButton
                      variant="outlined"
                      icon="upload"
                      type="inline"
                      inputType="file"
                      id={"uploadFile " + item[primaryKey]}
                      uploadFile={(file) => {
                        if(file[0].size > 4194304) {
                          this.setState({showFileSizeError : true})
                        } else {
                          this.uploadFile( file, item[primaryKey])
                        }
                      }}
                    />
                  </div>
                );
              }
              return item;
            });
          licenseTrackingRecords.closed_date = licenseTrackingRecords?.closed_date ? licenseTrackingRecords?.closed_date.split('T')[0] : null;
          if(rowId) {
            let oldLicenseTrackingData = [...this.state.licenseTrackingDetails?.license_tracking]
            let updatedItemIndex = oldLicenseTrackingData.findIndex(ele => ele[primaryKey] === rowId)  
            oldLicenseTrackingData[updatedItemIndex].files = licenseTrackingRecords.license_tracking?.find(ele => ele[primaryKey] === rowId).files
            licenseTrackingRecords.license_tracking = oldLicenseTrackingData
          }
          let config = {...this.state.config}
          if(licenseTrackingRecords?.is_closed === 1) {
            config.actions = []
          } else {
            config.actions = ["pen", "times"]
          }
          this.setState({
            config:config,
            licenseTrackingDetails: licenseTrackingRecords ,
            isFetchingLicenseTrakingDetails: false,
            isFileOperation:null,
          },()=>{
            if(callUserApi) {
              this.getEntity();
            }
            let followUpActions = this.state.licenseTrackingDetails?.license_tracking?.filter((item)=>item.action_name === "Followup")
            if(followUpActions.length > 0) {
              let currentDate = new Date();
              let followUpDate = new Date(followUpActions[followUpActions.length - 1].date)
              let diffTime = currentDate?.getTime() - followUpDate?.getTime();
              let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              this.setState({fromCurrentDate:diffDays})
            }
          });
        },
        (err) => {
          console.log("Error in fetching LicenseTrackingDetails:", err);
          this.setState({
            isFetchingLicenseTrakingDetails: false,
          });
        }
      );
    };

    downloadFile= (url, id) => {
      if (url) {
          this.setState({isFileOperation:id})
          fetch(url).then(response => response.blob()).then(blob => {
              const link = document.createElement('a');
              link.href = url
              document.body.appendChild(link);
              link.click();
              link.remove();

          })
          this.setState({isFileOperation:null})
      }
  }

    replaceFile(oldFileItem, file, clearance_license_tracking_id) {
        this.setState({showFileSizeError:false})
        let formData = new FormData();
        formData.append("files", file[0]);
        formData.append("showName", this.state.licenseTrackingDetails?.show_name);
        formData.append("season", this.state.licenseTrackingDetails?.season_name);
        formData.append("tenantName", this.props.userContext?.active_tenant?.tenant_name);
        if(this.props?.isMainTitle) {
          formData.append("module", "maintitlelicensetracking");
          formData.append("maintitleLicenseTrackingId",clearance_license_tracking_id);
        } else {
          formData.append("module", "clearancelicensetracking");
          //formData.append("episodeNumber",this.state.licenseTrackingDetails?.episode_number);
          formData.append("episodeName",this.state.licenseTrackingDetails?.episode_title);
          formData.append("clearanceLicenseTrackingId",clearance_license_tracking_id);
        }
        formData.append("isBoxUpload", 1);
        formData.append("replaceFileName", oldFileItem.Key);
        formData.append("isReplaceFile", 1);
        formData.append("divisionId", this.props.division_id);
        formData.append("divisionName", this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name);
        this.uploadFileAPI(formData, clearance_license_tracking_id)
    }

    uploadFile(file, clearance_license_tracking_id) {
        this.setState({showFileSizeError:false})
        let formData = new FormData();
        formData.append("files", file[0]);
        formData.append("showName", this.state.licenseTrackingDetails?.show_name);
        formData.append("season", this.state.licenseTrackingDetails?.season_name);
        formData.append("tenantName", this.props.userContext?.active_tenant?.tenant_name);
        if(this.props?.isMainTitle) {
          formData.append("module", "maintitlelicensetracking");
          formData.append("maintitleLicenseTrackingId",clearance_license_tracking_id);
        } else {
          formData.append("module", "clearancelicensetracking");
          //formData.append("episodeNumber",this.state.licenseTrackingDetails?.episode_number);
          formData.append("episodeName",this.state.licenseTrackingDetails?.episode_title);
          formData.append("clearanceLicenseTrackingId",clearance_license_tracking_id);
        }
        formData.append("isBoxUpload", 1);
        formData.append("divisionId", this.props.division_id);
        formData.append("divisionName", this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name);
        this.uploadFileAPI(formData, clearance_license_tracking_id);
    }

    deleteAPICall = (file, clearance_license_tracking_id) => {
      this.setState({showFileSizeError:false})
      let queryParamObj = {
        showName: this.state.licenseTrackingDetails?.show_name,
        season: this.state.licenseTrackingDetails?.season_name,
        tenantName: this.props.userContext?.active_tenant?.tenant_name,
        isBoxUpload: 1,
        fileName:file.Key
      };
      if(this.props?.isMainTitle) {
        queryParamObj.module = "maintitlelicensetracking"
        queryParamObj.maintitleLicenseTrackingId = clearance_license_tracking_id;
      } else {
        queryParamObj.module = "clearancelicensetracking";
        //queryParamObj.episodeNumber = this.state.licenseTrackingDetails?.episode_number;
        queryParamObj.episodeName = this.state.licenseTrackingDetails?.episode_title;
        queryParamObj.clearanceLicenseTrackingId = clearance_license_tracking_id;
      }
      queryParamObj.divisionId = this.props.division_id,
      queryParamObj.divisionName = this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
      this.setState({isFileOperation : clearance_license_tracking_id })
      ClearTrackService.deleteDataParams(
        Constants.ClearTrackServiceBaseUrl + "/deleteFiles",
        queryParamObj,
        this.props.userContext?.active_tenant?.tenant_id,
        this.props.division_id
      ).then(
        (response) => {
          if (response.data.error) {
            this.setState({
              openNotification: true,
              notification: "File deletion failed",
            });
          } else {
            this.getLicensorTrakingRecord(clearance_license_tracking_id,false)
          }
        },
        (err) => {
          this.setState({
            openNotification: true,
            notification: "File deletion failed"
          });
          console.log("Error in fetching data", err);
        }
      );
    };


    getEntity = () => {
      let allUsers = []
      ClearTrackService.getData(
        Constants.ClearTrackServiceBaseUrl +
        '/userAll',this.props.userContext?.active_tenant?.tenant_id,null
      ).then((response) => {
        let validRoles = ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Regular User)', 'Music Clearance (Basic)', 'Licensing Admin']
        let validUsers = []
        allUsers = [...response?.data]
        for(let i = 0; i< response?.data?.length; i++) {
          for(let j = 0; j < response?.data[i]?.tenants?.length ;  j++) {
            if(response?.data[i]?.tenants[j]?.tenant_id === this.props.userContext?.active_tenant?.tenant_id) {
              for(let k = 0; k < response?.data[i]?.tenants[j]?.division_role?.length ;  k++) { 
                if(response?.data[i]?.tenants[j]?.division_role[k]?.division_id === this.props.division_id) {
                   if(validRoles?.includes(response?.data[i]?.tenants[j]?.division_role[k]?.role_name)) {
                      validUsers.push({
                        value:  response?.data[i].user_id,
                        label:  response?.data[i].full_name,
                        is_active: response?.data[i].is_active,
                      })
                   }
                }
              }
            }
          }
        }
        let licenseTracking = [...this.state.licenseTrackingDetails?.license_tracking]
        for(let l=0 ; l < licenseTracking?.length ; l++) {
          if(!validUsers?.find(item => item?.value === licenseTracking[l]?.submitted_by)) {
            validUsers.push({
              value:  licenseTracking[l].submitted_by,
              label:  licenseTracking[l].submitted_by_name,
              is_active: allUsers?.find(item => item?.user_id === licenseTracking[l].submitted_by )?.is_active,
            })
          }
        }
        let licenseTrakingConfig = { ...this.state.config };
        licenseTrakingConfig.headings[4].selectOptions = validUsers ;
        let currentUser = validUsers?.find(item=>item?.value == this.props?.userContext?.user_profile?.user_id)
        if(currentUser){
          this.setState({currentUser:{submitted_by:currentUser?.value, date :  moment(new Date())?.format("YYYY-MM-DD")}})
        }
        this.setState({ usersList: validUsers  });
      });
    };

    getLicensorActinsList = () => {
      ClearTrackService.getData(
        Constants.ClearTrackServiceBaseUrl +
          `/entitySearch?entity=LICENSE_TRACKING_ACTIONS&searchString=null`,
        this.props.userContext?.active_tenant?.tenant_id,
      ).then(
        (response) => {
          let licenseTrakingConfig = { ...this.state.config };
          let actionsList = response?.data?.map((item) => ({
            label: item?.license_tracking_action,
            value: item.license_tracking_action_id,
            is_active: item?.is_active,
            waiting_days : item?.waiting_days
          }));
          licenseTrakingConfig.headings[1].selectOptions = actionsList;
          this.setState({ actionsList: actionsList });
        },
        (err) => {
          console.log("Error in fetching LicenseTrackingDetails:", err);
          this.setState({ isFetchingLicenseTrakingDetails: false });
        }
      );
    };

    handleInlineDelete = (id, dataItem, index) => {
      let updateLicenseTrackingDetails = {...this.state.licenseTrackingDetails};
      let primaryKey = this.props.isMainTitle ? 'main_title_license_tracking_id' : 'clearance_license_tracking_id';
      for(let i=0; i < updateLicenseTrackingDetails.license_tracking.length; i++) {
        if(updateLicenseTrackingDetails.license_tracking[i][primaryKey] === dataItem[primaryKey]) {
          updateLicenseTrackingDetails.license_tracking[i].is_delete = 1
        }
      }
      this.setState({licenseTrackingDetails : updateLicenseTrackingDetails, showFileSizeError:false})
    };

    handleReadCallback = (index) => {
      let updateLicenseTrackingDetails = {...this.state.licenseTrackingDetails};
      for(let i=0; i < updateLicenseTrackingDetails.license_tracking.length; i++) {
          updateLicenseTrackingDetails.license_tracking[i].editing = false
      }
      this.setState({licenseTrackingDetails : updateLicenseTrackingDetails, iserror:false, showFileSizeError:false})
    };

    handleInlineEdits = (id, dataItem, index) => {
      let updateLicenseTrackingDetails = {...this.state.licenseTrackingDetails};
      let primaryKey = this.props.isMainTitle ? 'main_title_license_tracking_id' : 'clearance_license_tracking_id';
      for(let i=0; i < updateLicenseTrackingDetails.license_tracking.length; i++) {
        if(updateLicenseTrackingDetails.license_tracking[i][primaryKey] === dataItem[primaryKey]) {
          updateLicenseTrackingDetails.license_tracking[i].editing = true
        } else {
          updateLicenseTrackingDetails.license_tracking[i].editing = false
        }
      }
      this.setState({licenseTrackingDetails : updateLicenseTrackingDetails, iserror:false, showFileSizeError:false})
    };

    saveInlineEdits = (list, index, id, dataItem) => {
      this.setState({iserror:false, showFileSizeError:false}, () => {
        if(list[index]?.action) {
          let primaryKey = this.props.isMainTitle ? 'main_title_license_tracking_id' : 'clearance_license_tracking_id';
          let updateLicenseTrackingDetails = {...this.state.licenseTrackingDetails}
          let updateIindex = updateLicenseTrackingDetails.license_tracking.findIndex(item => item[primaryKey] === list[index][primaryKey])
          updateLicenseTrackingDetails.license_tracking[updateIindex].description = list[index]?.description ? list[index]?.description : this.state.actionsList?.find(ele => ele.value ===list[index].action)?.label
          updateLicenseTrackingDetails.license_tracking[updateIindex].action = list[index].action
          updateLicenseTrackingDetails.license_tracking[updateIindex].date = list[index].date
          updateLicenseTrackingDetails.license_tracking[updateIindex].comment = list[index].comment
          updateLicenseTrackingDetails.license_tracking[updateIindex].submitted_by = list[index].submitted_by
          updateLicenseTrackingDetails.license_tracking[updateIindex].editing = false
          this.setState({licenseTrackingDetails: updateLicenseTrackingDetails}, () => {
            if((this.state.actionsList?.find(ele => ele.value === list[index]?.action)?.label.toLowerCase())?.includes('fully executed')) {
                this.handleChange("is_closed", true)
            }
            if(index === list?.length - 1) {
              let waitingDays = this.state.actionsList?.find(item => item?.value ===  list[index].action )?.waiting_days
              let dueDate = waitingDays ? getFormattedDate(new Date(new Date(list[index].date)?.getTime() + waitingDays*24*60*60*1000), true) : null
              this.handleChange('license_due_date',  dueDate )
            }
          })
        }
        else {
          this.setState({iserror:true})
        }
      })
    };

    handleAddNewItem = (newDataItem) => {
      this.setState({iserror:false, showFileSizeError:false}, () => {
        if(newDataItem?.action) {
          
          let newItem = {
            description: newDataItem?.description ? newDataItem?.description :  this.state.actionsList?.find(ele => ele.value === newDataItem?.action)?.label,
            action: newDataItem.action || null,
            date: newDataItem.date || null,
            comment: newDataItem.comment || null,
            submitted_by: newDataItem.submitted_by || null,
            is_delete: 0,
          }
          if (!this.props.isMainTitle) {
            newItem.clearance_license_tracking_id = 'new'+Date.now();
          } else {
            newItem.main_title_license_tracking_id = 'new'+Date.now();
          }
          let updateLicenseTrackingDetails = {...this.state.licenseTrackingDetails}
          updateLicenseTrackingDetails?.license_tracking?.push(newItem)
          this.setState({licenseTrackingDetails : updateLicenseTrackingDetails}, () => {
            if((this.state.actionsList?.find(ele => ele.value === newDataItem?.action)?.label.toLowerCase())?.includes('fully executed')) {
              this.handleChange("is_closed", true)
            }
            let waitingDays = this.state.actionsList?.find(item => item?.value ===  newDataItem?.action )?.waiting_days
            let dueDate = waitingDays ? getFormattedDate(new Date(new Date(newDataItem?.date)?.getTime() + waitingDays*24*60*60*1000), true) : null
            this.handleChange('license_due_date',  dueDate )
          })
        }
        else {
          this.setState({iserror:true})
        }
      })
    };

    uploadFileAPI = (uploadFormData, id) => {
      this.setState({showFileSizeError:false, isFileOperation:id})
      let extensionUrl = "/uploadFile";
      ClearTrackService.uploadFileFormData(
        Constants.ClearTrackServiceBaseUrl + extensionUrl,
        uploadFormData,
        this.props.userContext?.active_tenant?.tenant_id,
        this.props.division_id
      ).then(
        (response) => {
          if (response.data.error) {
            this.props?.notificationComponent(true, "fail");
          } else {
            this.getLicensorTrakingRecord(id,false)
          }
        },
        (err) => {
          this.props?.notificationComponent(true, "fail");
          console.log("Post agency details error: " + err);
        }
      );
    };

    
    handleSubmit = () => {
      this.setState({ isPosting: true, iserror:false });
      let primaryKey = this.props.isMainTitle ? 'main_title_license_tracking_id' : 'clearance_license_tracking_id';
      let licenseTrackingDetails = JSON.parse(
        JSON.stringify({ ...this.state.licenseTrackingDetails })
      );
      let licenseList = licenseTrackingDetails.license_tracking?.filter(item => !(item.is_delete === 1 && isNaN(item[primaryKey])))
      licenseList?.map((item) => {
        // if((this.state.actionsList?.find(ele => ele.value === item?.action)?.label.toLowerCase())?.includes('external lic rcvd')) {
        //   licenseTrackingDetails.closed_date = licenseTrackingDetails?.closed_date ? new Date(licenseTrackingDetails.closed_date) : new Date()
        //   licenseTrackingDetails.is_closed = 1
        // }
        item["action_id"] = item?.action
        item["action_date"] = item?.date ? new Date(item?.date) : null;
        item[primaryKey] = isNaN(item[primaryKey])  ?  null : item[primaryKey]
        delete item?.action;
        delete item?.action_name;
        delete item?.date;
        return item;
      });
      licenseTrackingDetails.license_tracking = licenseList
      licenseTrackingDetails.closed_date = licenseTrackingDetails?.closed_date ? new Date(licenseTrackingDetails.closed_date) :null
      if(this.props?.isMainTitle) {
        licenseTrackingDetails.main_title_id = this.props?.clearenceId
      } else {
        licenseTrackingDetails.clearance_id =  this.props?.clearenceId
      }
      if(licenseTrackingDetails?.license_tracking?.length > 0 ? licenseTrackingDetails.license_tracking?.every(item => item?.action_id) : true) {
      ClearTrackService.postDataParams(
        Constants.ClearTrackServiceBaseUrl + `/licenseTracking`,
        licenseTrackingDetails,
        this.props.userContext?.active_tenant?.tenant_id,
        this.props?.division_id
      ).then((response) => {
        if (response) {
          if (response.data.error) {
            this.setState({
              isPosting: false,
            });
            this.props?.notificationComponent(true, "fail");
          } else {
            this.setState({
              isPosting: false,
              isFetchingLicenseTrakingDetails: false,
            });
            // this.uploadFileAPI(response.data[0].clearance_licensor_id)
            this.props?.notificationComponent(true, "success");
            this.props?.toggleRefreshPage();
          }
        }
      });
    } else {
      this.setState({iserror:true, isPosting:false})
    }
    };

    handleChange = (field, value) => {
      if (field === "is_closed") {
        let config = {...this.state.config}
        config.actions = value ?  [] : ["pen", "times"]
        this.setState((prevState) => ({
          config:config,
          licenseTrackingDetails: {
            ...prevState.licenseTrackingDetails,
            [field]: value ? 1 : 0,
            ['closed_date'] : value ? getFormattedDate(new Date(), true) : null,
            ['license_tracking'] : prevState.licenseTrackingDetails?.license_tracking?.map(rec => {
              let newRec  = {...rec} 
              newRec.editing = false
              return newRec
            })
          },
        }));
      } else if (value != "") {
        this.setState({
          licenseTrackingDetails: {
            ...this.state.licenseTrackingDetails,
            [field]: value,
          },
        });
      } else {
        this.setState({
          licenseTrackingDetails: {
            ...this.state.licenseTrackingDetails,
            [field]: null,
          },
          postInitiated: false,
        });
      }
    };


    downloadRelianceLetter = () => {
      
      //let searchpayloademailobject = {"send_email" : 1,"is_draft" :1,"has_attachment" :0};
      
      this.setState({isDownloadLetter : true})
      let searchPayload  = this.props?.isMainTitle ? {
        'main_title_licensor_id' :  this.props.clearanceLicensorId || null
      } : {  'clearance_licensor_id' : this.props.clearanceLicensorId || null
      }
      searchPayload["isOpenExisting"] = this.state.isOpenExisting ? 1 : 0
      searchPayload["letter_type"] = "RELIANCE_LETTER"

      searchPayload["show_season_id"] = this.state.showSeasonId
      searchPayload["show_season_episode_id"] = this.props.show_season_episode_id
      searchPayload["show_id"] = this.state.showId
      searchPayload["song_id"] = this.props?.clearneceLicenserInfo[0]?.licensors_list[0]?.song_id
      searchPayload["licensor_id"] = this.props?.clearneceLicenserInfo[0]?.licensors_list[0]?.licensor_id
      
      let searchPayloadPdf  = this.props?.isMainTitle ? {
        'main_title_licensor_id' :  this.props.clearanceLicensorId || null
      } : {  'clearance_licensor_id' : this.props.clearanceLicensorId || null
      }
      searchPayloadPdf["isOpenExisting"] = this.state.isOpenExisting ? 1 : 0
      searchPayloadPdf["send_email"] = this.state.send_email ? 1 :0
      searchPayloadPdf["is_draft"] = this.state.is_draft ? 1: 0
      searchPayloadPdf["has_attachment"] = this.state.has_attachment ? 1: 0
      searchPayloadPdf["letter_type"] = "RELIANCE_LETTER"

      searchPayloadPdf["show_season_id"] = this.state.showSeasonId
      searchPayloadPdf["show_season_episode_id"] = this.props.show_season_episode_id
      searchPayloadPdf["show_id"] = this.state.showId
      searchPayloadPdf["song_id"] = this.props?.clearneceLicenserInfo[0]?.licensors_list[0]?.song_id
      searchPayloadPdf["licensor_id"] = this.props?.clearneceLicenserInfo[0]?.licensors_list[0]?.licensor_id
      searchPayloadPdf["is_box_upload"] = 1
      searchPayloadPdf["file_type"] = "PDF"
    
    console.log(this.props)      

      let load = {
        'payload': 'exportDocx',
        'reportName': this.props?.isMainTitle ?  "rptmaintitlerelianceletter" : 'rptclearancerelianceletter',
        'fileName': this.props?.isMainTitle ? `${this.state.licenseTrackingDetails?.show_name}_Reliance_Letter` : `${this.state.licenseTrackingDetails?.show_name}_Reliance_Letter_${this.state.licenseTrackingDetails?.episode_number}`,
        'searchJson':  btoa(JSON.stringify(searchPayload)),
        'showHtml': 0,
        'encryptionType': 1,
        'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
        'divisionId': this.props?.division_id,
        
      }
      ClearTrackService.getDataWS(load).then(webSocketConn => {
        webSocketConn.onmessage = (e) => {
            let response = JSON.parse(e.data);
            if (response?.statusCode == 200) {
                if (response?.body) {
                    this.downloadUrl(response?.body?.url)
                  //this.props?.notificationComponent(true, 'success');
                  this.setState({isDownloadLetter : false})
                } else {
                    this.props?.notificationComponent(true, 'fail');
                    this.setState({isDownloadLetter : false})
                }
            } else {
                this.setState({isDownloadLetter : false})
                //this.props?.notificationComponent(true, 'fail');
                console.log("Test purpose");
            }
        }
    })

    let pdfLoad = {
      'payload': 'exportPdf',
      'reportName': this.props?.isMainTitle ?  "rptmaintitlerelianceletter" : 'rptclearancerelianceletter',
      'fileName': this.props?.isMainTitle ? `${this.state.licenseTrackingDetails?.show_name}_Reliance_Letter` : `${this.state.licenseTrackingDetails?.show_name}_Reliance_Letter_${this.state.licenseTrackingDetails?.episode_number}`,
      'searchJson':  btoa(JSON.stringify(searchPayloadPdf)),
      'showHtml': 0,
      'encryptionType': 1,
      'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
      'divisionId': this.props?.division_id,
      
    }
    ClearTrackService.getDataWS(pdfLoad).then(webSocketConn => {
      webSocketConn.onmessage = (e) => {
          let response = JSON.parse(e.data);
          if (response?.statusCode == 200) {
              if (response?.body) {
                this.setState({has_attachment : true})
              } else {
                  this.props?.notificationComponent(true, 'fail');
              }
          }
      }
    })
    }

    downloadUrl = (url) => {
      if (url) {
          fetch(url)
              .then((response) => response.blob())
              .then((blob) => {
                  const link = document.createElement("a");
                  link.href = url;
                  document.body.appendChild(link);
                  if(this.isOpenExisting){
                    this.getLicensorTrakingRecord(null,false,true);
                  }
                  this.handleHideExcelAndDocModal();
                  link.click();
                  link.remove();
              });
      }
  };

  handleNewRowAdd = (actionName) => {
    if(actionName !== 'External Lic Sent – Partially Executed') {
      this.handleChange("is_closed", 1)
    }
    let currentUser = this.state.usersList?.find(item=>item?.value == this.props?.userContext?.user_profile?.user_id)
    let newItem = {
      description: actionName,  
      action: this.state.actionsList?.find(ele => ele.label === actionName)?.value || null,
      date: moment(new Date())?.format("YYYY-MM-DD") || null,
      comment:  null,
      submitted_by: currentUser?.value || null,
      is_delete: 0,
    }
    this.handleAddNewItem(newItem)
  }

  // getSongInfo = (songList) => {
  //   if(this.props?.type === "SM" || this.props?.type === 3) {
  //     return <>{songList?.map((song,index, songsList) => index !== 0 ? <div>{`${song?.song_title} performer by ${song?.performer_name} ${song?.writer?.length > 0 ? ' written by' : ''} ${song?.writer?.map((writer) => writer?.writer_name+' ' 
  //      )}`}</div> :
  //      <span>{`${song?.song_title} performer by ${song?.performer_name} ${song?.writer?.length > 0 ? ' written by' : ''} ${song?.writer?.map((writer) => writer?.writer_name+' ' 
  //      )}`}</span>)}</>
  //   } else if(this.props?.type === "M" || this.props?.type === 2) {
  //     return <>{songList?.map((song,index, songsList) => index !== 0 ? <div>{`${song?.song_title} performer by ${song?.performer_name}`}</div> :
  //       <span>{`${song?.song_title} performer by ${song?.performer_name}`}</span>)}</>
  //   } else {
  //     return <>{songList?.map((song,index, songsList) => index !== 0 ? <div>{`${song?.song_title}  ${song?.writer?.length > 0 ? ' written by' : ''} ${song?.writer?.map((writer) => writer?.writer_name+' ' 
  //       )}`}</div> :
  //       <span>{`${song?.song_title} ${song?.writer?.length > 0 ? ' written by' : ''} ${song?.writer?.map((writer) => writer?.writer_name+' '  
  //       )}`}</span>)}</>
  //   }
  // }

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
}

handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
    }, () => {
      let licenseTrackingSort = JSON.parse(JSON.stringify({ ...this.state.licenseTrackingDetails}))
      licenseTrackingSort.license_tracking = tableSortList(colName === "Date" ? "Date" : "Name", this.getSortNode(colName), this.state.licenseTrackingDetails?.license_tracking, this.state.isSortByAsc)
      this.setState({
        sortBy: colName,
        config: updateTableSortConfigObject(this.state?.config, this.state.isSortByAsc, colName, colName),
        licenseTrackingDetails: licenseTrackingSort
      });
    })
}

getSortNode = (col) => {
    switch (col) {
        case "Description": return "description";
        case "Action": return "action_name";
        case "Date": return "date";
        case "Comment": return "comment";
        case "Submittedby" : return 'submitted_by_name';
    }
}

    render() {
      
      const isOpenExisting = this.state.isOpenExisting;
      let editLicenseTracking = this.props?.licensorTrackingPermission?.edit == 1 ? true : false
      let primaryKey = this.props.isMainTitle ? 'main_title_license_tracking_id' : 'clearance_license_tracking_id';
      let actionCheckForFollowUp = this.state.licenseTrackingDetails?.license_tracking?.map(item => {
        if(item[primaryKey]) {
          return this.state.actionsList?.find(ele => ele.value === item.action)?.label?.toLowerCase()
        } else {
          return null
        }
      })?.includes('lic rcvd - fully executed')
      return (

        

        <MDBContainer fluid className="License-container">
          {/* <MDBRow>
            {this.props.licensorsInfo.map((licensor) => (
              <span className="f7">
                Letters created for{" "}
                {this.props.clearneceLicenserInfo.map(
                  (item) => item.licensors_list[0].licensor_name
                )}{" "}
                concerning the song{licensor.songs_list?.length >  1  ? 's ' : ' '}
                {this.getSongInfo(licensor.songs_list)}
              </span>
            ))}
          </MDBRow> */}
          <div id="TableComponentLicense">
            {this.state.isFetchingLicenseTrakingDetails ? (
              <div className="ContentLoader">
                <CircularProgress />
              </div>
            ) : (
              <>
                <MDBRow>
                  <MDBCol md={2}>
                    <MDBRow>
                      <span className="mainLabel">Show</span>
                    </MDBRow>
                    <MDBRow>
                      <span className="labelValue">
                        {this.state.licenseTrackingDetails.show_name}
                      </span>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol md={2}>
                    <MDBRow>
                        {!this.props?.isMainTitle && <span className="mainLabel">Episode</span>}
                    </MDBRow>
                    <MDBRow>
                      {!this.props?.isMainTitle && <span className="labelValue">
                        {this.state.licenseTrackingDetails.episode_title}
                      </span>}
                    </MDBRow>
                  </MDBCol>
                  <MDBCol md={3}>
                    <MDBRow>
                      <span className="mainLabel">License Due Date</span>
                    </MDBRow>
                    <MDBRow>
                      <span className="labelValue">
                        {this.state.licenseTrackingDetails?.license_due_date ? this.state.licenseTrackingDetails?.license_due_date?.includes('T') ?
                        getFormattedDate(this.state.licenseTrackingDetails?.license_due_date?.split('T')[0]) : getFormattedDate(this.state.licenseTrackingDetails?.license_due_date) : null }
                      </span>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol md={2}>
                    <MDBRow>
                      <span className="mainLabel">License Rep</span>
                    </MDBRow>
                    <MDBRow>
                      <span className="labelValue">
                        {this.state.licenseTrackingDetails.licensee_rep_name}
                      </span>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol md={1}></MDBCol>
                  <MDBCol md={2}>
                    <MDBRow>
                      <BasicCheckbox
                        label="closed"
                        id={"is_completed"}
                        checked={this.state.licenseTrackingDetails?.is_closed}
                        onChange={(e) =>
                          this.handleChange("is_closed", e.target.checked)
                        }
                      />
                    </MDBRow>
                    <MDBRow>
                      <DateField
                        value={
                          this.state.licenseTrackingDetails?.closed_date || ""
                        }
                        onChange={(e) =>
                          this.handleChange("closed_date", e.target.value)
                        }
                        disabled={!this.state.licenseTrackingDetails?.is_closed}
                      />
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md={2} xl={3}>
                    <BasicButton
                      className="AddLettter"
                      variant="contained"
                      type="inline"
                      text={
                        this.state.isDownloadLetter ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          "Send Reliance Letter"
                        )
                      }
                      onClick={this.downloadRelianceLetter}
                      
                    />
                  </MDBCol>
                  {this.state.fromCurrentDate > 29 && this.state.licenseTrackingDetails?.is_closed === 0 && !actionCheckForFollowUp &&
                    <MDBCol>
                      <span className="lastFollow">
                        Last follow was {this.state.fromCurrentDate} days ago
                      </span>
                    </MDBCol>
                  }
                </MDBRow>
                {this.state.licenseTrackingDetails?.is_closed != 1 && <MDBRow className="mt-2">
                    <MDBCol md={4}>
                      <BasicButton
                        type="inline"
                        variant="outlined"
                        text={"Add Partial Exec"}
                        onClick={() => {this.handleNewRowAdd('External Lic Sent – Partially Executed')}}
                      />
                  </MDBCol>
                  <MDBCol md={4}>
                    <BasicButton
                      type="inline"
                      variant="outlined"
                      text={"Fully Exec. License Sent"}
                      onClick={() => {this.handleNewRowAdd('External Lic Sent - Fully Executed')}}
                    />
                  </MDBCol>
                  <MDBCol md={4}>
                    <BasicButton
                      type="inline"
                      variant="outlined"
                      text={"Fully Exec. License Rcvd"}
                      onClick={() => {this.handleNewRowAdd("Lic Rcvd - Fully Executed")}}
                    />
                  </MDBCol>
                </MDBRow>}
                <MDBRow>
                  <div id="TableComponentLicense">
                    {this.state.isFetchingLicenseTrakingDetails ? (
                      <div className="ContentLoader">
                        <CircularProgress />
                      </div>
                    ) : (
                      <>
                        {this.state.iserror ? (
                          <p className="f-color">
                            Please Enter Action field's value
                          </p>
                        ) : (
                          (this.state.isFetchingLicenseTrakingDetails = false)
                        )}
                        {this.state.config?.headings[1]?.selectOptions?.length >
                          0 &&
                          this.state.config?.headings[4]?.selectOptions
                            ?.length > 0 && (
                            <TableComponent
                              list={this.state.licenseTrackingDetails?.license_tracking?.filter(item => item?.is_delete != 1)}
                              config={this.state.config}
                              postInitiated={this.state.isFileOperation}
                              handleAddNewItem={this.handleAddNewItem.bind(
                                this
                              )}
                              defaultValuePopulation={this.state?.currentUser || null}
                              editCallback={this.handleInlineEdits.bind(this)}
                              saveCallback={this.saveInlineEdits.bind(this)}
                              handleDelCallBack={this.handleInlineDelete.bind(
                                this
                              )}
                              stickyHeader={true}
                              sortCallback={this.handleTableColumnClick}
                              addItemToList={this.state.licenseTrackingDetails?.is_closed != 1}
                              readCallback={this.handleReadCallback.bind(this)}
                            />
                          )}
                      </>
                    )}
                  </div>
                </MDBRow>
              </>
            )}
          </div>

          {/* <div><ExcelAndDocModal handleAddNewItem = {this.handleAddNewItem}></ExcelAndDocModal></div> */}

          <MDBRow className="mt-2">
            <MDBRow>
              <MDBCol md={10}></MDBCol>
              <MDBCol md={1}>
                <BasicButton
                  text={
                    this.state.isPosting ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      "Save"
                    )
                  }
                  disabled={ !editLicenseTracking ? true : this.state.isFileOperation}
                  onClick={this.handleSubmit}
                />
              </MDBCol>
              <MDBCol md={1}>
                <BasicButton
                  type="inline"
                  variant="outlined"
                  text={"Cancel"}
                  onClick={this.props?.handleClose}
                  disabled={false}
                />
              </MDBCol>
            </MDBRow>
          </MDBRow>
          {this.state.showExcelAndDocModal && (
            <>
              <MainViewModalComponent
                open={this.state.showExcelAndDocModal}
                handleClose={this.handleHideExcelAndDocModal}
                headerText={"Generate Excel/DOC"}
                mode={" "}
                modalName={"Excel And Doc Modal"}
                downloadExcel={this.downloadRelianceLetter}
                isOpenExisting = {this.isOpenExisting.bind(this)}
                
                isExcelPosting={this.state.isDownloadLetter}

              />
            </>
          )}
        </MDBContainer>
      );
    }
  }
);
