import React from 'react';
import { withUserContext } from '../../../contexts/UserContext';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import { detailsIconList, valueList } from './Config';
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';
import ClearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { clearanceRecordPermissionsConfig } from './Config';
import { MDBTooltip } from 'mdb-react-ui-kit';
import './Details.scss';

export default withUserContext(class Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetchingDetails: false,
            config: detailsIconList,
            iconConfig: valueList,
            clearanceRecordPermissionsConfig: clearanceRecordPermissionsConfig,
            showClearanceRecordModal: false,
            showEpisodeViewClearanceRecord: false,
            licensorsInfo: this.props.licensorsInfo,
            clearance_divisionId: null,
            clearenceId:null,
            isActiveMusicSupervisor: false,
            division_id:null
        }
    }

    componentDidMount() {
        this.getEntity('musicOptions', 'MUSIC_USES', '');
            this.setState({ division_id: this.props?.clearance_divisionId })
        if (this.props?.userContext?.active_tenant?.tenant_id && this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Supervisor' && item?.division_id === this.props?.clearance_divisionId)){
        this.setState({ isActiveMusicSupervisor: true });  
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if ((prevProps?.userContext?.active_tenant?.tenant_id !== this.props?.userContext?.active_tenant?.tenant_id)
                && this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Supervisor' && item?.division_id === this.props?.clearance_divisionId)){
                    this.setState({ isActiveMusicSupervisor: true });          
                }
        if (this.props.licensorsInfo !== prevProps.licensorsInfo) {
            this.setState({ licensorsInfo: this.props.licensorsInfo });
        }      
    }

    getEntity = (entity, entitytype, searchString) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = [];
                formattedList = response?.data?.map(item => ({ value: item.music_uses_id, label: item.abbreviation }));
                this.setState({ [entity]: formattedList});
            },
                (err) => {
                    console.log("Error in fetching entities:", err)
                })
    }

    handleshowClearanceRecordModal = () => {
        this.setState({ showClearanceRecordModal: true });
    }
    handleHideClearanceRecordModal = () => {
        this.setState({ showClearanceRecordModal: false });
    }
    handleShowEpisodeViewClearanceRecord = () => {
        let clearance_id = !this.props.isMainTitle ? this.state.licensorsInfo[0]?.clearance_id : this.state.licensorsInfo?.[0]?.songs_list?.[0]?.main_title_song_id
        this.setState({ showEpisodeViewClearanceRecord: true,clearenceId:clearance_id,clearance_divisionId:this.state.licensorsInfo[0]?.division_id });
    }
    handleHideEpisodeViewClearanceRecord = () => {
        this.setState({ showEpisodeViewClearanceRecord: false });
    }

    render() {
        let songAndPerformerPermissions = this.props?.isMainTitle ? this.props.mainTitlePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "song/performerInfo" && item.permissions.includes(per.permission_name) && item.permissionGroupName.includes(per.permission_group_name))) : this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "song/performerInfo" && item.permissions.includes(per.permission_name) && item.permissionGroupName.includes(per.permission_group_name)))
        let viewNewCRPermission = this.props?.isMainTitle ? this.props.mainTitlePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "New CR" && item.permissions.includes(per.permission_name) && item.permissionGroupName.includes(per.permission_group_name))) : this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "New CR" && item.permissions.includes(per.permission_name) && item.permissionGroupName.includes(per.permission_group_name)))
        return (
            <>
                <div className="Details-Container">
                            {!this.props?.isMainTitle ?
                                <>
                                    {this.state.licensorsInfo.map((licensor) =>
                                        <div>
                                            <MDBRow>
                                                <MDBCol md={10} className="heder-text">
                                                    <span>
                                                        Details
                                        </span>
                                                </MDBCol>
                                                {this.props?.songAndPerformerPermissions?.edit == 1 &&
                                                    <MDBCol md={2}>
                                                        <div className="edit-btn">
                                                            <BasicButton
                                                                variant="contained"
                                                                type="inline"
                                                                color="primary"
                                                                text={"Edit"}
                                                                onClick={this.handleShowEpisodeViewClearanceRecord}
                                                            />
                                                        </div>
                                                    </MDBCol>
                                                }
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol md={4}>
                                                    <div className="details-para"><span className="text-small">Scene</span>
                                                        {' ' + (licensor?.scene || '-')}
                                                    </div>
                                                </MDBCol>
                                                <MDBCol md={2} className="btn-circle-details">
                                                    {this.state.config.map(item => (
                                                        <div className="btn-text">
                                                            <div className="cancel-icon">
                                                                <span className="details-label">{item.label}</span>
                                                                <span>
                                                                    <MDBIcon
                                                                        className={licensor[item.dataNode] === 1 ? "check-icon" : "cancle-icon"}
                                                                        icon={licensor[item.dataNode] === 1 ? "check-circle" : "times-circle"}
                                                                    />
                                                                </span>
                                                            </div>

                                                        </div>
                                                    ))
                                                    }
                                                </MDBCol>
                                                <MDBCol md={2}>
                                                    {this.state?.isActiveMusicSupervisor && this.state.iconConfig.map(item => (
                                                        <div className="btn-text">
                                                            <div className="cancel-icon">
                                                                <span className="details-label">{item.label}</span>
                                                                <span>
                                                                    <MDBIcon
                                                                        className={licensor[item.dataNode] === 1 ? "check-icon" : "cancle-icon"}
                                                                        icon={licensor[item.dataNode] === 1 ? "check-circle" : "times-circle"}
                                                                    />
                                                                </span>
                                                            </div>

                                                        </div>
                                                    ))
                                                    }
                                                    {!this.state?.isActiveMusicSupervisor && this.state.iconConfig.filter(item => item.dataNode!== "is_ms_complete").map(filteredItem => (
                                                        <div className="btn-text">
                                                            <div className="cancel-icon">
                                                                <span className="details-label">{filteredItem.label}</span>
                                                                <span>
                                                                    <MDBIcon
                                                                        className={licensor[filteredItem.dataNode] === 1 ? "check-icon" : "cancle-icon"}
                                                                        icon={licensor[filteredItem.dataNode] === 1 ? "check-circle" : "times-circle"}
                                                                    />
                                                                </span>
                                                            </div>

                                                        </div>
                                                    ))
                                                    }
                                                </MDBCol>
                                                <MDBCol md={2}>
                                                    <div className="Completed">
                                                    {licensor.songs_list?.find(item => item?.song_id === this.props?.selectedSong?.song_id)?.abbreviation==="Custom Use"?null:<>
                                                            <div className="header-text"> Duration </div>
                                                            <div className="details-para">{licensor.songs_list?.find(item=>item?.song_id === this.props?.selectedSong?.song_id)?.duration || "-"}</div>
                                                        </>}
                                                        
                                                        <div className="header-text">Use </div>
                                                        <div className="details-para">
                                                            {this.state.musicOptions?.find(item => item?.value === licensor.songs_list?.find(item=>item?.song_id === this.props?.selectedSong?.song_id)?.music_uses_id)?.label || '-'}
                                                        </div>
                                                    </div>
                                                </MDBCol>
                                                <MDBCol md={2}>
                                                    <div>
                                                        <div className="header-text"> Custom Use Notes</div>
                                                        <div className="details-para icon-space">{licensor.songs_list?.find(item => item?.song_id === this.props?.selectedSong?.song_id)?.custom_use ?
                                                            licensor.songs_list?.find(item => item?.song_id === this.props?.selectedSong?.song_id)?.custom_use?.length > 114 ?
                                                                <MDBTooltip placement='left' wrapperProps={{ color: 'secondary' }} title={licensor.songs_list?.find(item => item?.song_id === this.props?.selectedSong?.song_id)?.custom_use} ><span className={"notesWithEllipsis"}>{licensor.songs_list?.find(item => item?.song_id === this.props?.selectedSong?.song_id)?.custom_use}</span></MDBTooltip> : <span>{licensor.songs_list?.find(item => item?.song_id === this.props?.selectedSong?.song_id)?.custom_use}</span>
                                                            : <span>--</span>}</div>
                                                    </div>
                                                </MDBCol>

                                            </MDBRow>

                                        </div>
                                    )
                                    }
                                </>
                                :
                                <>
                                    {this.state.licensorsInfo.map((licensor) =>
                                        <div>
                                            <MDBRow>
                                                <MDBCol md={10} className="heder-text">
                                                    <span>
                                                        Details
                                    </span>
                                                </MDBCol>
                                                <MDBCol md={2}>
                                                    <div className="edit-btn">
                                                        <BasicButton
                                                            variant="contained"
                                                            type="inline"
                                                            color="primary"
                                                            text={"Edit"}
                                                            onClick={this.handleShowEpisodeViewClearanceRecord}
                                                            disabled={songAndPerformerPermissions === undefined || (songAndPerformerPermissions?.edit !== 1 ? true : false)}
                                                        />
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol md={2}>
                                                    <div>
                                                        <div className="header-text"> Duration </div>
                                                        <div className="details-para">{licensor.songs_list?.find(item=>item?.song_id === this.props?.selectedSong?.song_id)?.duration || "-"}</div>
                                                    </div>
                                                </MDBCol>
                                                <MDBCol md={2}>
                                                    <div>
                                                        <div className="header-text">Use </div>
                                                        <div className="details-para">
                                                            {this.state.musicOptions?.find(item => item?.value === licensor.songs_list?.find(item=>item?.song_id === this.props?.selectedSong?.song_id)?.music_uses_id)?.label || '-'}
                                                        </div>
                                                    </div>
                                                </MDBCol>
                                                <MDBCol md={2}>
                                                    <div>
                                                        <div className="header-text"> Clearance Rep </div>
                                                        <div className="details-para">{licensor.clearance_rep}</div>
                                                    </div>
                                                </MDBCol>
                                                <MDBCol md={2}>
                                                    <div>
                                                        <div className="header-text"> Custom Use Notes</div>
                                                        <div className="details-para icon-space">{licensor.songs_list?.find(item => item?.song_id === this.props?.selectedSong?.song_id)?.custom_use ?
                                                            licensor.songs_list?.find(item => item?.song_id === this.props?.selectedSong?.song_id)?.custom_use?.length > 114 ?
                                                                <MDBTooltip placement='left' wrapperProps={{ color: 'secondary' }} title={licensor.songs_list?.find(item => item?.song_id === this.props?.selectedSong?.song_id)?.custom_use} ><span className={"notesWithEllipsis"}>{licensor.songs_list?.find(item => item?.song_id === this.props?.selectedSong?.song_id)?.custom_use}</span></MDBTooltip> : <span>{licensor.songs_list?.find(item => item?.song_id === this.props?.selectedSong?.song_id)?.custom_use}</span>
                                                            : <span>--</span>}</div> 
                                                    </div>
                                                </MDBCol>
                                                <MDBCol md={4}>
                                                    <div>
                                                        <div className="header-text"> Main Title Credits</div>
                                                        <div className="details-para icon-space">{licensor.main_title_credits}</div>                           
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow className="guaranteed-eps-row">
                                                <MDBCol md={2}>
                                                    <div>
                                                        <div className="header-text"> Guaranteed Eps </div>
                                                        <div className="details-para">{licensor.guaranteed_eps}</div>
                                                    </div>
                                                </MDBCol>
                                                <MDBCol md={2}>
                                                    <div>
                                                        <div className="header-text"> Show Yr </div>
                                                        <div className="details-para">{licensor.show_yr}</div>
                                                    </div>
                                                </MDBCol>
                                                <MDBCol md={2}>
                                                    <div>
                                                        <div className="header-text"> Apply $ to HV
                                                            <span>
                                                                <MDBIcon
                                                                    className={licensor.is_apply_to_HV === 1 ? "check-icon" : "cancle-icon"}
                                                                    icon={licensor.is_apply_to_HV === 1 ? "check-circle" : "times-circle"}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </MDBCol>
                                                <MDBCol md={6}>
                                                    <div>
                                                        <span className="header-text"> Notes</span>
                                                 <span className="details-para icon-space">{licensor.songs_list[0].notes}</span>
                                                        
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                
                                        </div>
                                    )
                                    }
                                </>
                            }
                            {this.state.showEpisodeViewClearanceRecord && (
                                <>
                                    {this.props?.isMainTitle ? 
                                        <MainViewModalComponent
                                            open={this.state.showEpisodeViewClearanceRecord}
                                            handleClose={() => this.setState({ showEpisodeViewClearanceRecord: false })}
                                            headerText={'Main Title Clearance Record'}
                                            mode={"Edit"}
                                            modalName={"Main Title Clearance Record"}
                                            isMainTitle={this.props?.isMainTitle}
                                            mainTitleId={this.props?.mainTitleId}
                                            show_season_id={this.props?.show_season_id}
                                            showId={this.props?.showId}
                                            seasonId={this.props?.seasonId}
                                            division_id={this.props?.division_id}
                                            show_name={this.props?.show_name}
                                            season_name={this.props?.season_name}
                                            clearance_rep_id={this.props?.clearance_rep_id}
                                            clearance_rep={this.props?.clearance_rep}
                                            episodeLength={this.props?.episodeLength}
                                            updateMainTitleDetails={(details) => this.setState({ licensorsInfo: details })}
                                            toggleRefreshPage={this.props?.toggleRefreshPage}
                                            toggleRefreshMainTitleLicensor={this.props?.toggleRefreshMainTitleLicensor}
                                            mainTitlePermissions={this.props?.mainTitlePermissions}
                                            episodeSongPermissions={this.props?.episodeSongPermissions}
                                        /> : 
                                        <MainViewModalComponent
                                        open={this.state.showEpisodeViewClearanceRecord}
                                    handleClose={this.handleHideEpisodeViewClearanceRecord}
                                    headerText={"Clearance Record"}
                                    mode={"Edit"}
                                    modalName={"Clearance Record"}
                                    clearenceId={this.state.clearenceId}
                                    clearance_divisionId={this.state.clearance_divisionId}
                                    isMainTitle={this.props?.isMainTitle || false}
                                    licensorsInfo={this.props.licensorsInfo}
                                    division_id={this.props.division_id}
                                    updateClearanceDetails={(details) => this.setState({ licensorsInfo: details })}
                                    toggleRefreshPage={this.props?.toggleRefreshPage}
                                    commonPermissions={this.props?.commonPermissions}
                                    applyHVPermission={this.props?.applyHVPermission}
                                    clearancePermissions={this.props.clearancePermissions}
                                    viewNewCRPermission={viewNewCRPermission}
                                    episodeSongPermissions={this.props?.episodeSongPermissions}
                                        />
                                    }
                                </>
                            )}
                        </div>
            </>
        )
    }
});