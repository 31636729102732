import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBContainer } from "mdb-react-ui-kit";
import "./CostAllocation.scss";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import TableComponent from "../../SharedComponents/Table";
import {
  costSplitsConfig,
  songList,
  initialCostAllocationDetails,
  budgetConfig,
} from "./Config";
import * as Constants from "../../../constants/constants";
import clearTrackService from "../../../services/service";
import SearchSelectField from "../../SharedComponents/SearchSelectField";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { validateNumDotOnly } from "../../../Common/Helper";
import MessageModal from "../../SharedComponents/MessageModal";
import NotificationComponent from "../../../Common/NotificationComponent/NotificationComponent";

let totCost = 0;
class CostAllocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config1: budgetConfig,
      config: costSplitsConfig,
      songCostAllocationDetails: initialCostAllocationDetails,
      seasonOptions: [],
      termOptions: [],
      loadingShows: false,
      showOptions: [],
      isFetchingEpisodes: false,
      isFetchingSeason: false,
      seasonBasedEpisodes: [],
      episodeOptions: [],
      term_id: null,
      showBasedSeasonOptions: [],
      costSplits: [],
      costCenterData: [],
      isBudgetDataLoading: false,
      season_id: null,
      isCostSplitLoading: false,
      include_HV: null,
      show_season_episode_id: null,
      show_season_id: null,
      show_id: null,
      budgetData: [],
      isPosting: false,
      openBudgetConfirmation: false,
      isFieldChanged: false,
      costDetails: null,
      openSendEmailModal: false,
      isRefreshDetail: false,
      showFailMessage: false,
      showErrorMessage: false,
      showSuccessMessage: false,
      saveFlag: false,
      emailTextMsg: null,
      isOpenEmailModal: false,
    };
  }
  componentDidMount() {
    this.fetchEntityList("SEASON", null);
    this.getEntity("costAllocationTerms", "COST_ALLOCATION_TERMS", null);
    this.getProgramsDetails("", true)
  }
  fetchEntityList = (entity, searchString) => {
    clearTrackService
      .getData(
        Constants.ClearTrackServiceBaseUrl +
          `/entitySearch?entity=${entity}&searchString=${searchString}`,
        this.props.userContext?.active_tenant?.tenant_id
      )
      .then(
        (response) => {
          if (entity === "SEASON") {
            let formattedList = response.data?.map((item) => ({
              value: item?.season_id,
              label: item?.season_name,
            }));
            formattedList.unshift({ label: "Select", value: null });
            this.setState({ seasonOptions: formattedList });
          }
          //  else if (entity === "TERM") {
          //     let formattedTermList = response.data?.map(item => ({ value: item?.term_id, label: item?.term_name }));
          //     formattedTermList.unshift({ label: "Select", value: null })
          //     this.setState({ termOptions: formattedTermList })
          // }
        },
        (err) => {
          console.log("Error in fetching entity list:", err);
        }
      );
  };
  getBudgetDetails = (value) => {
    this.setState({ isBudgetDataLoading: true });
    clearTrackService
      .getData(
        Constants.ClearTrackServiceBaseUrl +
          `/entitySearch?entity=SHOW_SEASON_EPISODE_BUDGETS&searchString=${value}`,
        this.props.userContext?.active_tenant?.tenant_id,
        this.props.division_id
      )
      .then((response) => {
        let data = response.data;
        this.setState({ budgetData: data });
        let budgetDetailsData = [...this.state.budgetData];
        this.setState({
          isBudgetDataLoading: false,
          budgetData: budgetDetailsData.map((item) => {
            let newItem = { ...item };
            newItem["editing"] = true;
            newItem["cost"] = null;
            return newItem;
          }),
        });
      })
      .catch((err) => {
        console.log("error");
        this.setState({ isCostSplitLoading: false });
      });
  };
  postBudgetCostSplitsUpdate = () => {
    let data = [...this.state.budgetData];
    let costData = data.map((item) => ({
      show_season_episode_cost_center_budget_id:
        item?.show_season_episode_cost_center_budget_id,
      cost_center_id: item?.cost_center_id,
      cost_center_name: item?.cost_center_name,
      budget: item?.budget,
      actual_cost: item?.cost,
      show_season_episode_negotiated_cost_id:
        item?.show_season_episode_negotiated_cost_id,
    }));
    let payload = {
      is_cost_allocation_calculator: 1,
      episode_negotiated_costs: costData,
      show_season_episode_id: this.state.show_season_episode_id,
      show_season_id: this.state.show_season_id,
      show_id: this.state.show_id,
      season_id: this.state.season_id,
    };
    this.setState({ isPosting: true });
    clearTrackService
      .postDataParams(
        Constants.ClearTrackServiceBaseUrl + `/showSeasonEpisodes`,
        payload,
        this.props.userContext?.active_tenant?.tenant_id,
        this.props.division_id
      )
      .then(
        (response) => {
          if (response.data.error) {
            this.setState({ isPosting: false });
            this.notificationComponent(true, "fail");
          } else {
            if (this.state.isFieldChanged) {
              this.notificationComponent(true, "success");
              
              this.setState({
               // openSendEmailModal: true,
                isPosting: false
              });
              this.postSendDataEmail();
              
            } else {
              this.setState({ isPosting: false });
              this.notificationComponent(true, "success");
            }
          }
        },
        (err) => {
          this.setState({ isPosting: false });
          this.notificationComponent(true, "fail");
        }
      );
  };
  postSendDataEmail = () => {
    this.setState({ isSendEmail: true });
    let payload = {
      letter_type: "costallocationcalculator",
      show_season_episode_id: this.state.show_season_episode_id,
    };
    clearTrackService
      .postDataParams(
        Constants.ClearTrackServiceBaseUrl + `/sendEmail`,
        payload,
        this.props.userContext?.active_tenant?.tenant_id,
        this.props.division_id
      )
      .then((response) => {
        this.setState({
          isSendEmail: false,
          //openSendEmailModal: false,
          isFieldChanged: false,
          //emailTextMsg: response?.data?.data?.message,
          //isOpenEmailModal: true,
        });
          this.props?.handleClose();
      })
      .catch((err) => {
        this.setState({
          isSendEmail: false,
         // openSendEmailModal: false,
          isFieldChanged: false,
          //isOpenEmailModal: false,
        });
        this.props?.handleClose();
      });
  };
  getCostSplitDetails = (season_id, term_id, include_HV, show_season_id) => {
    this.setState({ isCostSplitLoading: true });
    let seasonId = season_id;
    let divisionId = show_season_id ?  this.state.songCostAllocationDetails?.selected_program?.division_id : null;
    clearTrackService
      .getData(
        Constants.ClearTrackServiceBaseUrl +
          `/showSeasonCostSplits?season_id=${seasonId}&term_id=${term_id}&include_HV=${
            include_HV ? include_HV : 0
          }&show_season_id=${show_season_id}`,
        this.props?.userContext?.active_tenant?.tenant_id,
        divisionId
      )
      .then((response) => {
        this.setState({
          isCostSplitLoading: false,
          costCenterData: response?.data,
        });
        let costDataList = response.data;
        this.setState({ costSplits: costDataList });
      })
      .catch((err) => {
        console.log("error");
        this.setState({ isCostSplitLoading: false });
      });
  };
  handleCompute = () => {
    let newCostSplits = this.state.costSplits.map((item) => {
      return { ...item, cost: 0 };
    });
    newCostSplits.map((item) => {
      item.cost = parseFloat(totCost * (item.std / 100)).toFixed(2);
    });
    this.setState({ costSplits: newCostSplits });
    let data = [...this.state.budgetData];
    this.setState({
      budgetData: data.map((item) => {
        let newItem = { ...item };
        newCostSplits.map((nItem) => {
          if (item.cost_center_name === nItem.cost_center_name) {
            newItem.cost = nItem.cost;
          }
        });
        return newItem;
      }),
      isFieldChanged: true,
    });
  };

  getEntity = (entity, entityType, searchString) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel(
        "Operation canceled due to new request"
      );
    var cancelToken = axios.CancelToken.source();
    this.setState({
      searchCancelToken: cancelToken,
      ishvCostSplitLoading: true,
      isOptionCostSplitLoading: true,
    });
    clearTrackService
      .getDataWithCancel(
        Constants.ClearTrackServiceBaseUrl +
          `/entitySearch?entity=${entityType}&searchString=${searchString}`,
        cancelToken,
        this.props?.userContext?.active_tenant?.tenant_id
      )
      .then((response) => {
        if (entityType === "COST_ALLOCATION_TERMS") {
          let costAllocTerms = response?.data?.map((item) => ({
            label: item.term_name,
            value: item.term_id,
          }));
          this.setState({
            [entity]: costAllocTerms,
            term_id: costAllocTerms[0]?.value,
          });
        }
      });
  };
  handleChange = (field, value) => {
    this.setState((prevState) => ({
      songCostAllocationDetails: {
        ...prevState.songCostAllocationDetails,
        [field]: value,
      },
    }));
  };
  onChangeHandler = (field, value) => {
    if (field === "term_id") {
      this.setState({ [field]: value });
    } else {
      this.setState((prevState) => ({
        songCostAllocationDetails: {
          ...prevState.songCostAllocationDetails,
          [field]: value ? 1 : 0,
        },
      }));
    }
  };

  getProgramsDetails = (event, mount=false) => {
    // if (event.target.value !== "") {
      this.setState({ loadingShows: true });
      if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel(
          "Operation canceled due to new request."
        );
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken });
      clearTrackService
        .getDataWithCancel(
          Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=SHOW&searchString=${mount == true? event : event.target.value}`,
          cancelToken,
          this.props?.userContext?.active_tenant?.tenant_id,
          this.props?.division_id
        )
        .then((res) => {
          let formattedList = res.data.map((item) => ({
            value: item.show_id,
            text: item.show_name,
            division_id: item?.division_id,
          }));
          this.setState({ showOptions: formattedList, loadingShows: false });
        })
        .catch((err) => {
          this.setState({ showOptions: [], loadingShows: false });
        });
    // }
  };
  selectedShow = (newValue) => {
    if (newValue) {
      this.handleChange("selected_program", newValue);
      this.handleChange("show_based_season_id", null);
      this.handleChange("season_id", null);
      this.setState({ isFetchingSeason: true, show_id: newValue.value });
      clearTrackService
        .getData(
          Constants.ClearTrackServiceBaseUrl +
            `/seasonsBasedOnShows?show_id=${newValue.value}`,
          this.props?.userContext?.active_tenant?.tenant_id
        )
        .then(
          (response) => {
            let formattedList = response?.data[0]?.seasons?.map((item) => ({
              value: item.season_id,
              label: item.season_name,
              show_season_id: item.show_season_id,
            }));
            this.setState({
              isFetchingSeason: false,
              showBasedSeasonOptions: formattedList,
              seasonBasedEpisodes: response?.data[0]?.seasons,
            });
          },
          (err) => {
            console.log("Error in fetching Details:", err);
          }
        );
    } else {
      this.setState({showBasedSeasonOptions:[],seasonBasedEpisodes:[],episodeOptions:[]})
      this.handleChange("selected_program", null);
      this.handleChange("season_id", null);
      this.handleChange("show_based_season_id", null);
      this.handleChange("episode_number", null);
    }
  };
  updateBudgetDataInlineEdit = (data, index, nodeName) => {
    if (nodeName === "cost") {
      console.log("data", data, index);
      let budgetDetailsData = [...this.state.budgetData];
      (budgetDetailsData[index].cost = data[index].cost),
        (budgetDetailsData[index].cost_center_name =
          data[index].cost_center_name),
        (budgetDetailsData[index].budget = data[index].budget);
      this.setState({
        budgetData: budgetDetailsData,
        isFieldChanged: true,
      });
    }
  };
  getShowBasedEpisodes = (showSeasonId) => {
    this.setState({ isFetchingEpisodes: true });
    clearTrackService
      .getData(
        Constants.ClearTrackServiceBaseUrl +
          `/showSeasonEpisodes?show_season_id=${showSeasonId}`,
        this.props.userContext?.active_tenant?.tenant_id,
        1
      )
      .then(
        (response) => {
          let sortedData = response?.data?.sort((a,b) => a?.episode_number - b?.episode_number)
          let formattedList = sortedData?.map((item) => ({
            value: item.show_season_episode_id,
            label: `${item.episode_number ? item.episode_number : '' }${item.episode_title ? '-'+item.episode_title : ''}`,
          }));
          this.setState({
            episodeOptions: formattedList,
            isFetchingEpisodes: false,
          });
        },
        (err) => {
          console.log("Error in fetching Details:", err);
        }
      );
  };
  notificationComponent = (value, mode = "fail") => {
    switch (mode) {
      case "success":
        return this.setState({ showSuccessMessage: value, saveFlag: true });
      case "fail":
        return this.setState({ showFailMessage: value });
    }
  };
  handleResetNotify = () => {
    this.setState({ showFailMessage: false, showSuccessMessage: false });
    if (!this.state.isFieldChanged) {
      this.props?.handleClose();
    }
  };
  render() {
    totCost =
      (parseFloat(
        this.state.songCostAllocationDetails?.std
          ? this.state.songCostAllocationDetails?.std
          : 0
      ) +
      parseFloat(
        this.state.songCostAllocationDetails?.lib
          ? this.state.songCostAllocationDetails?.lib
          : 0
      ) +
      parseFloat(
        this.state.songCostAllocationDetails?.mt
          ? this.state.songCostAllocationDetails?.mt
          : 0
      ) +
      parseFloat(
        this.state.songCostAllocationDetails?.ad_card
          ? this.state.songCostAllocationDetails?.ad_card
          : 0
      )).toFixed(2);
    let severity = this.state.showFailMessage
      ? "error"
      : this.state.showSuccessMessage
      ? "success"
      : "";
    let messageNotification =
      this.state.isRefreshDetail && this.state.showSuccessMessage
        ? "Please Refresh the Showseason Detail tab to know updated Budget Cost."
        : this.state.showSuccessMessage
        ? "The changes are saved successfully!"
        : this.state.showFailMessage
        ? "Saved Changes Failed"
        : "";
    return (
      <div className="CostAllocationContainer">
        <MDBRow>
          <div>
            <NotificationComponent
              open={this.state.showSuccessMessage || this.state.showFailMessage}
              message={messageNotification}
              severity={severity}
              handleResetNotify={this.handleResetNotify.bind(this)}
            />
          </div>
          <MDBCol md={6} className="vertical-line">
            {/* <span className="songs-text">By Songs</span> */}
            <MDBRow>
              <MDBCol md={4}>
                <SelectField
                  label={"Season"}
                  id="season"
                  value={
                    this.state.showBasedSeasonOptions?.length > 0
                      ? this.state.seasonOptions?.find(
                          (item) =>
                            item?.value ===
                            this.state.songCostAllocationDetails
                              ?.show_based_season_id
                        )?.value
                      : this.state.songCostAllocationDetails?.season_id
                  }
                  options={this.state.seasonOptions || []}
                  onChange={(e) => {
                    this.handleChange("season_id", e.target.value);
                    this.setState({ season_id: e.target.value });
                    this.getCostSplitDetails(
                      e.target.value,
                      this.state.term_id,
                      this.state.include_HV,
                      null
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={4}>
                <SelectField
                  label={"Terms"}
                  id="terms"
                  value={this.state.term_id}
                  options={this.state.costAllocationTerms || []}
                  onChange={(e) => {
                    this.onChangeHandler("term_id", e.target.value);
                    this.setState({ term_id: e.target.value });
                    this.getCostSplitDetails(
                      this.state.season_id,
                      e.target.value,
                      this.state.include_HV,
                      this.state.show_season_id
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3} className="m-t">
                <BasicCheckbox
                  label={"Include HV"}
                  id={"include_HV"}
                  checked={this.state.include_HV ? 1 : 0}
                  onChange={(e) => {
                    this.onChangeHandler("include_HV", e.target.checked);
                    this.setState({ include_HV: e.target.checked });
                    this.getCostSplitDetails(
                      this.state.season_id,
                      this.state.term_id,
                      e.target.checked,
                      null
                    );
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={3}>
                <BasicTextField
                  label={"Std Cost"}
                  id={"std-cost"}
                  value={this.state.songCostAllocationDetails?.std}
                  onChange={(e) =>
                    validateNumDotOnly(e.target.value)
                      ? this.handleChange("std", e.target.value)
                      : null
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" size="small">
                        {"$"}
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <BasicTextField
                  label={"Lib Cost"}
                  id={"lib-cost"}
                  value={this.state.songCostAllocationDetails?.lib}
                  onChange={(e) =>
                    validateNumDotOnly(e.target.value)
                      ? this.handleChange("lib", e.target.value)
                      : null
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" size="small">
                        {"$"}
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <BasicTextField
                  label={"MT Cost"}
                  id={"mtcost"}
                  value={this.state.songCostAllocationDetails?.mt}
                  onChange={(e) =>
                    validateNumDotOnly(e.target.value)
                      ? this.handleChange("mt", e.target.value)
                      : null
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" size="small">
                        {"$"}
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <BasicTextField
                  label={"AdCard Cost"}
                  id={"adcard-cost"}
                  value={this.state.songCostAllocationDetails?.ad_card}
                  onChange={(e) =>
                    validateNumDotOnly(e.target.value)
                      ? this.handleChange("ad_card", e.target.value)
                      : null
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" size="small">
                        {"$"}
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <BasicLabel text={"Total cost"} />
              <p>${totCost}</p>
            </MDBRow>
            <MDBRow>
              <MDBCol md={5}>
                <BasicButton
                  className="btnwidth"
                  variant="contained"
                  type="inline"
                  text={"Compute"}
                  onClick={this.handleCompute}
                />
              </MDBCol>
            </MDBRow>
            {this.state.isCostSplitLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <MDBRow id="table-width" className="mt2 Song_Table">
                <span className="table-name">Caluculated Cost</span>
                <TableComponent
                  id={"song"}
                  config={this.state.config}
                  list={this.state.costSplits || []}
                />
              </MDBRow>
            )}
          </MDBCol>
          <MDBCol md={6}>
            <span className="songs-text">By Show/Seasons</span>
            <MDBRow>
              <MDBCol md={6}>
                <SearchSelectField
                  id={"program"}
                  label={"Program"}
                  detail_selected={
                    this.state.songCostAllocationDetails?.selected_program
                  }
                  placeholder={'- Search -'}
                  options={this.state.showOptions}
                  loading={this.state.loadingShows}
                  width="80%"
                  multiple={false}
                  searchSelect={true}
                  searchText={this.getProgramsDetails}
                  valueSelected={(e, newValue) => this.selectedShow(newValue)}
                />
              </MDBCol>

              <MDBCol md={4}>
                {this.state.isFetchingSeason ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <SelectField
                    id={"seasons"}
                    label={"Seasons"}
                    value={
                      this.state.songCostAllocationDetails?.show_based_season_id
                    }
                    options={this.state.showBasedSeasonOptions}
                    width="80%"
                    multiple={false}
                    onChange={(e) => {
                      this.handleChange("show_based_season_id", e.target.value);
                      this.setState({ season_id: e.target.value });
                      let selectedShowSeason =
                        this.state.seasonBasedEpisodes?.find(
                          (item) => item.season_id === e.target.value
                        )?.show_season_id;
                      this.setState(
                        { show_season_id: selectedShowSeason },
                        () => {
                          this.getShowBasedEpisodes(this.state.show_season_id);
                        }
                      );
                    }}
                  />
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={6}>
                {this.state.isFetchingEpisodes ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <SelectField
                    id={"episode"}
                    label={"Episode"}
                    placeHolderText="- Select -"
                    value={this.state.songCostAllocationDetails?.episode_number}
                    options={this.state.episodeOptions || []}
                    onChange={(e, newValue) => {
                      this.handleChange("episode_number", e.target.value);
                      this.setState({ show_season_episode_id: e.target.value });
                      this.getBudgetDetails(e.target.value);
                      this.getCostSplitDetails(
                        this.state.season_id,
                        this.state.term_id,
                        this.state.include_HV,
                        this.state.show_season_id
                      );
                      //this.getCostSplitDetails(null , this.state.term_id , this.state.include_HV,e.target.value)
                    }}
                  />
                )}
              </MDBCol>
            </MDBRow>
            <br />
            <br />
            <br />
            {this.state.isBudgetDataLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <MDBRow id="table-width" className="mt2 Song_Table p-t">
                <span className="table-name">Budget</span>
                <TableComponent
                  id={"budget"}
                  idForFocus={"cost"}
                  config={this.state.config1}
                  list={this.state.budgetData}
                  updateBudgetDataInlineEdit={this.updateBudgetDataInlineEdit.bind(
                    this
                  )}
                />
              </MDBRow>
            )}
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBRow className="p-2">
            <MDBCol md={9}></MDBCol>
            <MDBCol md={2}>
              <BasicButton
                className="btnwidth"
                variant="contained"
                type="inline"
                text={
                  this.state.isPosting ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    "Update SS Ngtd. Fees"
                  )
                }
                onClick={() => {
                  let costDetails = "";
                  if (this.state.isFieldChanged) {
                    if (this.state.budgetData?.length > 0) {
                      this.state.budgetData?.find((item) => {
                        if (item.cost !== null) {
                          costDetails =
                            costDetails +
                            "update the negotiated " +
                            item?.cost_center_name +
                            " for " +
                            this.state.songCostAllocationDetails?.episode_number +
                            " from " +
                            "$" +
                            parseFloat(item?.actual_cost ?item?.actual_cost:0).toFixed(2) +
                            " to " +
                            "$" +
                            parseFloat(item?.cost).toFixed(2) +
                            ",";
                          this.setState({
                            costDetails: costDetails,
                            openBudgetConfirmation: true,
                          });
                        }
                      });
                    }
                  } else {
                    this.postBudgetCostSplitsUpdate();
                  }
                }}
              />
            </MDBCol>
            <MDBCol md={1}>
              <BasicButton
                type="inline"
                variant="outlined"
                text={"Close"}
                onClick={this.props?.handleClose}
              />
            </MDBCol>
          </MDBRow>
          <MessageModal
            open={this.state.openBudgetConfirmation}
            title={"Cost Allocation"}
            message={`You are about to update budget values.If this is correct click OK otherwise click Cancel`} // ${this.state.costDetails}
            hideCancel={false}
            primaryButtonText={"OK"}
            secondaryButtonText={"Cancel"}
            onConfirm={() => {
              this.setState({
                openBudgetConfirmation: false,
                isRefreshDetail: true,
              });
              this.postBudgetCostSplitsUpdate();
            }}
            handleClose={() => this.setState({ openBudgetConfirmation: false })}
          />
          {/* <MessageModal
            open={this.state.openSendEmailModal}
            title={"Cost Allocation"}
            message={`Send notification email to QA user to inform of changes in fees?`}
            hideCancel={false}
            primaryButtonText={
              this.state.isSendEmail ? (
                <CircularProgress color="inherit" size={18} />
              ) : (
                "OK"
              )
            }
            secondaryButtonText={"Cancel"}
            onConfirm={() => {
              this.postSendDataEmail();
            }}
            handleClose={() => {
              this.setState({
                openSendEmailModal: false,
                isFieldChanged: false,
              });
              this.props?.handleClose();
            }}
          /> */}
          {/* {this.state.isOpenEmailModal && (
            <MessageModal
              open={this.state.isOpenEmailModal}
              title={"Message"}
              message={this.state.emailTextMsg}
              hideCancel={true}
              primaryButtonText={"OK"}
              onConfirm={() => {
                this.setState({ isOpenEmailModal: false });
                this.props?.handleClose();
              }}
              handleClose={(e) => this.setState({ isOpenEmailModal: false })}
            />
          )} */}
        </MDBRow>
      </div>
    );
  }
}
export default withUserContext(CostAllocation);
