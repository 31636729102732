import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact';
import { intialPilotBudgetDetails, amortBudgetConfig, pilotList, pilotBudgetConfig2, pilotList2 } from './Config';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import TableComponent from '../../SharedComponents/Table';
import "./Budget.scss";
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import * as Constants from '../../../constants/constants';
import { validateNumbersOnly } from '../../../Common/Helper';
import { CircularProgress } from "@material-ui/core";
let canEditBudgetDetails = false;
class Amort extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pilotDetails: { ...intialPilotBudgetDetails },
            renderList: [],
            config: amortBudgetConfig,
            renderList2: [],
            config2: pilotBudgetConfig2,
            showDetailsOptions: null,
            isSubmit: false,
            isLoading: false,
            totalEfc: 0,
            totalEstCost: 0,
            totalLockedCost: 0,
            mainPilotDetails: {},
            pdfPayload: {},
            isPdfPosting: false,
            errorFlag: false,
            errorMsg: null
        };
    }

    componentDidMount = () => {
        this.fetchData("getInitialValues", `/showSeason?showSeasonId=${this.props.show_season_id}`);
        this.getPermissions(this.props.permissionArr);
        this.getDetailsOptions("showDetailsOptions", "BUDGET_DETAIL_CODE", "");
    }

    getSeriesPatternDetails = (loader = true) => {
        this.setState({ isLoading: loader });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonPilotBudgetDetails?show_season_id=${this.props?.show_season_id}&apply_to=AMORT`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                console.log("responseuse", response.data[0].pilot_budget_details);
                let summaryBudget = response.data[0].summary?.map(item => {
                    let newItem = {
                        "cost_center_name": item?.cost_center_name,
                        "description": item?.description,
                        "est_cost": item?.total_est_cost,
                        "locked_cost": item?.locked_cost,
                        "efc": item?.efc
                    }
                    return newItem
                })
                let totalEfc = response.data[0].pilot_budget_details.reduce((accumulator, object) => {
                    return accumulator + object.efc;
                }, 0);
                let totalEstCost = response.data[0].pilot_budget_details.reduce((accumulator, object) => {
                    return accumulator + object.est_cost;
                }, 0);
                let totalLockedCost = response.data[0].pilot_budget_details.reduce((accumulator, object) => {
                    return accumulator + object.locked_cost;
                }, 0);
                this.setState({ isSubmit: false, totalEfc: totalEfc, totalEstCost: totalEstCost, totalLockedCost: totalLockedCost, renderList2: summaryBudget, renderList: response.data[0].pilot_budget_details, pilotDetails: response.data[0], isLoading: false })
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                this.props?.notificationComponent(true, "fail");
            });
    }

    getDetailsOptions = (entity, entitytype, searchString) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then((res) => {
                let formattedList = res?.data?.map(item => ({ value: item.budget_detail_code_id, label: `${item?.detail_code}-${item?.description}` }));
                let config = { ...this.state.config }
                config.headings[0].selectOptions = formattedList
                this.setState({ showDetailsOptions: formattedList });
            })
            .catch((err) => {
                console.log("error")
            });
    };

    handleInlineEdits = (id, dataItem) => {
        let allDetails = [...this.state.renderList]
        this.setState({
            renderList: allDetails.map((item) => {
                let newItem = {...item}
                if (item.show_season_pilot_budget_details_id == id)
                    newItem['editing'] = true
                return newItem
            })
        })
    }

    postPilotBudgetDetails = (showPilotBudgetDetails, postFlag) => {
        this.setState({ isSubmit: postFlag });
        let Data = { ...showPilotBudgetDetails }
        Data.detail = this.state.showDetailsOptions.find(data => data.value === Data.detail)?.label;
        Data.detail_id = this.state.showDetailsOptions.find(data => data.value === Data.detail_id)?.value;
        Data.show_season_id = this.props?.show_season_id;
        Data.season_id = this.props?.initialRecordData?.season_id;
        Data.show_id = this.props?.initialRecordData?.show_id;
        Data.apply_to = "AMORT";
        Data.show_season_pilot_budget_header_id = this.state.pilotDetails?.show_season_pilot_budget_header_id;
        ClearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/showSeasonPilotBudgetDetails`,
            Data,
            this.props.userContext?.active_tenant?.tenant_id, this.props.division_id
        ).then(
            (response) => {
                if (response.data.error) {
                    this.setState({ isSubmit: false });
                    this.props?.notificationComponent(true, "fail")
                } else {
                    // this.setState({
                    //     isSubmit: false,
                    // });
                    if (showPilotBudgetDetails?.editing) {
                        showPilotBudgetDetails.editing = false;
                    }
                    this.props?.notificationComponent(true, "success");
                    this.setState({ errorFlag: false, errorMsg: null });
                    this.getSeriesPatternDetails(false);
                }
            },
            (err) => {
                this.setState({ isSubmit: false });
                this.props?.notificationComponent(true, "fail")
                console.log("Post showProgramDetails Details error: " + err);
            }
        );
    };

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        let showPilotBudget = [...data];
        let showPilotBudgetDetails = [...this.state.renderList];
        //showPilotBudgetDetails[index].detail = showPilotBudget[index]?.detail_id || null;
        showPilotBudgetDetails[index].budget_detail_code_id = showPilotBudget[index]?.budget_detail_code_id || null;
        showPilotBudgetDetails[index].description = showPilotBudget[index]?.description || null;
        showPilotBudgetDetails[index].secondary_description = showPilotBudget[index]?.secondary_description || null;
        showPilotBudgetDetails[index].est_cost = showPilotBudget[index]?.est_cost || null;
        showPilotBudgetDetails[index].efc = showPilotBudget[index]?.efc || null;
        showPilotBudgetDetails[index].locked_cost = showPilotBudget[index]?.locked_cost || null;
        if ((showPilotBudgetDetails[index]["budget_detail_code_id"] !== null && showPilotBudgetDetails[index]["budget_detail_code_id"] !== undefined) && (showPilotBudgetDetails[index]["est_cost"] !== null && showPilotBudgetDetails[index]["est_cost"] !== undefined && validateNumbersOnly(showPilotBudgetDetails[index]["est_cost"]))) {
            if (showPilotBudgetDetails[index]["efc"] === null || showPilotBudgetDetails[index]["efc"] === undefined || (showPilotBudgetDetails[index]["efc"] !== null && showPilotBudgetDetails[index]["efc"] !== undefined && validateNumbersOnly(showPilotBudgetDetails[index]["efc"]))) {
                if (showPilotBudgetDetails[index]["locked_cost"] === null || showPilotBudgetDetails[index]["locked_cost"] === undefined || (showPilotBudgetDetails[index]["locked_cost"] !== null && showPilotBudgetDetails[index]["locked_cost"] !== undefined && validateNumbersOnly(showPilotBudgetDetails[index]["locked_cost"]))) {
                    this.postPilotBudgetDetails(showPilotBudgetDetails[index], showPilotBudgetDetails[index].show_season_pilot_budget_details_id);
                }
            }
        }
        else {
            this.setState({ errorFlag: true, errorMsg: "'Details' & 'Est. Cost' column data is required." });
        }
        // if (showPilotBudgetDetails[index].detail_id === null) {
        //     this.setState({ isPostInitiated:true })

        // } else {
        //     this.setState({isPostInitiated:false, renderList: showPilotBudgetDetails });
        //     this.postPilotBudgetDetails(showPilotBudgetDetails[index]);
        // }
        // this.setState({ renderList: showPilotBudgetDetails });
        //     this.postPilotBudgetDetails(showPilotBudgetDetails[index]);

    };
    handleReadCallback = (index) => {
        let allDetails = [...this.state.renderList]
        allDetails[index].editing = false
        this.setState({ renderList: allDetails })
    }
    handleAddNewItem = (newDataItem) => {
        let newPilotBudgetData = {
            //detail: newDataItem?.detail_id || null,
            budget_detail_code_id: newDataItem?.budget_detail_code_id || null,
            description: newDataItem?.description || null,
            secondary_description: newDataItem?.secondary_description || null,
            est_cost: newDataItem?.est_cost || null,
            locked_cost: newDataItem?.locked_cost || null,
            efc: newDataItem?.efc || null,
        };
        // let showPilotBudgetDetails = [...this.state.renderList];
        // showPilotBudgetDetails.push(newPilotBudgetData);
        if (newPilotBudgetData["budget_detail_code_id"] !== null && newPilotBudgetData["budget_detail_code_id"] !== undefined && newPilotBudgetData["est_cost"] !== null && newPilotBudgetData["est_cost"] !== undefined && validateNumbersOnly(newPilotBudgetData["est_cost"])) {
            if ((newPilotBudgetData["efc"] === null || newPilotBudgetData["efc"] === undefined) || (newPilotBudgetData["efc"] !== null && newPilotBudgetData["efc"] !== undefined && validateNumbersOnly(newPilotBudgetData["efc"]))) {
                if ((newPilotBudgetData["locked_cost"] === null || newPilotBudgetData["locked_cost"] === undefined) || (newPilotBudgetData["locked_cost"] !== null && newPilotBudgetData["locked_cost"] !== undefined && validateNumbersOnly(newPilotBudgetData["locked_cost"]))) {
                    this.postPilotBudgetDetails(newPilotBudgetData, 'newItemPost');
                }
            }
        }
        else {
            this.setState({ errorFlag: true, errorMsg: "'Details' & 'Est. Cost' column data is required." });
        }
        // if (newPilotBudgetData.detail_id === null) {
        //     this.setState({ isPostInitiated:true })

        // } else {
        //     this.setState({ isPostInitiated:false})
        //     this.postPilotBudgetDetails(newPilotBudgetData);
        //     this.setState({ renderList: showPilotBudgetDetails });
        // }
        // this.postPilotBudgetDetails(newPilotBudgetData);
        // this.setState({ renderList: showPilotBudgetDetails });
    };

    deletePilotBudgetDetails = (pilotDetails) => {
        let pilotData = {};
        pilotData = {
            ...pilotDetails,
            is_delete: 1,
            show_season_id: this.props?.show_season_id,
            season_id: this.props?.initialRecordData?.season_id,
            show_id: this.props?.initialRecordData?.show_id,
            apply_to: "AMORT",
            show_season_pilot_budget_header_id: this.state.pilotDetails?.show_season_pilot_budget_header_id,
            show_season_pilot_budget_details_id: pilotDetails?.show_season_pilot_budget_details_id
        };
        this.setState({ isSubmit: pilotDetails?.show_season_pilot_budget_details_id });
        ClearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/showSeasonPilotBudgetDetails`,
            pilotData,
            this.props.userContext?.active_tenant?.tenant_id, this.props.division_id
        ).then(
            (response) => {
                if (response.data.error) {
                    this.setState({ isSubmit: false });
                    this.props?.notificationComponent(true, "fail")
                } else {
                    // this.setState({
                    //     isSubmit: false,
                    // });
                    this.props?.notificationComponent(true, "success")
                    this.getSeriesPatternDetails(false);
                }
            },
            (err) => {
                this.setState({ isSubmit: false });
                this.props?.notificationComponent(true, "fail")
                console.log("Post showProgramDetails Details error: " + err);
            }
        );
    };
    getPermissions(permissions) {
        if (permissions.length > 0) {
            canEditBudgetDetails = permissions.filter((obj) => obj.permission_name === "Pilot Budget" && obj.view === 1 && obj.edit === 1);
            canEditBudgetDetails = canEditBudgetDetails.length > 0 ? true : false;
            if (canEditBudgetDetails === false) {
                let tempConfig = amortBudgetConfig;
                let actionsToRemove = ["pen", "times"];
                let tempActions = tempConfig.actions.filter(item => !actionsToRemove.includes(item));
                amortBudgetConfig.actions = tempActions;
                this.setState({ config: amortBudgetConfig })
            }
            // canGeneratePDF = permissions.filter((obj, index) => obj.permission_name === "Can generate a PDF of a budget");
            // canGeneratePDF = canGeneratePDF.length > 0 ? true : false;

        }
    }

    downloadUrl = (url, reportName) => {
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', reportName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                this.setState({ isPdfPosting: false });
                this.props?.notificationComponent(true, "success");
            })
        }
    }
    generateReport = (reportName, searchPayload) => {
        if (!searchPayload || !reportName) {
            return;
        }
        Object.keys(searchPayload).map(obj => {
            if (searchPayload[obj] === undefined) {
                searchPayload[obj] = null;
            }
        })
        let encryptTedStr = btoa(JSON.stringify(searchPayload));
        let queryParams = `reportName=${reportName}&searchJson=${encryptTedStr}`
        return ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/exportPdf?${queryParams}`, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id, true)
    }
    generatePdf = () => {
        this.setState({ postInitiated: true, isPdfPosting: true });
        this.generateReport("rptProposedMusicBudgetReport", this.state.pdfPayload).then(response => {
            if (response.data.error) {
                this.setState({ isPdfPosting: false });
                this.props?.notificationComponent(true, "fail");
                // this.props.fieldChanged(true, "reportNotFound");
            }
            else {
                let url = response.data;
                this.setState({ isPdfPosting: false });
                this.downloadUrl(url, "AmortBudgetReport")
            }
        }, (err) => {
            this.setState({ isPdfPosting: false });
            this.props?.notificationComponent(true, "fail");
            console.log(err)
            // this.props.fieldChanged(true, "reportFail");
        })
    }
    fetchData = (field, url) => {
        this.setState({ isLoading: true });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                switch (field) {
                    case 'getInitialValues':
                        if (response.data.length > 0) {
                            if (response.data[0]?.pilot_maintance?.length > 0) {
                                this.setState({
                                    mainPilotDetails: response.data[0]?.pilot_maintance[0],
                                    pdfPayload: {
                                        "show_season_pilot_id": response.data[0]?.pilot_maintance[0]?.show_season_pilot_id,
                                        "include_pilot": 0,
                                        "include_pattern": 0,
                                        "include_amort": 1,
                                        "include_clearance_budget": response.data[0]?.pilot_maintance[0]?.apply_to === "AMORT" ? 1 : 0,
                                        "is_box_upload": 1
                                    },
                                }, () => { this.getSeriesPatternDetails() });
                            }
                            else {
                                this.setState({ isLoading: false}, () => { this.getSeriesPatternDetails() })
                            }
                        }
                        else {
                            this.setState({ isLoading: false})
                        }
                        break;
                }
            },
                (err) => {
                    this.setState({ isLoading: false })
                    console.log("Error in fetching Address Types:", err)
                })
    }
    render() {
        return (
            <div className="Pilot-Container">
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardText>
                            {this.state.isLoading ? (
                                <div style={{ textAlign: "center" }}>
                                    <CircularProgress size={24} />
                                </div>
                            ) : (
                                    <MDBRow className="cueSheetTable" id="table-bg-creative">
                                        {this.state.isPostInitiated ? <p className="f-color">Details should be reqiure</p> : this.state.isLoading = false}
                                        {/* {canGeneratePDF && this.state.pdfPayload?.show_season_pilot_id && <div className="pdfFileContainer"><BasicButton
                                            icon={this.state.isPdfPosting ? " " : "file-pdf"}
                                            text={this.state.isPdfPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                                            variant={"contained"}
                                            onClick={this.generatePdf}
                                        /></div>} */}
                                        {this.state.config.headings[0].selectOptions.length > 0 &&
                                            <>
                                                {this.state.errorFlag && <p className="errorMessage">{this.state.errorMsg}</p>}
                                                <TableComponent
                                                    list={this.state.renderList || []}
                                                    config={this.state.config}
                                                    editCallback={canEditBudgetDetails ? this.handleInlineEdits.bind(this) : false}
                                                    saveCallback={canEditBudgetDetails ? this.saveInlineEdits.bind(this) : false}
                                                    handleAddNewItem={canEditBudgetDetails ? this.handleAddNewItem.bind(this) : false}
                                                    addItemToList={canEditBudgetDetails ? true : false}
                                                    handleDelCallBack={canEditBudgetDetails ? ((id, item) =>
                                                        this.deletePilotBudgetDetails(item)) : false}
                                                    readCallback={canEditBudgetDetails ? this.handleReadCallback.bind(this) : false}
                                                    postInitiated={this.state.isSubmit}
                                                />
                                            </>
                                        }
                                    </MDBRow>
                                )}
                            <MDBRow>
                                <MDBCol md={7}></MDBCol>
                                <MDBCol md={5} className="float-r">
                                    <BasicLabel text={"Cost Before Music Licensing"} />
                                    <div className="display-Cost">
                                        <span className="greenColoredCost">${this.state.totalEstCost}</span>
                                        <span className="lgText">${this.state.totalLockedCost}</span>
                                        <span className="lgText">${this.state.totalEfc}</span>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            {this.state.mainPilotDetails?.apply_to === 'AMORT' && <MDBRow>
                                <h6>Music Licensing Budgets</h6>
                                <div className="cueSheetTable" id="table-bg-creative">
                                    <TableComponent
                                        list={this.state.renderList2 || []}
                                        config={this.state.config2}
                                    />
                                </div>
                            </MDBRow>}
                            {this.state.mainPilotDetails?.apply_to === 'AMORT' ? this.state.renderList2?.length > 0 ?
                                this.state.renderList2?.map(item => <MDBRow>
                                    <MDBCol md={7}></MDBCol>
                                    <MDBCol md={5} className="float-r">
                                        <BasicLabel text={`Amort Cost(${item?.detail})`} />
                                        <div className="display-Cost">
                                            {/* <span>Amort Cost({item?.detail})</span> */}
                                            <span className="greenColoredCost">${parseFloat(this.state.totalEstCost) + parseFloat(item?.est_cost?.replaceAll('$', '')?.replaceAll(',', ''))}</span>
                                            <span className="lgText">${parseFloat(this.state.totalLockedCost) + parseFloat(item?.locked_cost)}</span>
                                            <span className="lgText">${parseFloat(this.state.totalEfc) + parseFloat(item?.efc)}</span>
                                        </div>
                                    </MDBCol>
                                </MDBRow>) :
                                <MDBRow>
                                    <MDBCol md={8}></MDBCol>
                                    <MDBCol md={4} className="float-r">
                                        <BasicLabel text={"Amort Cost"} />
                                        <div className="display-Cost">
                                            {/* <span>Amort Cost</span> */}
                                            <span>${this.state.totalEstCost}</span>
                                            <span>${this.state.totalLockedCost}</span>
                                            <span>${this.state.totalEfc}</span>
                                        </div>
                                    </MDBCol>
                                </MDBRow> : null
                            }
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </div>
        )
    }
}

export default withUserContext(Amort);
