import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelectField from "../../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../../components/SharedComponents/SearchSelectField";
import BasicLabel from "../../../../components/SharedComponents/BasicLabel/BasicLabel";
import { initialCueSheetConfig,publisherConfig } from './Config';
import './SyncLicensor.scss';
import * as Constants from '../../../../constants/constants';
import ClearTrackService from '../../../../services/service';
import { withUserContext } from '../../../../contexts/UserContext';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditCueSheetPublisherModal from './EditCueSheetPublisherModal';
import Popover from '@material-ui/core/Popover';
import NotificationComponent from "../../../../Common/NotificationComponent/NotificationComponent";
import messages from "../../../../Common/Messages.json"
import SearchFieldWithAddValue from '../../../../components/SharedComponents/SearchFieldWithAddValue'; 


class EditCueSheetPublisherInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            publisherOption: [],
            affiliationOption: [],
            cueSheetPublisherInfoDetails: this.formatPublisherList(JSON.parse(JSON.stringify({ ...initialCueSheetConfig }))),
            cueSheetPublisherRecord: this.formatPublisherList(JSON.parse(JSON.stringify({ ...initialCueSheetConfig }))),
            postInitiated: false,
            songId: null,
            licensorId:null,
            setModal: null,
            EditPublisherValue: null,
            EditPublisherName: null,
            showError: false,
            showSuccess: false,
            showFail: false,
            unsavedTitle: "Unsaved Changes",
            fieldChanged: false,
            failMessage: "Saving changes failed!",
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
        };
    };

    componentDidMount() {
        if (this.props.lic.cue_sheet_publishers?.length > 0) {
            this.getCueSheetDetails();
        }
        this.getStaticData("affiliationOption", "lu_affiliation");
        this.getStaticData("publisherOption", "lu_cue_sheet_publisher");
       
    }

    formatPublisherList = (response) => {
        if (response.cue_sheet_publishers?.length === 0) {
            response.cue_sheet_publishers.push(JSON.parse(JSON.stringify({ ...publisherConfig })))
            return response;
        }
    }

    getCueSheetDetails = () => {
        let data = { ...this.state.cueSheetPublisherInfoDetails }
        data.song_id = this.props.selectedSong?.song_id
        data.licensor_id = this.props.lic.licensor_id
        data.cue_sheet_publishers = this.props.lic.cue_sheet_publishers?.filter(item => item[this.props?.isMainTitle ? 'main_title_song_id' : 'clearance_song_id'] === this.props?.selectedSong[this.props?.isMainTitle ? 'main_title_song_id' : 'clearance_song_id']);
        this.setState({ cueSheetPublisherInfoDetails: data }, () => { 
            this.setState({ cueSheetPublisherRecord: this.state.cueSheetPublisherInfoDetails })
        })
    }

    getStaticData = (optionType, tableName) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/staticData?staticTable=${tableName}`, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let list = [];
                if (tableName === "lu_affiliation") {
                    list = response?.data?.map(item => ({
                        label: item.name,
                        value: item.id,
                        is_active: item?.is_active,
                    }));
                } else if (tableName === "lu_cue_sheet_publisher") {
                    list = response?.data?.map(item => ({
                        text: item.name,
                        value: item.id,
                    }));
                }
                this.setState({ [optionType]: list, publisherOptionCopy:tableName === "lu_cue_sheet_publisher" ? list : [] })
            })
    }

    getEntity = (entity, entityType, searchString) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entityType}&searchString=${searchString}`, this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                if (entityType === "CUE_SHEET_PUBLISHER") {
                    let publishers = response?.data?.map(item => ({
                        text: item.cue_sheet_publisher_name,
                        value: item.cue_sheet_publisher_id,
                    }));
                    this.setState({ [entity]: publishers })
                }
            })
    }

    handleChange = (field, value) => {
        if (field === "is_closed" ) {
            this.setState({
                cueSheetPublisherInfoDetails: {
                    ...this.state.cueSheetPublisherInfoDetails.cue_sheet_publishers,
                    [field]: value ? 1 : 0,
                },
            },()=>{this.setState({cueSheetPublisherRecord:this.state.cueSheetPublisherInfoDetails})});
        }
        else {
            this.setState(prevState => ({
                cueSheetPublisherInfoDetails: {
                    ...prevState.cueSheetPublisherInfoDetails,
                    [field]: value
                },
                postInitiated: false
            },()=>{ this.setState({cueSheetPublisherRecord:this.state.cueSheetPublisherInfoDetails})}), () => {
                this.props?.fieldChanged(true, "change");
            })
            
        }
       
    }
    
    removeRecord(index) {
        let data = JSON.parse(JSON.stringify(this.state.cueSheetPublisherInfoDetails))
        let value = JSON.parse(JSON.stringify(this.state.cueSheetPublisherRecord))
        data.cue_sheet_publishers.splice(index, 1)
        value.cue_sheet_publishers[index]['is_delete']= 1;
        this.setState({
            cueSheetPublisherInfoDetails: data, cueSheetPublisherRecord: value
        })
        this.props?.fieldChanged(true, "change")
    }

    addRecord() {
        let data = JSON.parse(JSON.stringify(this.state.cueSheetPublisherInfoDetails))
        data.cue_sheet_publishers.push(JSON.parse(JSON.stringify({ ...publisherConfig })))
        this.setState({
            cueSheetPublisherInfoDetails: data
        },()=>{ this.setState({cueSheetPublisherRecord:this.state.cueSheetPublisherInfoDetails})})
        this.props?.fieldChanged(true, "change")
    }
    validateFields = (data) => {
        let canSubmit = false;
         data?.cue_sheet_publishers?.find((item) => {
            canSubmit = item?.cue_sheet_publisher_name 
        })
        return canSubmit;
    }
    handleSubmit = () => {
        this.setState({ postInitiated: true })
        let postData = { ...this.state.cueSheetPublisherRecord }
        if (this.validateFields(postData)) {

            // postData.cue_sheet_publishers[this.state.cueSheetpublisherIndex]['cue_sheet_publisher_id']=this.state.cueSheetPublisherRecord.cue_sheet_publishers.find((item) => item.is_licensor === 1) ? this.props?.lic?.licensor_id : this.state.cueSheetPublisherInfoDetails.cue_sheet_publishers.cue_sheet_publisher_id;
            postData.song_id = this.props.selectedSong?.song_id // this.props.licensorsInfo?.[0]?.songs_list[0]?.song_id;
            postData.licensor_id = this.props?.lic?.licensor_id,
            this.setState({ isPosting: true })
                ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
                    `/clearanceLicensorsCueSheetPublishers`, postData, this.props.userContext?.active_tenant?.tenant_id, this.props?.divisionId)
                    .then(response => {
                        if (response) {
                            if (response.data.error) {
                                this.setState({ isPosting: false,postInitiated:false })
                                this.props?.notificationComponent(true, 'fail')
                            } else {
                                this.props?.toggleRefreshCueSheetPublisherDetails();
                                this.setState({ isPosting: false,postInitiated:false })
                                this.props?.notificationComponent(true, 'success')
                            }
                        }
                    })
        }
    }

    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccessMessage: value });
            case "fail": return this.setState({ showFailMessage: value });
        }
    }

    handleResetNotify = () => {
        this.setState({ showFailMessage: false, showSuccessMessage: false });
        if (this.state.saveFlag) {
          this.closeToastMessage();
        }
      }
      closeToastMessage = () => {
        this.setState({ showErrorMessage: false, showFailMessage: false, showSuccessMessage: false });
        if (this.state.saveFlag) {
          this.props?.handleClose();
        }
    }

    handleOnChange = (fieldArray, field, value, index, type = null) => {
        let publishers =JSON.parse(JSON.stringify(this.state.cueSheetPublisherInfoDetails));
        if (field === "cue_sheet_publisher" && value === null) {
            publishers.cue_sheet_publishers[index]['cue_sheet_publisher_id'] = null
            publishers.cue_sheet_publishers[index]['cue_sheet_publisher_name'] = null
            publishers.cue_sheet_publishers[index]['affiliation_id'] = null
            publishers.cue_sheet_publishers[index]['share'] = null
            publishers.cue_sheet_publishers[index]['is_licensor'] = null
            publishers.cue_sheet_publishers[index]['is_active'] = null
        }
        if (field === "cue_sheet_publisher" && value?.optionType == 'new') {
            this.postPublisher(value?.value, index)
        } else if (field === "cue_sheet_publisher") {
            publishers.cue_sheet_publishers[index][field + '_id'] = value?.value || null;
            publishers.cue_sheet_publishers[index][field + '_name'] = value?.text || null;
        }
        else if (field === "is_closed") {
            publishers.cue_sheet_publishers[index][field] = value ? 1 : 0 || null;
            publishers.cue_sheet_publishers[index]['cue_sheet_publisher_id'] = this.props?.lic?.licensor_id || null;
            publishers.cue_sheet_publishers[index]['cue_sheet_publisher_name'] = this.props?.lic?.licensor_name || null;
        } else if (field === "cue_sheet_publisher" && type === 'update') {
            publishers.cue_sheet_publishers?.map(item => {
                if(item.cue_sheet_publisher_id === value?.value) {
                    item.cue_sheet_publisher_name = value?.text
                    return item;
                }
            })
        }
        else if(field === "is_licensor"){
            publishers.cue_sheet_publishers[index][field] = value ? 1 : 0 || null;
            if(value){
                let isExist =  this.state?.publisherOption?.findIndex(x=>x.text == this.props?.lic?.licensor_name);
                if(isExist != -1){
                    publishers.cue_sheet_publishers[index]['cue_sheet_publisher_id'] = this.state?.publisherOptionCopy?.[isExist]?.value || null;
                    publishers.cue_sheet_publishers[index]['cue_sheet_publisher_name'] = this.state?.publisherOptionCopy?.[isExist]?.text || null;
                }
                else {
                    this.postPublisher(this.props?.lic?.licensor_name,index);
                }
            }
           else{
                    publishers.cue_sheet_publishers[index]['cue_sheet_publisher_id'] = null;
                    publishers.cue_sheet_publishers[index]['cue_sheet_publisher_name'] = null;
           }
        }        
        else {
            publishers.cue_sheet_publishers[index][field] = value || null;
        }
        this.setState({
            cueSheetPublisherInfoDetails: publishers,
            postInitiated: false,
        }, () => {
            this.setState({ cueSheetPublisherRecord:this.state.cueSheetPublisherInfoDetails, publisherOption: [] })
        })
    };
    postPublisher(licensorName,index){
        let cuesheetPublisherData = { id:null,name:licensorName, is_active: 1, table_name: "lu_cue_sheet_publisher" };
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/staticData`,
        cuesheetPublisherData,
        this.props?.userContext?.active_tenant?.tenant_id)
        .then(
            (response) => {
                if (response.data.error) {
                    this.props?.notificationComponent(true, 'fail')
                    // this.setState({ showLoading: false})
                } else {                  
                    // this.props.notificationComponent(true, "success");
                    let publishers =JSON.parse(JSON.stringify(this.state.cueSheetPublisherInfoDetails));
                    publishers.cue_sheet_publishers[index]['cue_sheet_publisher_id'] = response.data?.[0]?.id || null;
                    publishers.cue_sheet_publishers[index]['cue_sheet_publisher_name'] = licensorName || null;
                    this.setState({
                        cueSheetPublisherInfoDetails: publishers,
                        postInitiated: false,
                    }, () => {
                        this.setState({ cueSheetPublisherRecord:this.state.cueSheetPublisherInfoDetails, publisherOption: [] })
                    })
                }
               
            },
            (err) => {
                
                this.props?.notificationComponent(true, 'fail')
            }
        )

    }
    handleClickModal = (event, index) => {
        let cueSheetPublisherInfoDetails = {...this.state.cueSheetPublisherInfoDetails}
        cueSheetPublisherInfoDetails.cue_sheet_publishers[index]['is_editing'] = true;
        this.setState({
            setModal: event.currentTarget,
            cueSheetPublisherInfoDetails: cueSheetPublisherInfoDetails
        })
    };

    handleCloseModal = () => {
        let cueSheetPublisherInfoDetails = {...this.state.cueSheetPublisherInfoDetails}
        cueSheetPublisherInfoDetails.cue_sheet_publishers?.map(item => {
            item['is_editing'] = false;
            return item;
        })
        this.setState({
            setModal: null,
            cueSheetPublisherInfoDetails: cueSheetPublisherInfoDetails
        })
    };

    render() {
        let openModal = Boolean(this.state.setModal);
        let idModal = open ? 'modal-popover' : undefined;
        let severity = this.state.showFailMessage ? "error" : this.state.showSuccessMessage ? "success" : "";
        let messageNotification = this.state.showSuccessMessage ? "Saved Changes Successfully" : this.state.showFailMessage ? "Saved Changes Failed" : "";
        //success modal popup
        let message = this.state.showFail ? this.state.failMessage :
        this.state.showSuccess ? this.state.saveEditMessage : null
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
        this.state.showSuccess ? this.state.saveTitle : "";
        return (
            <MDBContainer className='editCueSheetPublisherInfo'>
                <NotificationComponent 
                    open={this.state.showSuccessMessage || this.state.showFailMessage} 
                    message={messageNotification} 
                    severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} 
                />
                <MDBRow className="small-font">
                    {this.props.licensorsInfo.map((licensor) =>
                        <span className='f7'> The song[s] {licensor.songs_list[0]?.song_title} by {licensor.songs_list[0]?.performer_name}</span>
                    )}
                </MDBRow>
                <MDBRow>
                            <MDBCol md={5}>
                                    <BasicLabel 
                                        text="Publisher Name" 
                                        isMandatory={true}
                                    />
                                </MDBCol>
                                <MDBCol md={1}></MDBCol>
                                <MDBCol md={3}>
                                    <BasicLabel 
                                        text="Affiliation" 
                                        // isMandatory={true}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicLabel 
                                        text="Share" 
                                        //isMandatory={true}
                                    />
                                </MDBCol>
                </MDBRow>
                {this.state.cueSheetPublisherInfoDetails?.cue_sheet_publishers?.map((publishers, index) => (
                    <>
                        <MDBRow key={'publisher' + index}>
                            <MDBCol md={5} id="Publisher">
                                {/* {publishers.is_licensor === 1 ? 
                                    <BasicLabel 
                                        text={this.props?.lic?.licensor_name} 
                                    /> : */}
                                    <>
                                        <SearchFieldWithAddValue
                                            id={"publisher-select"}
                                            options={this.state.publisherOption}
                                            placeholder={"- Search -"}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            detail_selected={ publishers.cue_sheet_publisher_name }
                                            onChange={(e, value) => this.handleOnChange('publishers', 'cue_sheet_publisher', value, index)}
                                            searchText={(e, value) => {
                                                if (e.target.value !== "" || e.target.value !== null) {
                                                    this.getEntity('publisherOption',"CUE_SHEET_PUBLISHER",e.target.value)
                                                    }
                                            }}
                                            searchSelect={true}
                                            newLabel={'Publisher'}
                                            // loading={this.state.loading}
                                        />
                                    </>
                                {/* } */}
                            </MDBCol>
                            <MDBCol 
                                key={'edit-publisher' + index} 
                                md={1} 
                                id="pen-btn" 
                                disabled={publishers.cue_sheet_publisher_id !== null ? false : true}
                            >
                                <BasicButton
                                    className={"pen-btn"}
                                    variant="contained"
                                    text=" "
                                    icon={"pen"}
                                    onClick={(e) => this.handleClickModal(e, index)}
                                />
                                <Popover
                                    id={idModal}
                                    open={openModal}
                                    className={"pop-over"}
                                    anchorEl={this.state.setModal}
                                    onClose={this.handleCloseModal}
                                    anchorReference="anchorOrigin"
                                    anchorOrigin={{
                                        horizontal: "right",
                                        vertical: "bottom "
                                    }}
                                    transformOrigin={{
                                        horizontal: "left",
                                        vertical: "top"
                                    }}
                                    PaperProps={{ style: { width: '16%', backgroundColor: '#e6e6e6' } }}
                                    anchorPosition={{ top: 700, left: 300 }}
                                >
                                    <EditCueSheetPublisherModal 
                                        notificationComponent={this.notificationComponent}
                                        handleOnChange={this.handleOnChange}
                                        handleCloseModal={this.handleCloseModal}
                                        cueSheetPublisherInfoDetails={this.state.cueSheetPublisherInfoDetails}
                                        index={index}
                                    />
                                </Popover>
                            </MDBCol>
                            <MDBCol md={3} id="Affiliation">
                                <SelectField
                                    // label={`${index === 0 ? "Affiliation" : ""}`}
                                    // isMandatory={true}
                                    // showMandatory={this.state.postInitiated}
                                    // mandatoryWarning={messages.mandatoryWarning}
                                    placeHolderText={"- Select - "}
                                    value={publishers.affiliation_id || null}
                                    options={this.state.affiliationOption || []}
                                    onChange={(e, value) => this.handleOnChange('publishers', 'affiliation_id', e.target.value, index)} />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicTextField
                                    // label={`${index === 0 ? "Share" : ""}`}
                                    // isMandatory={true}
                                    // showMandatory={this.state.postInitiated}
                                    mandatoryWarning={messages.mandatoryWarning}
                                    value={publishers.share}
                                    onChange={(e, value) => this.handleOnChange('publishers', 'share', e.target.value, index)} />
                            </MDBCol>
                            <MDBCol md={1}
                            >
                                <div className="add-remove-icons">
                                    <div>
                                        {!(this.state.cueSheetPublisherInfoDetails.cue_sheet_publishers.length === 1)
                                            ? <RemoveCircleIcon fontSize="small" onClick={() => this.removeRecord(index)} />
                                            : null
                                        }
                                    </div>
                                    <div className="ml5">
                                        {this.state.cueSheetPublisherInfoDetails.cue_sheet_publishers.length- 1 === index && (publishers.cue_sheet_publisher_id != null || publishers.cue_sheet_publisher_name != null || publishers.cue_sheet_publisher_name != "") ?
                                            <AddCircleIcon fontSize="small" onClick={() => this.addRecord(index)} />
                                            : null
                                        }
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className='mt-2'>
                            <MDBCol md={9}>
                                <BasicCheckbox
                                    label="Cue Sheet Publisher name is same as Licensor"
                                    id={`"-publisher_name"${index}`}
                                    disabled={this.props.disabled || false}
                                    checked={publishers?.is_licensor}
                                    onChange={(e, value) => this.handleOnChange('publishers', 'is_licensor', e.target.checked, index)} />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicCheckbox
                                    label="Inactive"
                                    id={`Inactive-${index}`}
                                    disabled={this.props.disabled || false}
                                    checked={publishers?.is_active}
                                    onChange={(e, value) => this.handleOnChange('publishers', 'is_active', e.target.checked?1:0, index)} 
                                    align="end" />
                            </MDBCol>
                        </MDBRow>
                    </>
                ))} 
                <MDBRow>
                    <MDBCol md={8}></MDBCol>
                    <MDBCol md={4}>
                        <MDBRow>
                            <MDBCol>
                                <BasicButton 
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={18} /> : "Save"}
                                    onClick={this.handleSubmit} 
                                />
                            </MDBCol>
                            <MDBCol>
                                <BasicButton onClick={() => this.props?.handleClose()} variant="outlined" text={"Cancel"} />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default withUserContext(EditCueSheetPublisherInfo);
