import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import "./MixDateReport.scss";
import TableComponent from "../../../components/SharedComponents/Table";
import {
  agentListConfig,
  allContactList,
  reportConfig,
  allreportList,
  initialBudgetEFCDetails
} from "../Reports/config";
import Radio from "@material-ui/core/Radio";
import DateField from "../../../components/SharedComponents/DateField/DateField";
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';
import ShowSeason from "./ShowSeason";
import Chip from "@material-ui/core/Chip";
import { s2ab, genReportName } from '../../../Common/Helper';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { withUserContext } from '../../../contexts/UserContext';

class BudgetVsEFC extends Component {
  constructor() {
    super();
    this.state = {
      postInitiated: false,
      renderList: allContactList,
      config: agentListConfig,
      renderList1: allreportList,
      config1: reportConfig,
      budgetEFCDetails: initialBudgetEFCDetails,
      showDetailsOption: [],
      showRecipientOption: [],
      seasonOptions: [],
      showOptions: [],
      filterValues: [],
      removedItem: null,
      seasonIdValues: [],
      showIdValues: [],
      found: [],
      isPosting: false,
      recipientOptions: [],
      isExcelPosting: false,
      showRecipientsError: false,
      showSeasonValidation: false,
      latestShowOptions: []
    };
  }

  componentDidMount() {
    this.getRecipientsList();
    // this.setState((prevState) => ({
    //   budgetEFCDetails: {
    //     ...prevState.budgetEFCDetails,
    //     ["selected_all_list"]: "all_list",
    //     ["selected_choose_recipients"]: "default_recipients"
    //   },
    // }))
  }

  getRecipientsList = () => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=BUDGET_VS_EFC_RECIPIENT&searchString=null`,
      this.props?.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.season_budget_vs_efc_recipients_id, text: item.name }));
        this.setState({ recipientOptions: formattedList }, () => { console.log(this.state.recipientOptions) });
      },
        (err) => {
          console.log("Error in fetching Address Types:", err);
          this.setState({ isFetchingClearanceDetails: false });
        })
  }

  handleSubmitBudgetVsEFCReport = () => {
    this.setState({ postInitiated: true });
    let { budgetEFCDetails, filterValues, seasonIdValues, showIdValues } = this.state;
    // if (!mixDateDetails.mix_date_range_form || !mixDateDetails.mix_date_range_to) {
    //   return;
    // }
    this.setState({ isPosting: true })
    let payload = {};
    let reportName = "";
    let reportDownloadName = "";
    if (budgetEFCDetails.is_condensed_report === true) {
      payload = {
        show_ids: showIdValues,
        season_id: seasonIdValues.join(),
        show_variance: budgetEFCDetails.is_hide_variance ? null : 1,
        show_negotiated: budgetEFCDetails.is_negotiated_fees ? 1 : 0,
        all_shows: showIdValues.length === 0 ? 1 : 0,
        exclude_main_title: budgetEFCDetails?.is_exclude_main_title ? 1 : 0,
        is_warner_horizon: budgetEFCDetails.is_warner_horizan_shows ? 1 : 0,
        show_all_programs: budgetEFCDetails.is_all_shows ? 1 : 0
      }
      reportName = "rptBudgetVsEfcCondensed";
      reportDownloadName = "BudgetVSEFC_Condensed"
    } else if (budgetEFCDetails.is_costs === true) {
      payload = {
        show_ids: showIdValues,
        season_id: seasonIdValues.join(),
        show_negotiated: budgetEFCDetails.is_negotiated_fees ? 1 : 0,
        all_shows: showIdValues.length === 0 ? 1 : 0,
        exclude_main_title: budgetEFCDetails?.is_exclude_main_title ? 1 : 0,
        only_4646: budgetEFCDetails.is_costs ? 1 : 0,
        is_warner_horizon: budgetEFCDetails.is_warner_horizan_shows ? 1 : 0,
        show_all_programs: budgetEFCDetails.is_all_shows ? 1 : 0
      }
      reportName = "rptBudgetVsEfcOnly4646";
      reportDownloadName = "BudgetVSEFC_Only4646"
    } else {
      payload = {
        show_ids: showIdValues,
        season_id: seasonIdValues.join(),
        all_shows: showIdValues.length === 0 ? 1 : 0,
        exclude_main_title: budgetEFCDetails?.is_exclude_main_title ? 1 : 0,
        is_warner_horizon: budgetEFCDetails.is_warner_horizan_shows ? 1 : 0,
        show_all_programs: budgetEFCDetails.is_all_shows ? 1 : 0
      },
        reportName = this.props?.reportName;
      reportDownloadName = "BudgetVSEFC"
    }
    this.props.generateReport(reportName, payload, reportDownloadName).then(webSocketConn => {
      webSocketConn.onmessage = (e) => {
        let response = JSON.parse(e.data)
        if (response?.statusCode == 200) {
          this.setState({ isPosting: false })
          this.props?.downloadUrl(response?.body);
          // this.props?.notificationComponent(true, 'success')
        } else {
          this.setState({ isPosting: false })
          // this.props?.notificationComponent(true, 'fail')
        }
      }
    })
  }

  handleClearFilters = () => {
    let values = this.state.filterValues;
    values = []
    this.setState({ filterValues: values, budgetEFCDetails: {...initialBudgetEFCDetails} });
  }

  onChangeCallBack = (field, val, eventValue, data) => {
    let newValue = val.filter(
      (value, index, arr) =>
        index === arr.findIndex((item) => item.value === value.value)
    );
    let modifiedValue = newValue?.map((item) => ({
      value: item.value,
      text: item.text,
    }));
    if (field === "selected_recipients_list") {
      this.handleChange("selected_recipients_list", modifiedValue);
    }
  }

  handleChange = (field, value) => {
    if (field === 'is_all_shows') {
      console.log("test")
    }
    this.setState((prevState) => ({
      budgetEFCDetails: {
        ...prevState.budgetEFCDetails,
        [field]: value,
      },
    }), () => {
      if (field === 'is_condensed_report') {
        this.setState((prevState) => ({
          budgetEFCDetails: {
            ...prevState.budgetEFCDetails,
            ["is_hide_variance"]: true,
          }
        }))
      }
    });
  };

  handleOpenBudgetEFCReport = () => {
    this.setState({ showBudgetEFC: true })
  }

  handleFilterValues = (filterValues) => {
    let values = this.state.filterValues;
    values = filterValues
    this.setState({
      filterValues: values
    })
  }

  seasonValues = (seasonValue) => {
    this.setState({ found: seasonValue })
  }

  showValues = (showValue) => {
    let backup = this.state.showOptions;
    backup = backup?.concat(showValue)
    this.setState({ showOptions: backup, latestShowOptions: showValue }, () => {
      console.log("latestShowOptions", this.state.latestShowOptions)
    })
  }

  handleFilterDelete = (removeNode, index) => {
    let filterValues = this.state.filterValues;
    let removedItem = filterValues.splice(index, 1);
    this.setState({ filterValues: filterValues, removedItem: removeNode }, () => {
      let splitVal = this.state.removedItem.split(":");
      let name = splitVal[0].trim();
      let valueId = splitVal[1].trim();
      if (name === "Season") {
        let filterSeasonIdValues = this.state.found.find(item => item.label === valueId)?.value
        let index2 = this.state.seasonIdValues.indexOf(filterSeasonIdValues)
        let id = this.state.seasonIdValues
        id.splice(index2, 1);
        this.setState({ seasonIdValues: id })
      }
      else if (name === 'Show') {
        let filterSeasonIdValues = this.state.showOptions.find(item => item?.text === valueId)?.value
        let index2 = this.state.showIdValues.indexOf(filterSeasonIdValues)
        let id = this.state.showIdValues
        id.splice(index2, 1);
        this.setState({ showIdValues: id })
      }
    });
  }

  downloadExcel = () => {
    this.setState({ isExcelPosting: true })
    let { budgetEFCDetails, filterValues, seasonIdValues, showIdValues, showOptions, latestShowOptions } = this.state;
    let latestShowOptionsIds = latestShowOptions.map(item => item.value)
    if(seasonIdValues.length === 0){
      this.setState({ isExcelPosting: false })
      this.setState({ showSeasonValidation: true }, () =>{
        setTimeout(() => { this.setState({ showSeasonValidation: false }) }, 5000)
      })
      return;
    }
    if (showIdValues.length === 0) {
      if (latestShowOptions.length === 0) {
        this.setState({ isExcelPosting: false })
        this.setState({ showErrorValidation: true }, () => {
          setTimeout(() => { this.setState({ showErrorValidation: false }) }, 5000)
        })
        return;
      }
    }
    if(budgetEFCDetails.selected_all_list === "recipients_list"){
      if(budgetEFCDetails.selected_recipients_list?.length === 0){
        this.setState({ isExcelPosting: false })
        this.setState({ showRecipientsError: true}, () => {
          setTimeout(() => { this.setState({ showRecipientsError: false }) }, 5000)
        })
        return;
      }
    }
    if(budgetEFCDetails.selected_all_list === "from_shows"){
      if(showIdValues?.length === 0){
        this.setState({ isExcelPosting: false })
        this.setState({ showSelectedShowError: true},() => {
          setTimeout(() => { this.setState({ showSelectedShowError: false }) }, 5000)
        })
        return;
      }
    }
    let reportName = "rptbudgetvsefcexl"
    if (budgetEFCDetails.is_costs === true) {
      reportName = "rptBudgetVsEfcOnly4646Exl"
    } else if (budgetEFCDetails.is_condensed_report === true) {
      reportName = "rptBudgetVsEfcCondensedExl"
    }
    let selected_recipient_list_formatted = budgetEFCDetails.selected_recipients_list?.map(item => item.value)
  
    let allShows = showIdValues.length !== 0 ? showIdValues : this.state.latestShowOptions.length !== 0 ? latestShowOptionsIds : null
    allShows?.map(item => {
      let searchPayload = {
        "show_ids": item,
        "season_id": seasonIdValues?.join(),
        "recipient_ids": selected_recipient_list_formatted,
        "all_distribution": budgetEFCDetails?.selected_all_list === "all_list" ? 1 : 0,
        "from_recipient_list": budgetEFCDetails?.selected_all_list === "recipients_list" ? 1 : 0,
        "from_shows": budgetEFCDetails?.selected_all_list === "from_shows" ? 1 : 0,
        "default_recipients_flag": budgetEFCDetails?.selected_choose_recipients === "default_recipients" ? 1 : 0,
        "exclude_main_title": budgetEFCDetails?.is_exclude_main_title ? 1 : 0,
        "show_variance": budgetEFCDetails.is_hide_variance ? 0 : 1,
        "only_4646": budgetEFCDetails.is_costs ? 1 : 0,
        "is_warner_horizon": budgetEFCDetails.is_warner_horizan_shows ? 1 : 0,
        "show_all_programs": budgetEFCDetails.is_all_shows ? 1 : 0
      }
      this.generateEmails(reportName, searchPayload, "isExcel").then(webSocketConn => {
        webSocketConn.onmessage = (e) => {
          let response = JSON.parse(e.data)
          if (response?.statusCode == 200) {
            if (response?.body === "Emails have been saved to your Drafts folder.") {
              this.setState({ isExcelPosting: false })
              // this.downloadUrl(response?.body);
              this.props?.notificationComponent(true, 'emailSaved')
            } else {
              this.setState({ isExcelPosting: false })
              // this.props?.notificationComponent(true, 'fail')
            }
          } else {
            this.setState({ isExcelPosting: false })
            // this.props?.notificationComponent(true, 'fail')
          }
        }
      })
    })
  }

  downloadUrl = (url) => {
    if (url) {
      fetch(url).then(response => response.blob()).then(blob => {
        const link = document.createElement('a');
        // link.href = window.URL.createObjectURL(blob);
        link.href = url;
        // link.setAttribute('download', reportName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
    }
  }

  // notificationComponent = (value, mode = "fail") => {
  //   switch (mode) {
  //     case "success": return this.setState({ showSuccess: value });
  //     case "fail": return this.setState({ showFail: value });
  //   }
  // }


  generateEmails = (reportName, searchPayload, fileName) => {
    if (!searchPayload || !reportName) {
      return;
    }
    Object.keys(searchPayload).map(obj => {
      if (searchPayload[obj] === undefined) {
        searchPayload[obj] = null;
      }
    })
    let encryptTedStr = btoa(JSON.stringify(searchPayload));
    let load = {
      'payload': 'exportExcel',
      'reportName': reportName,
      'fileName': "BudgetVSEFC",
      'searchJson': encryptTedStr,
      'showHtml': 0,
      'encryptionType': 1,
      'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
      'divisionId': 1,
    }
    return clearTrackService.getDataWS(load)
  }



  GenerateCleranceDetails = () => { };
  render() {
    return (
      <>
        <MDBRow>
          <MDBCol md={2}>
            <p>
              <b>Budget vs. EFC Report</b>
            </p>
            <MDBRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
              <div style={{ display: "flex" }}>
                {this.state.filterValues &&
                  this.state.filterValues?.map((item, index) => (
                    <Chip
                      label={item}
                      onDelete={this.handleFilterDelete.bind(
                        this,
                        item,
                        index
                      )}
                    // filterValues={this.state.filterValues}
                    />
                  ))}
              </div>
            </MDBRow>
          </MDBCol>
          <MDBCol md={1}>
            <BasicButton variant="outlined"
              text={"Schedule"}
              onClick={this.handleOpenBudgetEFCReport}
            />
          </MDBCol>
        </MDBRow>
        <MDBContainer
          style={{ background: "white" }}
          fluid
          className="MixDate-Container"
        >
          <MDBRow>
            <MDBCol md={2}>
              <br />
              <BasicCheckbox
                id="Condensed Report"
                label={"Condensed Report"}
                checked={this.state.budgetEFCDetails?.is_condensed_report || false}
                onChange={(e) => this.handleChange('is_condensed_report', e.target.checked)}
              />
              {this.state.budgetEFCDetails?.is_condensed_report ?
                <BasicCheckbox
                  id="Hide Variance"
                  label={"Hide Variance"}
                  checked={this.state.budgetEFCDetails?.is_hide_variance || false}
                  onChange={(e) => this.handleChange('is_hide_variance', e.target.checked)}
                />
                :
                <BasicCheckbox
                  id="Show Only 4646 Costs"
                  label={"Show Only 4646 Costs"}
                  checked={this.state.budgetEFCDetails?.is_costs || false}
                  onChange={(e) => this.handleChange('is_costs', e.target.checked)}
                />
              }
              <BasicCheckbox
                id="Exclude Main Title"
                label={"Exclude Main Title"}
                checked={this.state.budgetEFCDetails?.is_exclude_main_title || false}
                onChange={(e) => this.handleChange('is_exclude_main_title', e.target.checked)}
              />
              <BasicCheckbox
                id="Always show negotiated fees, even for negotiated episodes"
                label={
                  "Always show negotiated fees, even for negotiated episodes"
                }
                checked={this.state.budgetEFCDetails?.is_negotiated_fees || false}
                onChange={(e) => this.handleChange('is_negotiated_fees', e.target.checked)}
              />
            </MDBCol>
            <MDBCol md={4}>
              <MDBRow className="checkBoxes-ShowSeason">
                <MDBCol md={5}>
                  <BasicCheckbox
                    id="Warner Horizon Shows"
                    label={"Warner Horizon Shows"}
                    checked={this.state.budgetEFCDetails?.is_warner_horizan_shows || false}
                    onChange={(e) => this.handleChange('is_warner_horizan_shows', e.target.checked)}
                  />
                </MDBCol>
                <MDBCol md={7}>
                  <BasicCheckbox
                    id="All Shows CheckBox"
                    label={"All Shows"}
                    checked={this.state.budgetEFCDetails?.is_all_shows || false}
                    onChange={(e) => this.handleChange('is_all_shows', e.target.checked)}
                  />
                </MDBCol>
              </MDBRow>
              <br />
              <MDBRow className="showSeasonRow">
                <ShowSeason
                  onChangeCallBack={this.handleFilterValues}
                  removedItem={this.state.removedItem}
                  // setSeasonId={this.setSeasonId}
                  // setShowId={this.setShowId}
                  filterSeasonIdValues={this.state.seasonIdValues}
                  filterShowIdValues={this.state.showIdValues}
                  filterValues={this.state.filterValues}
                  seasonValues={this.seasonValues}
                  showValues={this.showValues}
                  reportName={"BudgetVSEfc"}
                  is_warner_horizon={this.state.budgetEFCDetails?.is_warner_horizan_shows === true ? 1 : 0}
                  is_all_shows={this.state.budgetEFCDetails?.is_all_shows === true ? 1 : 0}
                  showErrorValidation={this.state.showErrorValidation}
                  showSelectedShowError={this.state.showSelectedShowError}
                  showSeasonValidation={this.state.showSeasonValidation}
                />
              </MDBRow>
              {/* <MDBRow>
                <MDBCol md={6}>
                  <BasicCheckbox
                    id="Warner Horizon Shows"
                    label={"Warner Horizon Shows"}
                    checked={this.state.budgetEFCDetails?.is_warner_horizan_shows || false}
                    onChange={(e) => this.handleChange('is_warner_horizan_shows', e.target.checked)}
                  />
                </MDBCol>
                <MDBCol md={6}>
                  <BasicCheckbox
                    id="All Shows CheckBox"
                    label={"All Shows"}
                    checked={this.state.budgetEFCDetails?.is_all_shows || false}
                    onChange={(e) => this.handleChange('is_all_shows', e.target.checked)}
                  />
                </MDBCol>
              </MDBRow> */}
            </MDBCol>
            <MDBCol md={3} className="emailDistribution">
              <BasicLabel text="Email Distribution" />
              <Radio name="radio-buttons" label="name"
                checked={this.state.budgetEFCDetails.selected_all_list === 'all_list'}
                onChange={e => this.handleChange('selected_all_list', e.target.value)}
                value="all_list"
              />
              <span style={{ fontSize: "10px" }}>All </span>
              <br />
              <Radio name="radio-buttons" label="name"
                checked={this.state.budgetEFCDetails.selected_all_list === 'recipients_list'}
                onChange={e => this.handleChange('selected_all_list', e.target.value)}
                value="recipients_list"
              />
              <span style={{ fontSize: "10px" }}>From Recipients List </span>
              <br />
              <Radio name="radio-buttons" label="name"
                checked={this.state.budgetEFCDetails.selected_all_list === 'from_shows'}
                onChange={e => this.handleChange('selected_all_list', e.target.value)}
                value="from_shows"
              />
              <span style={{ fontSize: "10px" }}>From Shows </span>
              <br />
              {this.state.budgetEFCDetails.selected_all_list === 'from_shows' ?
                <MDBRow>
                  <MDBCol>
                    <Radio name="radio-buttons" label="name"
                      checked={this.state.budgetEFCDetails.selected_choose_recipients === 'default_recipients'}
                      onChange={e => this.handleChange('selected_choose_recipients', e.target.value)}
                      value="default_recipients"
                    />
                    <span style={{ fontSize: "10px" }}>Default Recipients </span>
                  </MDBCol>
                  <br />
                  <MDBCol>
                    <Radio name="radio-buttons" label="name"
                      checked={this.state.budgetEFCDetails.selected_choose_recipients === 'choose_recipients'}
                      onChange={e => this.handleChange('selected_choose_recipients', e.target.value)}
                      value="choose_recipients"
                    />
                    <span style={{ fontSize: "10px" }}>Choose Recipients </span>
                  </MDBCol>
                </MDBRow>
                : null}
            </MDBCol>
            <MDBCol md={3}
              id="recipients" >
              <BasicLabel
                text={"Program selection (on left) has no effect when generating emails unless you select 'From Shows'"}
              />
              {/* <BasicLabel text="Shows" />
              <SearchSelectField
                id={"roles-select-shows"}
                placeHolderText={"All Shows in selected season(s)"}
                value={this.state.budgetEFCDetails.selected_shows_details}
                options={this.state.showDetailsOption || []}
                onChange={(e) => this.handleChange("show_details_id", e.target.value)}
              />
              <br /> */}
              {/* <BasicLabel text="Recipient List" /> */}
              <SearchSelectField
                label={"Recipient List"}
                width={"100%"}
                id={"recipient"}
                // disabled={
                //   this.state.licensorLetterData.userDefinedLanguage
                //     ? false
                //     : true
                // }
                multiple={true}
                placeHolderText="- Select -"
                value={
                  this.state.budgetEFCDetails.selected_recipients_list?.length > 0
                    ? this.state.budgetEFCDetails.selected_recipients_list?.map(
                      (item) => ({
                        value: item.value,
                        text: item.text,
                      })
                    )
                    : []
                }
                options={this.state.recipientOptions || []}
                onChange={(e, newValue) => {
                  this.onChangeCallBack(
                    "selected_recipients_list",
                    newValue,
                    e.target.value
                  );
                }}
              // <SearchSelectField
              //   id={"roles-select-recipient-list"}
              //   value={this.state.budgetEFCDetails.selected_recipient_list}
              //   options={this.state.recipientOptions || []}
              //   onChange={(e) => this.handleChange("recipient_id", e.target.value)}
              />
                <span className="errorText">{this.state.showRecipientsError && 'Choose atleast one recipient.'}</span>
              <MDBRow>
                <MDBCol md={6}></MDBCol>
                <MDBCol md={6}>
                  <br />
                  <BasicButton variant="outlined" className="generateEmailsButton" text={this.state.isExcelPosting ? <CircularProgress color="inherit" size={20} /> : "Generate Emails"}
                    onClick={this.downloadExcel} />
                  <br />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          {
            this.state.showBudgetEFC &&
            <MainViewModalComponent
              open={this.state.showBudgetEFC}
              handleClose={() => this.setState({ showBudgetEFC: false })}
              headerText={"Budget vs. EFC Schedule"}
              mode={"Both"}
              modalName={"Budget EFCReport"}
              // id={this.state.selectedLicensor}
              commonPermissions={this.props?.userContext?.active_tenant?.common_permissions}
            />
          }
        </MDBContainer>
        <MDBRow>
          <MDBCol md={9}></MDBCol>
          <MDBCol md={3}>
            <MDBRow>
              <MDBCol id="genpdf-btn">
                <br />
                <BasicButton className="efcBtn" text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate PDF"} onClick={this.handleSubmitBudgetVsEFCReport} />
              </MDBCol>
              <MDBCol>
                <br />
                <BasicButton variant="outlined" text={"Clear Filters"} onClick={this.handleClearFilters} />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </>
    );
  }
}

export default withUserContext(BudgetVsEFC);
