import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CircularProgress from '@material-ui/core/CircularProgress';
import { publisherConfig } from './config';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import messages from '../../../../Common/Messages.json';
import * as Constants from '../../../../constants/constants';
import ClearTrackService from '../../../../services/service';
import './Publisher.scss';
import axios from 'axios';
import { withUserContext } from '../../../../contexts/UserContext';
import {validateNumDotOnly } from "../../../../Common/Helper";

class Publisher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            publishers: this.formatPublisherList(),
            publisherOptions: [],
            affiliationOptions: [],
            publisherIndex: 2,
            postInitiated: false,
            searchCancelToken: null,
            isFetchingPublisher: false
        }
    }

    componentDidMount() {
        this.getStaticData('affiliationOptions', 'lu_affiliation');
    }

    formatPublisherList = () => {
        let publishers = [];
        if (publishers?.length === 0) {
            publishers.push(JSON.parse(JSON.stringify({ ...publisherConfig })));
        }
        return publishers;
    }

    getEntity = (entity, entityType, searchString) => {
        if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel("Operation canceled due to new request");
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken, isFetchingPublisher: true });
        ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entityType}&searchString=${searchString}`, cancelToken, this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                if (entityType === "CUE_SHEET_PUBLISHER") {
                    let publishers = response?.data?.map(item => ({
                        text: item.cue_sheet_publisher_name,
                        value: item.cue_sheet_publisher_id,
                    }));
                    this.setState({ [entity]: publishers, isFetchingPublisher: false})
                }
            })
    }

    getStaticData = (fieldName, dataType) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${dataType}`, null)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, text: item.name, is_active: item?.is_active }));
                this.setState({ [fieldName]: formattedList, });
            },
                (err) => {
                    console.log("Error in fetching Tenant Details:", err)
                })
    }

    canSubmit = (publishers) => {
        let canSubmit = (!publishers?.some(publisher => (!(publisher.publisher_id && publisher.affiliation_id && publisher.percentage) || false)))
        return canSubmit;
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true })
        let publishers = [...this.state.publishers];
        if (this.canSubmit(publishers)) {
            this.setState({ isPosting: true })
            let formattedPublisherNames = [];
            publishers?.map(item => {
                let data = null;
                if (/^\d+(\.\d+)?%$/.test(item.percentage)) {
                   data = item.percentage
                } else {
                    data = item.percentage + "%"
                }
                
                let publisherName = item.publisher_name + ' (' + item.affiliation_name + ') ' + data;
                formattedPublisherNames.push(publisherName);
            })
            let convertedPublisherName = formattedPublisherNames.join('\r\n');
            this.props?.handleNodeValue(convertedPublisherName);
            this.props.handleClose();
        }
    }

    handleOnChange = (fieldArray, field, value, index) => {
        let publishers = [...this.state.publishers];
        if (field === "publisher" ) {
            publishers[index][field + '_id'] = value?.value || null;
            publishers[index][field + '_name'] = value?.text || null;
            publishers[index]["selected_publisher"] = value;
        } else if (field === "affiliation") {
            publishers[index][field + '_id'] = value?.value || null;
            publishers[index][field + '_name'] = value?.text || null;
            publishers[index]["selected_affiliation"] = value;
        }
        else {
            publishers[index][field] = value || null;
        }
        this.setState({
            publishers: publishers,
            postInitiated: false
        }, () => {
            this.setState({ publisherOptions: [] })
        })
    };

    addAdditionalField = (listName) => {
        this.setState({ postInitiated: false });
        let initialPublisherArray = JSON.parse(JSON.stringify({ ...publisherConfig }));
        let publishers = [...this.state.publishers];
        initialPublisherArray.index = this.state.publisherIndex;
        initialPublisherArray.canRemove = true;
        publishers.push(initialPublisherArray)
        this.setState({ publisherIndex: this.state.publisherIndex + 1, publishers: publishers });
    }

    removeField = (listName, callbackItem, index) => {
        this.setState({
            listName: listName, callbackItem: callbackItem, postInitiated: false, index: index
        }, () => {
            let publishers = [...this.state.publishers];
            if (!callbackItem.canRemove) {
                let index = publishers.indexOf(callbackItem);
                if (index > -1) {
                    publishers?.splice(index, 1);
                }
            } else {
                let list = publishers?.filter(item => item.index !== callbackItem.index);
                publishers = list;
            }
            this.setState({ publishers: publishers });
        })
    }

    render() {
        return (
            <MDBContainer className="publisherContainer">
                {this.state.publishers?.map((publisher, index) =>
                    <MDBRow>
                        <MDBCol md={5} className="p-0 publisher">
                            <SearchSelectField
                                id={"publisher-select"}
                                label={index === 0 ? "Publisher" : ""}
                                options={this.state.publisherOptions}
                                placeholder={"- Search -"}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated}
                                mandatoryWarning={messages.mandatoryWarning}
                                multiple={false}
                                loading={this.state.isFetchingPublisher}
                                detail_selected={publisher.selected_publisher}
                                valueSelected={(e, value) => this.handleOnChange('publishers', 'publisher', value, index)}
                                searchText={(e, value) => {
                                    if (e.target.value !== "" && e.target.value !== null) {
                                        this.getEntity('publisherOptions', 'CUE_SHEET_PUBLISHER', e.target.value)
                                    }}}
                                searchSelect={true}
                               
                            />
                        </MDBCol>
                        <MDBCol md={4} className="p-0 affiliation">
                            <SearchSelectField
                                id={"affiliation-select"}
                                width={"100%"}
                                label={index === 0 ? "Affiliation" : ""}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated}
                                mandatoryWarning={messages.mandatoryWarning}
                                value={publisher.selected_affiliation || {}}
                                options={this.state.affiliationOptions || []}
                                placeholder={"- Select -"}
                                onChange={(e, value) => this.handleOnChange('publishers', 'affiliation', value, index)}
                            />
                        </MDBCol>
                        <MDBCol md={2} className="p-0 percentage">
                            <BasicTextField
                                id={"PctText"}
                                label={index === 0 ? "Pct." : ""}
                                placeholder={"__.__"}
                                width={"100%"}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated}
                                mandatoryWarning={messages.mandatoryWarning}
                                value={publisher.percentage || ""}
                                onChange={(e) =>validateNumDotOnly(e.target.value) ? this.handleOnChange('publishers', 'percentage', e.target.value, index):null}
                            />
                        </MDBCol>
                        <MDBCol md={1} className="p-0 actionIcons">
                            <MDBRow className={index === 0 ? "actionButons ml-1" : "ml-1"}>
                                <MDBCol md={1} className="actions iconWidth p-0">
                                    {
                                        !(this.state.publishers?.length === 1) ?
                                            <RemoveCircleIcon
                                                fontSize="small"
                                                onClick={(e) => this.removeField('publishers', publisher, index)}
                                            /> : null
                                    }
                                </MDBCol>
                                <MDBCol md={1} className="actions">
                                    {
                                        (this.state.publishers?.length - 1 === index && publisher.publisher_name?.length > 0) ?
                                            <AddCircleIcon fontSize="small"
                                                onClick={(e) =>
                                                    this.addAdditionalField('publishers', index)
                                                }
                                            /> : null
                                    }
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                )}
                <MDBRow className='mt-4' >
                    <MDBCol md={8}></MDBCol>
                    <MDBCol md={4}>
                        <MDBRow>
                            <MDBCol>
                                <BasicButton
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={18} /> : "Save"}
                                    onClick={this.handleSubmit}
                                />
                            </MDBCol>
                            <MDBCol>
                                <BasicButton
                                    onClick={() => this.props.handleClose()}
                                    variant="outlined"
                                    text={"Cancel"}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}
export default withUserContext(Publisher);